import React from "react";
import { Select, SelectProps } from "@mantine/core";

export const SelectGender = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Spol"}
        label={hideLabel ? undefined : "Spol"}
        {...rest}
        data={["Muški", "Ženski"].map((item) => ({
          value: item,
          label: item,
        }))}
      />
    );
  }
);

SelectGender.displayName = "SelectGender";
