import { Loader } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { notifications } from "sdk/common/helpers";
import { useMe, useSetTitle } from "sdk/common/hooks";
import { UserForm } from "./components/UserForm";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";
import { BaseRouteRedirect } from "../../../commonScreens/BaseRouteRedirect";

export const UrediKorisnika = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const userMe = useMe();

  const isUserAllowed = userMe?.id === id || userMe?.orgId === "szgz";

  const { data, isLoading, error } = trpc.user_find.useQuery(
    {
      id: id!,
    },
    { enabled: isUserAllowed }
  );
  const utils = trpc.useContext();

  const userUpdate = trpc.user_update.useMutation({
    onSuccess: () => {
      notifications.success();
      if (userMe?.id === id) {
        utils.user_me.invalidate();
      }
      utils.user_find.invalidate({ id });
      utils.user_findMany.reset();
      navigate("/pregled/korisnici");
    },
    onError: (error) => notifications.error(error.message),
  });

  useSetTitle("Izmjena korisničkih detalja", { showBackButton: true });

  if (!isUserAllowed) return <BaseRouteRedirect />;

  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;

  return (
    <ContentContainer className="h-full overflow-auto">
      <UserForm
        initialValues={{ ...data }}
        isEdit={true}
        onSubmit={(values) => userUpdate.mutate({ ...values })}
        isSubmitting={isLoading}
      />
    </ContentContainer>
  );
};
