import { Radio, RadioGroupProps } from "@mantine/core";
import React from "react";

export const RadioGender = React.forwardRef(
  (props: any & React.RefAttributes<HTMLInputElement>, ref: any) => {
    return (
      <Radio.Group ref={ref} name="gender" label="Spol" {...props}>
        <Radio value="Muški" label="Muški" />
        <Radio value="Ženski" label="Ženski" />
      </Radio.Group>
    );
  }
);

RadioGender.displayName = "RadioGender";
