import { z } from "zod";
import { idSchema } from "../helpers";
export const trainingScheduleUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    name: z.string().min(1, "Obvezan podatak"),
    description: z.string().default(""),
    fromDate: z.date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    }),
    toDate: z.date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    }),
});
