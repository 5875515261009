import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Button,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import dayjs from "dayjs";
import React from "react";
import { MdClose } from "react-icons/md";
import { TbFilter } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { memberTypeTableColors, removeFalsies } from "sdk/common/helpers";
import {
  useBreakpoints,
  useGetSelectedOrg,
  useSearchParams,
  useSetTitle,
} from "sdk/common/hooks";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { SelectMembershipType } from "sdk/components/Selects/SelectMembershipType";
import { useSwipeable } from "react-swipeable";
import {
  MemberStatuses,
  SelectStatus,
} from "sdk/components/Selects/SelectStatus";
import { AddNewFAB } from "sdk/components/AddNewFAB";
import { TiPlus } from "react-icons/ti";
import { HiChevronDown, HiChevronUp, HiPencil } from "react-icons/hi";
import { ActionButton } from "sdk/components/ActionIcon";
import { IoEnterOutline } from "react-icons/io5";
import { SelectGender } from "sdk/components/Selects/SelectGender";
import { BsDot } from "react-icons/bs";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";

export const ClanoviKluba = () => {
  useSetTitle("Članovi");
  const { orgId } = useParams();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const selectedOrg = useGetSelectedOrg();
  const ageGroups = selectedOrg?.Sport?.AgeGroups;

  const [params, setParams] = useSearchParams();
  const { isLg } = useBreakpoints();
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [sortInput, setSortInput] = React.useState<{
    orderBy: string;
    asc: boolean;
  }>({ orderBy: "lastName", asc: true });

  const [debouncedSearchText] = useDebouncedValue(params.searchText, 500);

  const memberFindMany = trpc.member_findMany.useInfiniteQuery(
    {
      ...params,
      searchText: debouncedSearchText || "",
      orgId: orgId as string,
      orderBy: { [sortInput.orderBy]: sortInput.asc ? "asc" : "desc" },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  );
  const members = memberFindMany.data?.pages.flatMap((arr) => arr.items);

  const getSortHeader = React.useCallback(
    (columnId, columnLabel) => (
      <Group
        spacing="xs"
        sx={{ userSelect: "none", cursor: "pointer" }}
        onClick={() => {
          setSortInput((prev) => ({
            orderBy:
              prev.orderBy === columnId && !prev.asc ? "lastName" : columnId,
            asc: prev.orderBy === columnId && prev.asc ? false : true,
          }));
        }}
      >
        <Text>{columnLabel.toLowerCase()}</Text>
        {sortInput.orderBy === columnId &&
          (sortInput.asc ? <HiChevronUp /> : <HiChevronDown />)}
      </Group>
    ),
    //
    [sortInput]
  );

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "",
        id: "eye",
        accessor: "",
        Cell: () => <IoEnterOutline size={20} />,
        width: 36,
        className: "pt-1 text-blue-400 hover:text-blue-700",
      },
      {
        Header: "",
        id: "edit",
        accessor: "",
        className: "pt-1 text-blue-400 hover:text-blue-700",
        Cell: (data) => {
          return (
            <HiPencil
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`uredi/${data.cell.row.original.id}`);
              }}
            />
          );
        },
        width: 36,
      },
      {
        id: "lastName",
        Header: getSortHeader("lastName", "prezime"),
        accessor: "lastName",
        width: 180,
        className: "text-blue-700 font-bold",
      },
      {
        id: "firstName",
        Header: getSortHeader("firstName", "ime"),
        accessor: "firstName",
        width: 180,
        className: "text-blue-700 font-bold",
      },

      // {
      //   Header: getSortHeader("dateOfBirth", "Datum rođenja"),
      //   accessor: "dateOfBirth",
      //   width: 150,
      //   Cell: ({ value }) =>
      //     formatDate.toDateString(value),
      // },
      {
        id: "age",
        Header: getSortHeader("dateOfBirth", "Dob"),
        accessor: "dateOfBirth",
        width: 60,
        headerClassName: "text-right",
        className: "text-center text-blue-500",
        Cell: (props) => (
          <div>
            {props.value ? dayjs().diff(dayjs(props.value), "y") : "-/-"}
          </div>
        ),
      },
      {
        Header: getSortHeader("gender", "Spol"),
        accessor: "gender",
        width: 70,
        className: "text-center text-blue-500",
        Cell: ({ value }) => value?.toString()?.charAt(0) || "-/-",
      },
      {
        Header: getSortHeader("membershipTypeId", "Tip člana"),
        accessor: "MembershipType.name",
        width: 260,
        className: "text-blue-500",
        Cell: ({ row, value }) => (
          <div
            className={`flex items-center rounded-full px-2 ${
              memberTypeTableColors[row?.original?.membershipTypeId]
            }`}
          >
            <BsDot size={32} />
            <div>
              {row?.original?.membershipTypeId === "O"
                ? row?.original?.memberRole
                : value}
            </div>
          </div>
        ),
      },
      {
        Header: getSortHeader("currentAgeGroupId", "Uzrast"),
        accessor: "CurrentAgeGroup.AgeGroup.name",
        width: 150,
        className: "text-blue-500",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div>
            {MemberStatuses.find((m) => m.value === value)?.label || ""}
          </div>
        ),
      },
    ],
    [sortInput]
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setDrawerOpened(false),
  });

  const { data: membershipTypes } = trpc.membershipType_findMany.useQuery();
  const membershipFilterTypes = membershipTypes?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const filterLabels = {
    membershipTypeId: membershipFilterTypes?.find(
      (m) => m.value === params.membershipTypeId
    )?.label,
    currentAgeGroupId: ageGroups?.find(
      (ageGroup) => ageGroup.id === params.currentAgeGroupId
    )?.name,
    searchText: params.searchText,
    status: MemberStatuses.find(
      (item) => item.value === (params?.status as string)
    )?.label,
    gender: params.gender,
  };

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        {/* <pre>{JSON.stringify({ newMember }, null, 2)}</pre> */}
        <div className="flex flex-row items-center justify-between pb-4">
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({
                ...params,
                searchText: e.currentTarget.value,
              })
            }
          />

          <div className="flex flex-row items-center gap-6">
            {isLg && (
              <Button
                leftIcon={<TiPlus size={20} />}
                onClick={() => navigate("novi")}
                sx={{ fontWeight: "bold" }}
              >
                Dodaj člana
              </Button>
            )}

            <ActionButton onClick={() => setDrawerOpened(true)} label="Filter">
              <TbFilter size={24} />
            </ActionButton>
          </div>
        </div>

        {/**
         * ACTIVE FILTERS
         */}
        {Object.keys(removeFalsies(params)).length ? (
          <div className="flex gap-4 px-4">
            {Object.keys(removeFalsies(params)).map((key) => (
              <Badge
                size="lg"
                sx={{
                  background: "#FCE6E4",
                  color: "#617A9E",
                }}
                key={key}
                rightSection={
                  <ActionIcon
                    size="xs"
                    radius="xl"
                    variant="transparent"
                    onClick={() => {
                      setParams(removeFalsies({ ...params, [key]: "" }));
                    }}
                  >
                    <MdClose size={16} />
                  </ActionIcon>
                }
              >
                {filterLabels[key]}
              </Badge>
            ))}
            <button
              className="ml-auto cursor-pointer border-0 bg-inherit p-1 text-blue-600 hover:underline"
              onClick={() => setParams({})}
              type="button"
            >
              Ukloni filtere
            </button>
          </div>
        ) : null}

        <DataTable
          isLoading={memberFindMany.isLoading}
          data={members}
          columns={columns}
          restoreScrollKey={`OrgMembersListScreen-${orgId}`}
          onRowClick={(row) => navigate(`detalji/${row.id}`)}
          renderItem={(row) => (
            <Group noWrap>
              <Text>
                {row.firstName} {row.lastName}
              </Text>
              <Text>{row.dateOfBirth || "-/-"}</Text>
            </Group>
          )}
          renderItemHeight={55}
          fetchMore={memberFindMany.fetchNextPage}
        />
      </ContentContainer>

      <AddNewFAB />

      <Drawer
        withCloseButton={false}
        overlayColor={theme.other.overlayColor}
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={
          <Text
            sx={{
              color: theme.colors.blue[4],
            }}
            weight={500}
            size={24}
          >
            Filter
          </Text>
        }
        position="right"
        closeOnEscape
        closeOnClickOutside
        styles={(theme) => ({
          root: {},
          title: {},
          drawer: {
            borderTopLeftRadius: theme.radius.lg,
            borderBottomLeftRadius: theme.radius.lg,
            backdropFilter: "blur(8px)",
            background: theme.other.sidebarFilterBackground,
          },
          header: {
            padding: theme.spacing.xl,
            height: isLg ? "80px" : "62px",
          },
        })}
      >
        <Stack
          {...swipeHandlers}
          spacing="xl"
          px="md"
          py="xl"
          sx={{ height: "100%" }}
        >
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams(
                removeFalsies({
                  ...params,
                  searchText: e.currentTarget.value,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectMembershipType
            hideLabel
            withAllAthletesOption
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
            value={params.membershipTypeId || null}
            onChange={(membershipTypeId) =>
              setParams(
                removeFalsies({
                  ...params,
                  membershipTypeId,
                })
              )
            }
          />

          <Select
            clearable
            disabled={!ageGroups?.length}
            data={
              ageGroups?.map((ageGroup) => ({
                value: (ageGroup as any).id,
                label: (ageGroup as any).name,
              })) || []
            }
            placeholder="Uzrasna kategorija"
            value={params?.currentAgeGroupId || null}
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
            onChange={(currentAgeGroupId) =>
              setParams(
                removeFalsies({
                  ...params,
                  currentAgeGroupId,
                })
              )
            }
          />

          <SelectGender
            hideLabel
            value={params.gender || null}
            onChange={(gender) =>
              setParams(
                removeFalsies({
                  ...params,
                  gender,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />

          <SelectStatus
            hideLabel
            value={params.status || null}
            onChange={(status) =>
              setParams(
                removeFalsies({
                  ...params,
                  status,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
        </Stack>
      </Drawer>
    </>
  );
};
