import { NavLink } from "react-router-dom";
import { useAppState } from "state/useAppState";
import {
  IoBarChart,
  IoBarChartOutline,
  IoHome,
  IoHomeOutline,
} from "react-icons/io5";
import { RiAdminLine, RiAdminFill } from "react-icons/ri";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi";
import { trpc } from "utils/trpc";
import { useMe } from "sdk/common/hooks";

export const adminLinks = [
  {
    to: "/pregled/klubovi?status=ACTIVE",
    title: "Članice",
    icon: IoHomeOutline,
    activeIcon: IoHome,
  },
  {
    to: "/pregled/clanovi?status=ACTIVE",
    title: "Članovi",
    icon: HiOutlineUserGroup,
    activeIcon: HiUserGroup,
  },

  {
    to: "/izvjestaji",
    title: "Izvještaji",
    icon: IoBarChartOutline,
    activeIcon: IoBarChart,
  },
  {
    to: "/pregled/korisnici",
    title: "Korisnici",
    icon: RiAdminLine,
    activeIcon: RiAdminFill,
  },
];

export const AdminSidebar = () => {
  const user = useMe();

  return (
    <div className="hidden h-full w-[74px] min-w-[74px] flex-col items-center gap-4 pt-32 xl:flex">
      {user?.orgId === "szgz" &&
        adminLinks.map((link) => (
          <ToolbarMenuItem
            key={link.to}
            ActiveIcon={link.activeIcon}
            Icon={link.icon}
            to={link.to}
            tooltip={link.title}
          />
        ))}
    </div>
  );
};

const ToolbarMenuItem = (props: {
  to: string;
  Icon: any;
  ActiveIcon: any;
  tooltip: string;
  disabled?: boolean;
}) => {
  const { Icon, ActiveIcon, to } = props;

  return (
    <NavLink
      to={to}
      className="no-underline"
      {...(props.disabled ? { onClick: (e) => e.preventDefault() } : undefined)}
    >
      {({ isActive }) => {
        return (
          <div
            className={`flex h-16 w-16 flex-col items-center justify-center text-sm font-semibold
            ${
              isActive
                ? "text-blue-700"
                : props.disabled
                ? "text-zinc-500"
                : "text-blue-500"
            }
            ${props.disabled ? "" : "hover:text-blue-700"}`}
          >
            {isActive ? <ActiveIcon size={24} /> : <Icon size={24} />}
            <span>{props.tooltip}</span>
          </div>
        );
      }}
    </NavLink>
  );
};
