import { z } from "zod";
import { isValidOIB } from "../helpers";
export const organizationUpsertSchema = z
    .object({
    id: z.string().min(3),
    sportId: z
        .string({
        invalid_type_error: "Sport je obvezan podatak",
        required_error: "Sport je obvezan podatak",
    })
        .min(1, "Sport je obvezan podatak"),
    name: z
        .string({
        invalid_type_error: "Puni naziv je obvezan podatak",
        required_error: "Puni naziv je obvezan podatak",
    })
        .min(2, { message: "Puni naziv je obvezan podatak" }),
    shortName: z.string().nullish(),
    authorizedPersonName: z
        .string({
        invalid_type_error: "Ime i prezime ovlaštene osobe je obvezan podatak",
        required_error: "Ime i prezime ovlaštene osobe je obvezan podatak",
    })
        .min(2, { message: "Ime i prezime ovlaštene osobe je obvezan podatak" }),
    authorizedPersonPhone: z.string().nullish(),
    authorizedPersonEmail: z.string().nullish(),
    address: z
        .string({
        invalid_type_error: "Adresa je obvezan podatak",
        required_error: "Adresa je obvezan podatak",
    })
        .min(1, { message: "Adresa je obvezan podatak" }),
    houseNumber: z.string().nullish(),
    email: z.string({
        required_error: "E-mail je obvezan",
        invalid_type_error: "E-mail je obvezan",
    }),
    city: z
        .string({
        invalid_type_error: "Grad je obvezan podatak",
        required_error: "Grad je obvezan podatak",
    })
        .min(1, { message: "Grad je obvezan podatak" }),
    zipCode: z
        .string({
        invalid_type_error: "Poštanski broj je obvezan podatak",
        required_error: "Poštanski broj je obvezan podatak",
    })
        .min(1, { message: "Poštanski broj je obvezan podatak" }),
    secretaryMandateStartDate: z.date().nullish(),
    secretaryMandateEndDate: z.date().nullish(),
    mandateStartDate: z.date().nullish(),
    mandateEndDate: z.date().nullish(),
    vatId: z
        .string({
        required_error: "OIB je obvezan podatak",
        invalid_type_error: "OIB je obvezan podatak",
    })
        .refine((value) => isValidOIB(value?.toString()), (value) => ({
        message: value.length
            ? "OIB je neispravan"
            : "OIB je obvezan podatak",
    })),
    status: z.string().min(1).optional(),
    mb: z.string().nullish(),
    rb: z.string().nullish(),
    rno: z.string().nullish(),
    bankName: z.string().nullish(),
    iban: z
        .string()
        .refine((value) => value.replace(/\s+/g, "").length === 21, {
        message: "IBAN mora imati 21 znak",
    })
        .nullish(),
    webAddress: z.string().nullish(),
    foundingAssemblyDate: z.date().nullish(),
    membershipAdmissionDate: z.date().nullish(),
    avatarRecordFileId: z.string().nullish(),
    contactPersonName: z.string().nullish(),
    contactPersonPhone: z.string().nullish(),
    contactPersonEmail: z.string().nullish(),
})
    .refine((data) => {
    if (data.secretaryMandateEndDate && data.secretaryMandateStartDate) {
        return (data.secretaryMandateEndDate.getTime() >
            data.secretaryMandateStartDate.getTime());
    }
    return true;
}, {
    message: "Datum završetka mandata mora biti veći od datuma početka mandata",
    path: ["secretaryMandateEndDate"],
})
    .refine((data) => {
    if (data.mandateEndDate && data.mandateStartDate) {
        return data.mandateEndDate.getTime() > data.mandateStartDate.getTime();
    }
    return true;
}, {
    message: "Datum završetka mandata mora biti veći od datuma početka mandata",
    path: ["mandateEndDate"],
});
