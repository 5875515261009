import { ulid } from "ulid";
import { OrganizationForm } from "../pregled/klubovi/$orgId/components/OrganizationForm";
import { useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";

export const NoviKlub = () => {
  useSetTitle("Registracija novog kluba/udruge", { showBackButton: true });

  return (
    <ContentContainer className="h-full">
      <OrganizationForm
        initialValues={{
          id: ulid(),
          name: "",
          email: "",
          sportId: "",
          address: "",
          city: "",
          authorizedPersonName: "",
          zipCode: "",
        }}
      />
    </ContentContainer>
  );
};
