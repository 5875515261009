import { Avatar, Burger, Group, useMantineTheme } from "@mantine/core";
import { getUrlFromRecordFileId } from "sdk/common/helpers";
import { useBreakpoints, useMe } from "sdk/common/hooks";

import { useAppState } from "state/useAppState";

export const MobileHeader = () => {
  const [isSidebarOpen, setSidebarOpen, title] = useAppState((state) => [
    state.isSidebarOpen,
    state.setSidebarOpen,
    state.title,
  ]);

  const theme = useMantineTheme();

  const { isXs } = useBreakpoints();

  const user = useMe();

  return (
    <div className="flex flex-row items-center justify-between bg-blue-600 p-4">
      <Burger
        color={theme.colors.blue[2]}
        size="md"
        opened={isSidebarOpen}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
      />

      <span className="text-lg font-bold text-blue-200">{title}</span>

      <Group
        spacing="xs"
        // onClick={() => navigate("/profil")}
        sx={{ cursor: "pointer" }}
      >
        <Avatar
          src={getUrlFromRecordFileId(user?.avatarRecordFileId)}
          size="md"
          radius="xl"
          styles={{ image: { border: "0px solid white" } }}
        />
        {isXs && (
          <span className="font-bold text-blue-200">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
        )}
      </Group>
    </div>
  );
};
