import {
  Group,
  Stack,
  Button,
  Text,
  useMantineTheme,
  Modal,
  ActionIcon,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React from "react";
import { MdEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { formatDate } from "sdk/common/helpers";
import { SelectAgeGroup } from "sdk/components/Selects/SelectAgeGroup";
import { ulid } from "ulid";
import { CardContainer, TabPanelContainer } from ".";
import { trpc } from "utils/trpc";
import { useForm, zodResolver } from "@mantine/form";
import { ageGroupUpsertSchema } from "models";
import { assert } from "sdk/assert";
import { openDeleteDialog } from "sdk/common/hooks";

export const TabPanelAgeGroup = () => {
  const { id, orgId } = useParams();
  assert(id);

  const { data: member, refetch } = trpc.member_find.useQuery({ id });
  const theme = useMantineTheme();

  const deleteAgeGroup = trpc.member_ageGroupDelete.useMutation({
    onSuccess: () => refetch(),
  });

  const [selectedItem, setSelectedItem] = React.useState<any>();

  const items = member?.MemberAgeGroups;
  const isEdit = !!items?.find((item) => item.id === selectedItem?.id);

  return (
    <>
      <TabPanelContainer
        addButtonTitle={"Dodaj novu"}
        addButtonOnClick={() =>
          setSelectedItem({
            id: ulid(),
            orgId,
            memberId: id,
            dateFrom: new Date(),
            ageGroupId: "",
          })
        }
      >
        <CardContainer>
          <Text>Povijest uzrasnih kategorija</Text>
          <Stack spacing="xs" sx={{ position: "relative" }}>
            {items?.map((item, index) => (
              <div key={index} className="flex gap-8">
                <div
                  key={item.id}
                  className="flex grow cursor-pointer justify-between rounded-lg bg-blue-200 p-2 transition-all hover:rounded-2xl hover:bg-blue-300"
                  onClick={() => setSelectedItem(item)}
                >
                  <Text>{formatDate.toDateString(item.dateFrom)}</Text>
                  <Text>{item.AgeGroup?.name}</Text>
                </div>
                <div className="flex gap-4 px-2">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    radius="xl"
                    size="xl"
                    onClick={() => setSelectedItem(item)}
                  >
                    <MdEdit />
                  </ActionIcon>
                  <ActionIcon
                    color="pink"
                    variant="light"
                    radius="xl"
                    size="xl"
                    disabled={items.length < 2}
                    onClick={() =>
                      items.length > 1 &&
                      openDeleteDialog({
                        onAccept() {
                          deleteAgeGroup.mutate({
                            id: item.id,
                            memberId: id,
                          });
                        },
                        titleText: "Izbriši uzrasnu kategoriju",
                        contentText:
                          "Potvrdom čete izbrisati uzrasnu kategoriju",
                      })
                    }
                  >
                    <TbTrash />
                  </ActionIcon>
                </div>
              </div>
            ))}
          </Stack>
        </CardContainer>
      </TabPanelContainer>

      <Modal
        overlayOpacity={0.2}
        opened={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title={isEdit ? "Izmjena podataka" : "Nova kategorija"}
        styles={{
          modal: { width: `min(100%, ${theme.breakpoints.sm}px)` },
          title: { fontWeight: "bold" },
        }}
      >
        <AgeGroupUpsert
          ageGroup={selectedItem}
          onClose={() => setSelectedItem(undefined)}
        />
      </Modal>
    </>
  );
};

const AgeGroupUpsert = ({ ageGroup, onClose }) => {
  const utils = trpc.useContext();
  const ageGroupUpsert = trpc.member_ageGroupUpsert.useMutation({
    onSuccess: () => {
      utils.member_find.invalidate({ id: ageGroup.memberId });
      onClose();
    },
  });

  const form = useForm({
    validate: zodResolver(ageGroupUpsertSchema),
    initialValues: ageGroup,
  });

  return (
    <form
      onSubmit={form.onSubmit(
        (values) => ageGroupUpsert.mutate(values),
        (err) => console.log({ err })
      )}
    >
      <Stack>
        <Group align="flex-start" noWrap>
          <DatePicker
            withAsterisk
            label="Početak"
            {...form.getInputProps("dateFrom")}
            maxDate={form.values.dateTo}
          />
          <SelectAgeGroup {...form.getInputProps("ageGroupId")} />
        </Group>
        <Group position="right">
          <Button
            disabled={ageGroupUpsert.isLoading}
            variant="outline"
            onClick={onClose}
            type="button"
          >
            Odustani
          </Button>
          <Button
            loading={ageGroupUpsert.isLoading}
            disabled={ageGroupUpsert.isLoading}
            type="submit"
          >
            Spremi
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
