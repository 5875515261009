import React from "react";
import { useParams } from "react-router-dom";
import { assert } from "sdk/assert";
import { formatDate } from "sdk/common/helpers";
import { useMe } from "sdk/common/hooks";
import { Label } from "sdk/components/Label";
import { trpc } from "utils/trpc";

export const ClanoviKlubaIzvjestaj = (props) => {
  const { orgId } = useParams();
  assert(orgId);

  const { data: org } = trpc.organization_find.useQuery({ id: orgId });

  const { data } = trpc.report_orgMemberReport.useQuery({ orgId });

  const user = useMe();

  const qualifications = trpc.qualification_findMany.useQuery();

  if (!data) return null;

  const totalMembersCount = data.filter(
    (item) => item.membershipTypeId !== "T"
  ).length;

  const regAthletes = data.filter((item) => item.membershipTypeId === "RS");
  const nonRegAthletes = data.filter((item) => item.membershipTypeId === "NRS");
  const otherMembers = data.filter((item) => item.membershipTypeId === "O");

  const totalAthletes = regAthletes?.length + nonRegAthletes?.length;

  const getMembersCount = ({
    membershipTypeId = undefined,
    memberRole = undefined,
    employeeQualificationId = undefined,
    coachEmploymentTypeId = undefined,
  }: {
    membershipTypeId?: string | null | Array<string>;
    memberRole?: string | null;
    employeeQualificationId?: string | null;
    coachEmploymentTypeId?: string | null;
  }) =>
    data?.filter(
      (member) =>
        (!membershipTypeId || member.membershipTypeId === membershipTypeId) &&
        (!memberRole || member.memberRole === memberRole) &&
        (!employeeQualificationId ||
          member.employeeQualificationId === employeeQualificationId) &&
        (!coachEmploymentTypeId ||
          member.coachEmploymentTypeId === coachEmploymentTypeId)
    )?.length || "-";

  return (
    <div className="flex h-[297mm] w-[210mm] flex-col p-[10mm]">
      <Label className="text-xl">{`${org?.name} - (${org?.shortName})`}</Label>

      <Label className="mt-8 self-center text-xl">
        Izvještaj o članovima kluba
      </Label>

      <div className="mt-12 self-start">
        <Label>Broj članova</Label>
        <div className="mx-4 grid grid-cols-[auto_auto] gap-px bg-zinc-300 text-right [&>*]:bg-white [&>*]:px-2">
          <div>Sportaša</div>
          <div className="text-right">{totalAthletes}</div>

          <div> Ostalih članova</div>
          <div className="text-right">{otherMembers.length}</div>

          <div className="font-bold">Ukupan broj članova</div>
          <div className="text-right font-bold">{totalMembersCount}</div>

          {/* <div>Ukupan broj trenera</div>
          <div className="text-right">{coachesCount}</div> */}
        </div>
      </div>

      <div className="mt-12">
        <Label>Detaljni pregled sportaša</Label>
        <div className="mx-4 grid grid-cols-[auto_auto_auto_auto_auto_auto_auto_auto_auto_auto] gap-px bg-zinc-300 text-right [&>*]:bg-white [&>*]:px-2">
          {/**
           * 1st row
           */}
          <div>vrsta</div>
          <div className="text-center font-bold text-zinc-800">
            ne-registrirani
          </div>
          <div className="col-span-7 text-center font-bold text-zinc-800">
            registrirani
          </div>
          <div />

          {/**
           * 2nd row
           */}
          <div>kategorija</div>
          <div>-</div>
          <div className="">Nekategoriziran</div>
          <div className="">I.</div>
          <div className="">II.</div>
          <div className="">III.</div>
          <div className="">IV.</div>
          <div className="">V.</div>
          <div className="">VI.</div>
          <div></div>

          {/**
           * 3rd - N row
           */}
          {org?.Sport?.AgeGroups?.map((item) => (
            <React.Fragment key={item.id}>
              <div>{item.name}</div>
              <div>
                {nonRegAthletes.filter(
                  (m) => m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "Nekategoriziran" &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "I." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "II." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "III." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "IV." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "V." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {regAthletes.filter(
                  (m) =>
                    m.CurrentCategory?.categoryNo === "VI." &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
              <div>
                {data.filter(
                  (m) =>
                    ["RS", "NRS"].includes(m.membershipTypeId!) &&
                    m.CurrentAgeGroup?.ageGroupId === item.id
                ).length || "-"}
              </div>
            </React.Fragment>
          ))}
          <>
            <div>Bez dobne skupine</div>
            <div>
              {nonRegAthletes.filter((m) => m.currentAgeGroupId === null)
                ?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "Nekategoriziran"
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "I."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "II."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "III."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "IV."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "V."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) =>
                  m.currentAgeGroupId === null &&
                  m.CurrentCategory?.categoryNo === "VI."
              )?.length || "-"}
            </div>
            <div>
              {data.filter(
                (m) =>
                  ["RS", "NRS"].includes(m.membershipTypeId!) &&
                  m.currentAgeGroupId === null
              )?.length || "-"}
            </div>
          </>

          {/**
           * Totals
           */}
          <>
            <div />
            <div>{nonRegAthletes?.length || "-"}</div>
            <div>
              {regAthletes.filter(
                (m) => m.CurrentCategory?.categoryNo === "Nekategoriziran"
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter((m) => m.CurrentCategory?.categoryNo === "I.")
                ?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) => m.CurrentCategory?.categoryNo === "II."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) => m.CurrentCategory?.categoryNo === "III."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) => m.CurrentCategory?.categoryNo === "IV."
              )?.length || "-"}
            </div>
            <div>
              {regAthletes.filter((m) => m.CurrentCategory?.categoryNo === "V.")
                ?.length || "-"}
            </div>
            <div>
              {regAthletes.filter(
                (m) => m.CurrentCategory?.categoryNo === "VI."
              )?.length || "-"}
            </div>
            <div className="font-bold">{totalAthletes || "-"}</div>
          </>
        </div>
      </div>

      <div className="mt-12 self-start">
        <Label>Ostali članovi</Label>
        <div className="mx-4 grid grid-cols-[auto_auto] gap-px bg-zinc-300 text-right [&>*]:bg-white [&>*]:px-2">
          <div> Članovi upravnog odbora</div>
          <div className="text-right">
            {getMembersCount({
              membershipTypeId: "O",
              memberRole: "Član upravnog odbora",
            })}
          </div>

          <div>Članovi izvršnog odbora</div>
          <div className="text-right">
            {getMembersCount({
              membershipTypeId: "O",
              memberRole: "Član izvršnog odbora",
            })}
          </div>

          <div>Obični članovi</div>
          <div className="text-right">
            {getMembersCount({
              membershipTypeId: "O",
              memberRole: "Običan član",
            })}
          </div>
          <div>Ukupno članova</div>
          <div className="text-right">
            {getMembersCount({
              membershipTypeId: "O",
            })}
          </div>
        </div>
      </div>

      <div className="mt-12 self-start">
        <Label>Treneri</Label>
        <div className="mx-4 grid grid-cols-[auto_auto_auto_auto_auto_auto] gap-px bg-zinc-300 text-right [&>*]:bg-white [&>*]:px-2">
          {/**
           * 1st row
           */}
          <div />
          <div className="max-w-[200px] truncate">
            Ugovor o radu, ugovor o profesionalnom treniranju
          </div>
          <div>Honorarno</div>
          <div>Volonteri</div>
          <div>Ostalo</div>

          <div></div>

          {/**
           * 2nd .. N-row
           */}

          {qualifications.data?.map((qual) => (
            <React.Fragment key={qual.id}>
              <div className="max-w-[200px] truncate">{qual.name}</div>
              <div>
                {getMembersCount({
                  membershipTypeId: "T",
                  employeeQualificationId: qual.id,
                  coachEmploymentTypeId: "U",
                })}
              </div>
              <div>
                {getMembersCount({
                  membershipTypeId: "T",
                  employeeQualificationId: qual.id,
                  coachEmploymentTypeId: "H",
                })}
              </div>
              <div>
                {getMembersCount({
                  membershipTypeId: "T",
                  employeeQualificationId: qual.id,
                  coachEmploymentTypeId: "V",
                })}
              </div>
              <div>
                {getMembersCount({
                  membershipTypeId: "T",
                  employeeQualificationId: qual.id,
                  coachEmploymentTypeId: "O",
                })}
              </div>
              <div>
                {getMembersCount({
                  membershipTypeId: "T",
                  employeeQualificationId: qual.id,
                })}
              </div>
            </React.Fragment>
          ))}

          {/**
           * totals row
           */}
          <>
            <div />
            <div>
              {getMembersCount({
                membershipTypeId: "T",
                coachEmploymentTypeId: "U",
              })}
            </div>
            <div>
              {getMembersCount({
                membershipTypeId: "T",
                coachEmploymentTypeId: "H",
              })}
            </div>
            <div>
              {getMembersCount({
                membershipTypeId: "T",
                coachEmploymentTypeId: "V",
              })}
            </div>
            <div>
              {getMembersCount({
                membershipTypeId: "T",
                coachEmploymentTypeId: "O",
              })}
            </div>
            <div className="font-bold">
              {getMembersCount({ membershipTypeId: "T" })}
            </div>
          </>
        </div>
      </div>

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div className="mt-auto flex justify-between font-bold">
        <div>{`Dokument izradio: ${user?.firstName} ${user?.lastName}`}</div>
        <div>{formatDate.toDateTimeString(new Date())}</div>
      </div>
    </div>
  );
};
