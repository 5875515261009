import { Global } from "@mantine/core";

import Satoshi_Variable from "./fonts/web/fonts/Satoshi-Variable.woff2";
import Satoshi_VariableItalic from "./fonts/web/fonts/Satoshi-VariableItalic.woff2";
import Satoshi_Light from "./fonts/web/fonts/Satoshi-Light.woff2";
import Satoshi_LightItalic from "./fonts/web/fonts/Satoshi-LightItalic.woff2";
import Satoshi_Regular from "./fonts/web/fonts/Satoshi-Regular.woff2";
import Satoshi_Italic from "./fonts/web/fonts/Satoshi-Italic.woff2";
import Satoshi_Medium from "./fonts/web/fonts/Satoshi-Medium.woff2";
import Satoshi_MediumItalic from "./fonts/web/fonts/Satoshi-MediumItalic.woff2";
import Satoshi_Bold from "./fonts/web/fonts/Satoshi-Bold.woff2";
import Satoshi_BoldItalic from "./fonts/web/fonts/Satoshi-BoldItalic.woff2";
import Satoshi_Black from "./fonts/web/fonts/Satoshi-Black.woff2";
import Satoshi_BlackItalic from "./fonts/web/fonts/Satoshi-BlackItalic.woff2";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Satoshi-Variable",
            src: `url("${Satoshi_Variable}") format("woff2")`,

            fontWeight: "300 900",
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-VariableItalic",
            src: `url("${Satoshi_VariableItalic}") format("woff2")`,

            fontWeight: "300 900",
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Light",
            src: `url("${Satoshi_Light}") format("woff2")`,

            fontWeight: 300,
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-LightItalic",
            src: `url("${Satoshi_LightItalic}") format("woff2")`,

            fontWeight: 300,
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Regular",
            src: `url("${Satoshi_Regular}") format("woff2")`,

            fontWeight: 400,
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Italic",
            src: `url("${Satoshi_Italic}") format("woff2")`,

            fontWeight: 400,
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Medium",
            src: `url("${Satoshi_Medium}") format("woff2")`,

            fontWeight: 500,
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-MediumItalic",
            src: `url("${Satoshi_MediumItalic}") format("woff2")`,

            fontWeight: 500,
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Bold",
            src: `url("${Satoshi_Bold}") format("woff2")`,

            fontWeight: 700,
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-BoldItalic",
            src: `url("${Satoshi_BoldItalic}") format("woff2")`,

            fontWeight: 700,
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-Black",
            src: `url("${Satoshi_Black}") format("woff2")`,

            fontWeight: 900,
            fontDisplay: "swap",
            fontStyle: "normal",
          },
        },

        {
          "@font-face": {
            fontFamily: "Satoshi-BlackItalic",
            src: `url("${Satoshi_BlackItalic}") format("woff2")`,

            fontWeight: 900,
            fontDisplay: "swap",
            fontStyle: "italic",
          },
        },
      ]}
    />
  );
}
