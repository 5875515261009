/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTable, useBlockLayout, Column } from "react-table";
import {
  Box,
  Loader,
  Text,
  useMantineTheme,
  Group,
  Button,
} from "@mantine/core";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import { useBreakpoints } from "sdk/common/hooks";
import { assert } from "sdk/assert";

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableColumn<T extends object = any> = Column<T> & {
  isNumeric?: boolean;
  className?: string;
  headerClassName?: string;
  Cell?: (props: any) => any;
};
interface IProps<T extends object> {
  columns: readonly TableColumn<T>[];
  data?: T[];
  onRowClick?: (row: T) => unknown;
  count?: number;
  refetch?: (pageNumber: number) => unknown;
  isLoading?: boolean;
  renderItem?: (row: T) => React.ReactNode;
  renderItemHeight?: number;
  fetchMore?: () => unknown;
  hasNextPage?: boolean;
  restoreScrollKey?: string;
}
const scrollPositionCache = {
  tableKey: 0,
};
export const DataTable = <T extends object>(props: IProps<T>) => {
  const { isLg } = useBreakpoints();
  // console.log({ isLg });
  return isLg ? <DesktopLayout {...props} /> : <MobileLayout {...props} />;
  // return !isLg ? <MobileLayout {...props} /> : <DesktopLayout {...props} />;
};

function DesktopLayout<T extends object>(props: IProps<T>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [scrollContainerWidth, setScrollContainerWidth] = React.useState(0);
  const {
    columns,
    data = [],
    fetchMore,
    hasNextPage = false,
    isLoading = false,
    onRowClick,
    restoreScrollKey,
  } = props;
  const isFetchMoreInProgressRef = useRef(false);
  const handleEndReached = useCallback(async () => {
    if (!fetchMore || isFetchMoreInProgressRef.current) return;
    isFetchMoreInProgressRef.current = true;
    await fetchMore();
    setTimeout(() => (isFetchMoreInProgressRef.current = false));
  }, [fetchMore]);

  const ref = useRef(null);
  const lastRowObserver = useMemo(
    () => new IntersectionObserver(handleEndReached),
    [handleEndReached]
  );
  const observeLastRow = useCallback(
    (row) => {
      lastRowObserver.disconnect();
      if (row && !isFetchMoreInProgressRef.current) {
        lastRowObserver.observe(row);
      }
    },
    [lastRowObserver]
  );

  useEffect(() => {
    if (!ref?.current || !restoreScrollKey) return;

    ((ref.current as any)._outerRef as HTMLDivElement).scrollTop =
      scrollPositionCache[restoreScrollKey] || 0;
  }, [ref.current, restoreScrollKey]);

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );
  useEffect(() => {
    if (!tableContainerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setScrollContainerWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(tableContainerRef.current);
    return () => resizeObserver.disconnect();
  }, [tableContainerRef.current]);

  const { headerGroups, rows, totalColumnsWidth, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  );

  const RenderRow = React.useCallback(
    ({ index, style }, totalColumnsWidth, containerWidth) => {
      if (isItemLoaded(index)) {
        const row = rows[index];
        prepareRow(row);
        return (
          <div
            {...row.getRowProps()}
            ref={index === data.length - 1 ? observeLastRow : null}
            style={Object.assign({ ...style }, { width: containerWidth })}
            className="flex pl-2  hover:cursor-pointer hover:bg-white hover:shadow-table-row
            [&>div]:hover:pl-1
            [&>div]:hover:transition-spacing
            [&>div]:hover:duration-300"
          >
            <>
              {row.cells.map((cell, index) => {
                const { className, isNumeric = false } =
                  cell.column as TableColumn;
                const cellProps = cell.getCellProps();
                if (index === columns.length - 1 && cellProps.style) {
                  cellProps.style.width = `${
                    containerWidth -
                    totalColumnsWidth +
                    Number(String(cellProps.style?.width).replace("px", "")) -
                    15
                  }px`;
                }

                return (
                  <div
                    {...cellProps}
                    key={index}
                    onClick={() => onRowClick?.(row.original)}
                    className={`
                    line h-12 overflow-hidden  pr-3 font-[500]
                    leading-6 text-blue-700
                    ${isNumeric ? "text-right" : "text-left"}
                    `}
                  >
                    <div className={`flex h-full w-full items-center `}>
                      <div className={`w-full truncate ${className || ""}`}>
                        {cell.render("Cell")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        );
      } else if (isLoading) {
        return (
          <Group
            // ref={ref}
            style={{ ...style }}
            position="center"
            sx={{ paddingTop: 8 }}
          >
            <Loader size="sm" />
            <Text>Učitavanje...</Text>
          </Group>
        );
      } else if (!rows.length) {
        return (
          <Text size="lg" pt="md" sx={{ textAlign: "center" }}>
            Nema rezultata
          </Text>
        );
      }
    },
    [prepareRow, rows, hasNextPage, isLoading]
  );

  const isItemLoaded = (index) => index < rows.length;

  // Render the UI for your table
  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        background: "transparent",
      }}
    >
      {/**HEADER */}
      {headerGroups.map((headerGroup, index) => (
        <div
          className="sticky top-0 z-10 w-full rounded-tl-lg rounded-tr-lg bg-blue-450 py-2 pl-2"
          key={index}
          style={{ width: scrollContainerWidth }}
        >
          {headerGroup.headers.map((column, index) => {
            const headerProps = column.getHeaderProps();
            if (index === columns.length - 1 && headerProps.style) {
              headerProps.style.flex = 1;
              headerProps.style.width = undefined;
            }
            return (
              <div
                {...headerProps}
                key={index}
                className={`pr-3 text-sm font-[600] uppercase text-blue-700 ${
                  (column as TableColumn).headerClassName || ""
                }`}
              >
                {column.render("Header")}
              </div>
            );
          })}
        </div>
      ))}

      <Box
        sx={{
          height: "calc(100% - 60px)",
          width: `max(100%, ${totalColumnsWidth}px)`,
        }}
        className="overflow-hidden"
        ref={tableContainerRef}
      >
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              height={height}
              itemCount={rows.length + 1}
              itemSize={48}
              width={width}
              ref={ref}
              onScroll={(e) => {
                if (!ref?.current || !restoreScrollKey) return;
                scrollPositionCache[restoreScrollKey] = e.scrollOffset;
              }}
              style={{
                background: "rgba(253, 250, 255, 0.8)",
                overflowX: "hidden",
                borderRadius: "0",
              }}
            >
              {/* {(e) => RenderRow(e, totalColumnsWidth)} */}
              {(e) => RenderRow(e, totalColumnsWidth, width)}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}

const MobileLayout = <T extends object>(props: IProps<T>) => {
  const { data, columns, renderItem, onRowClick } = props;
  const theme = useMantineTheme();
  const isRowClickable = typeof onRowClick === "function";

  const Row = React.useCallback(
    ({ index, style }: any) => {
      assert(data);
      const row = data[index] as any;
      return (
        <div style={style} onClick={() => isRowClickable && onRowClick(row)}>
          {typeof renderItem === "function" ? renderItem(row) : null}
        </div>
      );
    },
    [data]
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemCount={data?.length}
          itemSize={props.renderItemHeight || 90}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
