import {
  Stack,
  Text,
  Image,
  Group,
  Box,
  ActionIcon,
  Loader,
  Modal,
  Accordion,
  useMantineTheme,
  Select,
  Title,
  Button,
} from "@mantine/core";

import axios from "axios";
import { useParams } from "react-router-dom";
import { Dropzone } from "@mantine/dropzone";
import React from "react";
import {
  faDownload,
  faPaperclip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileDownload from "js-file-download";
import { useBreakpoints } from "../common/hooks";
import { getUrlFromRecordFileId, notifications } from "../common/helpers";
import { trpc } from "utils/trpc";

export const RecordFiles = ({
  recordId,
  isReadOnly = false,
}: {
  recordId: string;
  isReadOnly?: boolean;
}) => {
  const theme = useMantineTheme();

  const [isUploading, setIsUploading] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>();

  React.useEffect(() => {
    selectedFile && setIsModalOpen(true);
  }, [selectedFile]);

  const {
    data: recordFiles,
    isLoading,
    refetch,
  } = trpc.recordFile_findMany.useQuery({
    recordId,
  });

  const fileDelete = trpc.recordFile_delete.useMutation({
    onSuccess: () => {
      refetch();
      setIsModalOpen(false);
    },
  });

  // const fileUpdate = trpc.recordFile_update.useMutation({
  //   onSuccess: () => {
  //     refetch();
  //     setIsModalOpen(false);
  //   },
  // });

  const onFileUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("recordId", recordId);
    setIsUploading(true);
    axios
      .post(import.meta.env.VITE_SERVER_URI + "/api/recordFileUpload", data, {
        withCredentials: true,
      })
      .then((result) => {
        if (result.status === 200) {
          notifications.success("Datoteka uspješno dodana");
          refetch();
        }
      })
      .catch((error) =>
        notifications.error(
          "Greška prilikom dodavanja datoteke, pokušajte ponovno!"
        )
      )
      .finally(() => {
        setIsUploading(false);
      });
  };

  const { isSm, isMd } = useBreakpoints();

  const [iframeUrl, setIframeUrl] = React.useState<any>();

  React.useEffect(() => {
    if (selectedFile) {
      axios
        .get(
          `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${
            selectedFile?.id
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => setIframeUrl(URL.createObjectURL(res.data)));
    }
  }, [selectedFile]);

  return (
    <>
      <Accordion variant="contained">
        <Accordion.Item value="photos">
          <Accordion.Control
            disabled={!recordFiles?.length && isReadOnly}
            icon={
              <FontAwesomeIcon
                icon={faPaperclip}
                color={theme.colors.blue[5]}
                size="2x"
              />
            }
          >
            {recordFiles?.length
              ? "Priložena dokumentacija"
              : "Nema priloženih dokumenata"}
          </Accordion.Control>
          <Accordion.Panel>
            <Stack
              sx={(theme) => ({
                padding: 32,
                background: theme.colors.blue[0],
                borderRadius: theme.radius.md,
              })}
            >
              {!isReadOnly && (
                <Dropzone
                  onDrop={(files) =>
                    files.forEach((file) => onFileUpload(file))
                  }
                  loading={isUploading}
                  maxSize={10 * 1024 ** 2}
                >
                  <Text align="center">Ovdje priložite dokumente</Text>
                </Dropzone>
              )}

              <Group spacing="xl" sx={{ alignItems: "flex-start" }}>
                {recordFiles?.map((recordFile) => (
                  <Box
                    key={recordFile.id}
                    sx={{
                      cursor: "zoom-in",
                    }}
                    onClick={() => setSelectedFile({ ...recordFile })}
                  >
                    {recordFile.mimeType.includes("image") ? (
                      <Image
                        height={150}
                        radius="md"
                        src={getUrlFromRecordFileId(recordFile?.id)}
                      />
                    ) : (
                      <DocumentBox recordFile={recordFile} />
                    )}

                    {recordFile.fileKindId && (
                      <Text sx={{ textAlign: "center" }}>
                        {recordFile.FileKind?.name}
                      </Text>
                    )}
                  </Box>
                ))}
              </Group>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Modal
        withCloseButton={true}
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={isMd ? "960px" : "100%"}
        overflow="inside"
      >
        <Stack p="md">
          <Title order={5}>{selectedFile?.fileName}</Title>
          {selectedFile?.mimeType?.includes("image") ? (
            <Image src={getUrlFromRecordFileId(selectedFile?.id)} />
          ) : (
            <iframe width="100%" height={isSm ? 800 : 500} src={iframeUrl} />
          )}
          {/* <Select
            disabled={isReadOnly}
            // data={Object.keys(fileTypes).map((key) => ({
            //   value: key,
            //   label: fileTypes[key],
            // }))}
            data={[]}
            label="Tip dokumenta"
            value={selectedFile?.fileKindId}
            // onChange={async (fileType) => {
            //   const response = await updateFile({
            //     variables: {
            //       id: selectedFile?.id as string,
            //       fileType: fileType as EFileType,
            //     },
            //   });
            //   showNotificationsForMutationResponse(response);
            // }}
          /> */}
          {!isReadOnly && (
            <Group position="right">
              <Button
                variant="outline"
                color="pink"
                sx={{}}
                onClick={() =>
                  fileDelete.mutate({ id: selectedFile?.id as string })
                }
                leftIcon={<FontAwesomeIcon icon={faTrash} size="1x" />}
              >
                OBRIŠI
              </Button>
            </Group>
          )}
        </Stack>
      </Modal>
    </>
  );
};

const DocumentBox = ({ recordFile }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        height: 150,
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: theme.radius.md,
        padding: 8,
        gap: 8,
      })}
    >
      <Text>{recordFile.fileName}</Text>
      <ActionIcon
        disabled={isDownloading}
        onClick={() => {
          setIsDownloading(true);
          axios
            .get(
              `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${
                recordFile?.id
              }`,
              {
                responseType: "blob",
              }
            )
            .then((res) => FileDownload(res.data, recordFile.fileName || ""))
            .finally(() => setIsDownloading(false));
        }}
      >
        {isDownloading ? <Loader /> : <FontAwesomeIcon icon={faDownload} />}
      </ActionIcon>
    </Box>
  );
};
