import { Loader, Modal, Tooltip, useMantineTheme } from "@mantine/core";
import axios from "axios";
import React from "react";
import { IoEyeOutline, IoTrash } from "react-icons/io5";
import { MdDownload } from "react-icons/md";
import { formatDate, notifications } from "sdk/common/helpers";
import FileDownload from "js-file-download";
import { IMAGE_MIME_TYPE, MIME_TYPES } from "@mantine/dropzone";
import { trpc } from "utils/trpc";
import { openDeleteDialog } from "sdk/common/hooks";

type TProps = {
  data: Array<any>;
  onDocumentOpen: (id: string) => void;
  onDocumentDelete: (id: string) => void;
  loading: boolean;
};

export const DocumentsTableView = (props: TProps) => {
  const { data, loading } = props;

  const theme = useMantineTheme();

  const utils = trpc.useContext();

  const fileDelete = trpc.recordFile_delete.useMutation({
    onSuccess: () => {
      notifications.success("Datoteka uspješno obrisana");
      utils.documents_findMany.invalidate();
    },
  });

  const [selectedRecordFile, setSelectedRecordFile] = React.useState<any>();

  const [isDownloading, setIsDownloading] = React.useState(false);

  const [iframeUrl, setIframeUrl] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (msOfficeMimeTypes.includes(selectedRecordFile?.mimeType)) {
      setIframeUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${
          import.meta.env.VITE_SERVER_URI
        }/api/recordFileDownload/${selectedRecordFile?.id}`
      );
    } else if (selectedRecordFile) {
      axios
        .get(
          `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${
            selectedRecordFile?.id
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => setIframeUrl(URL.createObjectURL(res.data)));
    } else setIframeUrl(undefined);
  }, [selectedRecordFile]);

  return (
    <>
      <div className="flex w-full flex-1  flex-col  overflow-hidden">
        {/* <div>DocumentsTableView</div> */}
        <div className="grid grid-cols-[120px_1fr_1fr_80px_1fr] gap-8 rounded-t-md bg-blue-450 px-4 py-1 font-semibold">
          <div>Datum</div>
          <div>Naziv</div>
          <div>Vrsta</div>
          <div className="text-right">Veličina</div>
          <div></div>
        </div>
        <div className={`mt-2 flex h-full flex-1 flex-col gap-2 overflow-auto`}>
          {loading ? (
            <Loader mx="auto" />
          ) : data?.length ? (
            data?.map((item, index) => (
              <div
                key={item.id}
                className={`grid grid-cols-[120px_1fr_1fr_80px_1fr] gap-8 bg-[rgba(253,250,255,0.8)] p-4 text-blue-700 ${
                  index === data.length - 1 ? "rounded-b-md" : ""
                } transition-all hover:bg-white hover:shadow-documents-table-row`}
              >
                <div>{formatDate.toDateString(item.createdAt)}</div>
                <div className="truncate font-semibold ">{item.fileName}</div>
                <div className="truncate ">{item.FileKind?.name}</div>
                <div className="text-right">
                  {item.fileByteSize > 1024 ** 2
                    ? `${(item.fileByteSize / 1024 ** 2).toFixed(2)} MB`
                    : `${(item.fileByteSize / 1024).toFixed(0)} KB`}
                </div>
                <div className="flex justify-end gap-4 text-xl text-blue-500">
                  {[
                    ...IMAGE_MIME_TYPE,
                    MIME_TYPES.pdf,
                    ...msOfficeMimeTypes,
                  ].includes(item?.mimeType as string) && (
                    <Tooltip label="Pogledaj">
                      <div>
                        <IoEyeOutline
                          className="cursor-pointer"
                          onClick={() => setSelectedRecordFile(item)}
                        />
                      </div>
                    </Tooltip>
                  )}

                  <Tooltip label="Preuzmi">
                    <div>
                      <MdDownload
                        className="cursor-pointer"
                        onClick={() => {
                          setIsDownloading(true);
                          axios
                            .get(
                              `${
                                import.meta.env.VITE_SERVER_URI
                              }/api/recordFileDownload/${item?.id}`,
                              {
                                responseType: "blob",
                              }
                            )
                            .then((res) =>
                              FileDownload(res.data, item.fileName || "")
                            )
                            .finally(() => setIsDownloading(false));
                        }}
                      />
                    </div>
                  </Tooltip>

                  {/* <IoMailOpenOutline className="cursor-pointer" /> */}

                  <Tooltip label="Obriši">
                    <div>
                      <IoTrash
                        className="cursor-pointer text-pink-500"
                        onClick={() =>
                          openDeleteDialog({
                            onAccept() {
                              fileDelete.mutate({
                                id: item?.id as string,
                              });
                            },
                            titleText: "Izbriši datoteku",
                            contentText: "Potvrdom čete izbrisati datoteku",
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))
          ) : (
            <div className="bg-[rgba(253,250,255,0.8)] p-2 text-center font-medium text-blue-700">
              Mapa nema priloženih datoteka
            </div>
          )}
        </div>
      </div>
      <Modal
        opened={!!selectedRecordFile}
        onClose={() => setSelectedRecordFile(undefined)}
        styles={{
          modal: {
            width: theme.breakpoints.xl,
            maxWidth: "90%",
          },
          inner: { padding: "5%" },
        }}
      >
        {<iframe style={{ width: "100%", height: "66vh" }} src={iframeUrl} />}
      </Modal>
    </>
  );
};

const msOfficeMimeTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.visio",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
