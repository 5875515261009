import React from "react";

import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { formatDate } from "sdk/common/helpers";
import { trpc } from "utils/trpc";

export const SelectTrainingSchedule = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        onChange?: (e: any) => any;
      },
    ref: any
  ) => {
    const { hideLabel, onChange, ...rest } = props;

    const { orgId } = useParams();

    const { data, isLoading, isError } =
      trpc.trainingSchedule_findMany.useQuery({ orgId: orgId! });

    if (isLoading) return <Loader />;
    if (isError || !data) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Raspored treninga"}
        label={hideLabel ? undefined : "Raspored treninga"}
        {...rest}
        data={data?.map((item) => ({
          value: item.id,
          label: `${formatDate.toDateString(
            item.fromDate
          )} - ${formatDate.toDateString(item.toDate)}\t${item.name}`,
        }))}
        onChange={(e) => onChange?.(e)}
      />
    );
  }
);

SelectTrainingSchedule.displayName = "SelectTrainingSchedule";
