import { ActionIcon, Affix, useMantineTheme } from "@mantine/core";
import { TiPlus } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "sdk/common/hooks";

export const AddNewFAB = (props: any) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { isLg } = useBreakpoints();

  if (isLg) return null;

  return (
    <Affix position={{ bottom: 40, right: 40 }}>
      <ActionIcon
        color={theme.colors.blue[4]}
        variant="filled"
        radius="xl"
        size="xl"
        onClick={() => navigate("novi")}
      >
        <TiPlus size={24} />
      </ActionIcon>
    </Affix>
  );
};
