import {
  Grid,
  Space,
  Stack,
  Sx,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useSetTitle } from "sdk/common/hooks";
import { PrintHeader } from "./PrintHeader";
import { trpc } from "utils/trpc";

export const ActiveOrganizationsPrintScreen = () => {
  const theme = useMantineTheme();

  const { data: organizations } = trpc.organization_findMany.useQuery();

  useSetTitle("Aktivni klubovi");

  const textHeaderStyle: Sx = {
    marginRight: 16,
    fontWeight: "bold",
  };

  return (
    <Stack p="xl" sx={{ width: "210mm", overflow: "auto" }}>
      <PrintHeader />
      <Title order={1} align="center">
        Aktivni klubovi
      </Title>
      <Space sx={{ height: 12 }} />
      <Grid columns={13} sx={{ backgroundColor: theme.colors.blue[4] }}>
        <React.Fragment>
          <Grid.Col span={1}>
            <Text sx={[textHeaderStyle, { textAlign: "left" }]}>R.b.</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text sx={textHeaderStyle}>Naziv članice</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text sx={textHeaderStyle}>Adresa sjedišta</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text sx={textHeaderStyle}>Kontakt</Text>
          </Grid.Col>
          <Grid.Col span={3}>
            <Text sx={textHeaderStyle}>Odgovorne osobe</Text>
          </Grid.Col>
        </React.Fragment>
      </Grid>
      <Grid columns={13}>
        {organizations?.map((org, index) => (
          <React.Fragment key={org.id}>
            <Grid.Col
              sx={{
                backgroundColor:
                  index % 2 === 0 ? theme.colors.blue[1] : "transparent",
              }}
              span={1}
            >
              <Text>{index + 1}.</Text>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={{
                backgroundColor:
                  index % 2 === 0 ? theme.colors.blue[1] : "transparent",
              }}
            >
              <Text>{org.name}</Text>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={{
                backgroundColor:
                  index % 2 === 0 ? theme.colors.blue[1] : "transparent",
              }}
            >
              <Text>
                {org.address ? org.address : "-"}
                {org.zipCode ? `, ${org.zipCode}` : ""}
                {org.city ? `, ${org.city}` : ""}
              </Text>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={{
                backgroundColor:
                  index % 2 === 0 ? theme.colors.blue[1] : "transparent",
              }}
            >
              <Stack spacing={0} sx={{ fontSize: 12 }}>
                <Text>{org.email ? org.email : "-"}</Text>
                <Text>
                  {org.contactPersonPhone ? org.contactPersonPhone : ""}
                </Text>
                <Text>{org.webAddress ? org.webAddress : ""}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={{
                backgroundColor:
                  index % 2 === 0 ? theme.colors.blue[1] : "transparent",
              }}
            >
              <Stack spacing={0} sx={{ fontSize: 12 }}>
                <Text>
                  {org.authorizedPersonName ? org.authorizedPersonName : "-"}
                </Text>
              </Stack>
            </Grid.Col>
          </React.Fragment>
        ))}
      </Grid>
      <Space sx={{ height: 24 }} />
      <PerSportDetailed orgs={organizations} />
    </Stack>
  );
};

const PerSportDetailed = ({ orgs }) => {
  const theme = useMantineTheme();
  const { data: sports } = trpc.sport_findMany.useQuery();

  const textHeaderStyle: Sx = {
    marginRight: 16,
    fontWeight: "bold",
  };

  if (orgs === undefined) return null;

  return (
    <>
      <Grid columns={1} sx={{ backgroundColor: theme.colors.blue[4] }}>
        <React.Fragment>
          <Grid.Col span={1}>
            <Text sx={[textHeaderStyle]}>Po sportu (detaljno)</Text>
          </Grid.Col>
        </React.Fragment>
      </Grid>

      {sports?.map((sport) => (
        <React.Fragment key={sport.id}>
          <Stack>
            <Grid columns={13} sx={{ backgroundColor: theme.colors.blue[3] }}>
              <Grid.Col span={1}></Grid.Col>
              <Grid.Col span={6}>
                <Text>{sport.name}</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text sx={{ fontSize: 12, opacity: 0.4 }}>
                  {orgs && orgs.filter((x) => x.sportId === sport.id).length < 1
                    ? "Nema klubova"
                    : null}
                </Text>
              </Grid.Col>
            </Grid>
            {orgs
              ?.filter((x) => x.sportId === sport.id)
              .map((org, index) => (
                <Grid
                  sx={{ fontSize: 12, height: 32 }}
                  key={org.id}
                  columns={13}
                >
                  <Grid.Col
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? theme.colors.blue[1] : "transparent",
                    }}
                    span={1}
                  >
                    <Text>{index + 1}.</Text>
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? theme.colors.blue[1] : "transparent",
                    }}
                  >
                    <Text>{org.name}</Text>
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? theme.colors.blue[1] : "transparent",
                    }}
                  >
                    <Text>
                      {org.address ? org.address : "-"}
                      {org.zipCode ? `, ${org.zipCode}` : ""}
                      {org.city ? `, ${org.city}` : ""}
                    </Text>
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? theme.colors.blue[1] : "transparent",
                    }}
                  >
                    <Stack spacing={0} sx={{ fontSize: 12 }}>
                      <Text>{org.email ? org.email : "-"}</Text>
                      <Text>
                        {org.contactPersonPhone ? org.contactPersonPhone : ""}
                      </Text>
                      <Text>{org.webAddress ? org.webAddress : ""}</Text>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? theme.colors.blue[1] : "transparent",
                    }}
                  >
                    <Stack spacing={0} sx={{ fontSize: 12 }}>
                      <Text>
                        {org.authorizedPersonName
                          ? org.authorizedPersonName
                          : "-"}
                      </Text>
                    </Stack>
                  </Grid.Col>
                </Grid>
              ))}
          </Stack>
        </React.Fragment>
      ))}
    </>
  );
};
