import React from "react";
import { Select, SelectProps, Text } from "@mantine/core";
import { trpc } from "utils/trpc";
import { Spinner } from "../Spinner";

export const SelectEmploymentType = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & { hideLabel?: boolean },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    const { data, isLoading, isError } =
      trpc.employmentType_findMany.useQuery();

    const values = data?.map((c) => ({
      value: c?.id,
      label: c?.name,
    }));

    if (isLoading) return <Spinner />;
    if (!data || isError) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        sx={{ width: 500, "&:mantine-ActionIcon-root": { display: "none" } }}
        clearable
        placeholder={!hideLabel ? undefined : "Status trenera"}
        label={hideLabel ? undefined : "Status trenera"}
        {...rest}
        data={values || []}
      />
    );
  }
);

SelectEmploymentType.displayName = "SelectEmploymentType";
