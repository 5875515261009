import React from "react";
import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { trpc } from "utils/trpc";

export const SelectOrganization = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        filterBySportId?: string;
      },
    ref: any
  ) => {
    const { hideLabel, filterBySportId, ...rest } = props;

    const { data, isLoading, error } = trpc.organization_findMany.useQuery();

    const organizations =
      data?.filter((org) =>
        filterBySportId ? org.sportId === filterBySportId : true
      ) || [];

    if (isLoading) return <Loader />;
    if ((!isLoading && !data) || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Sportski klub"}
        label={hideLabel ? undefined : "Sportski klub"}
        {...rest}
        data={organizations?.map((o) => ({ value: o.id, label: o.name }))}
      />
    );
  }
);

SelectOrganization.displayName = "SelectOrganization";
