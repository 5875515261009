import { z } from "zod";
import { idSchema } from "../helpers";
export const trainingEventUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    trainingScheduleId: idSchema,
    day: z.string().min(1),
    startTime: z.date({
        required_error: "Obvezan podatak",
        invalid_type_error: "Obvezan podatak",
    }),
    endTime: z
        .date({
        invalid_type_error: "Obvezan podatak",
    })
        .nullish(),
    coachId: idSchema.nullish(),
    venueId: idSchema.nullish(),
    ageGroupId: idSchema.nullish(),
});
