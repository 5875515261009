import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Modal,
  Tabs,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formSubmitErrorHandler, notifications } from "sdk/common/helpers";
import { openDeleteDialog, useSetTitle } from "sdk/common/hooks";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { RadioGender } from "sdk/components/Radio/RadioGender";
import { SelectMembershipType } from "sdk/components/Selects/SelectMembershipType";
import { TbExternalLink, TbTrash } from "react-icons/tb";
import { SelectMemberCategory } from "sdk/components/Selects/SelectMemberCategory";

import { CardContainer } from "./components";
import { SelectMemberRole } from "sdk/components/Selects/SelectMemberRole";
import { MdOutlineCancel } from "react-icons/md";
import { trpc } from "utils/trpc";
import { memberUpdateSchema } from "models";
import { TabPanelPhysique } from "./components/TabPanelPhysique";
import { TabPanelAgeGroup } from "./components/TabPanelAgeGroup";
import { TabPanelCategory } from "./components/TabPanelCategory";
import { TabPanelMedical } from "./components/TabPanelMedical";
import { ContentContainer } from "sdk/components/ContentContainer";
import { IoMdCheckmarkCircle } from "react-icons/io";
import "dayjs/locale/hr";

export const UrediClana = () => {
  const { id } = useParams();

  const {
    data: member,
    isLoading,
    isError,
  } = trpc.member_find.useQuery({ id: id! });

  useSetTitle(
    member ? `${member?.firstName || ""} ${member?.lastName || ""}` : "",
    {
      showBackButton: true,
    }
  );

  if (isLoading) return <Loader size="xl" m="auto" />;
  if (!member || isError) return <Text>Greška</Text>;

  return <MemberEditForm />;
};

const MemberEditForm = () => {
  const { id, orgId } = useParams();

  const { data: member } = trpc.member_find.useQuery({ id: id! });

  const navigate = useNavigate();
  const theme = useMantineTheme();

  const utils = trpc.useContext();

  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    React.useState(false);

  const memberUpdate = trpc.member_update.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.member_find.invalidate({ id });
      utils.member_findMany.reset();
      navigate(`/pregled/klubovi/${orgId}/clanovi?status=ACTIVE`);
    },
    onError: () => {
      notifications.error();
    },
  });

  const memberDelete = trpc.member_delete.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.member_find.invalidate({ id });
      utils.member_findMany.reset();
      navigate(`/pregled/klubovi/${orgId}/clanovi?status=ACTIVE`);
    },
    onError: () => {
      notifications.error();
    },
  });

  const [activeTab, setActiveTab] = React.useState<string | null>(
    "OSNOVNI PODACI"
  );

  const form = useForm({
    initialValues: member,
    validate: zodResolver(memberUpdateSchema),
  });

  const CurrentMedicalRecord = member?.MemberMedicalRecords?.[0];

  if (!member) return null;

  return (
    <>
      <ContentContainer className="h-full">
        <div className="mx-auto flex  h-full max-w-[1400px] gap-16 overflow-auto p-8">
          <div>
            <Text weight="bold">{activeTab}</Text>
            <div>
              <ProfilePicture
                recordId={id as string}
                avatarRecordFileId={form.values.avatarRecordFileId || ""}
                onChange={(value) =>
                  form.setFieldValue("avatarRecordFileId", value)
                }
              />
            </div>
          </div>

          <Tabs
            value={activeTab}
            onTabChange={setActiveTab}
            defaultValue="OSNOVNI PODACI"
            orientation="vertical"
            placement="right"
            styles={{
              root: { flex: 1, height: "100%", overflow: "auto" },
              tabsList: {
                border: "none",

                width: "max(25%, 250px)",
                background: "transparent",
                padding: theme.spacing.xl,
              },
              tab: {
                height: "36px",
                color: "#97A8BF",
                lineHeight: "24px",

                '&[aria-selected="true"]': {
                  color: "#1A3560",
                  ">*": {
                    borderBottomWidth: "4px",
                    borderBottomStyle: "solid",
                    borderColor: "#1A3560",
                  },
                },
                ":hover": {
                  background: "transparent",
                  borderColor: theme.colors.blue[4],
                  color: "#617A9E",
                },
                ":disabled": {
                  opacity: 0.2,
                },
              },
              tabLabel: {
                fontWeight: "bold",
              },
              panel: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
                overflow: "auto",
              },
            }}
          >
            <Tabs.List>
              <div className="flex h-full flex-col justify-between ">
                <div className="flex flex-col gap-4">
                  <Tabs.Tab value="OSNOVNI PODACI">OSNOVNI PODACI</Tabs.Tab>
                  <Tabs.Tab
                    value="KATEGORIZACIJA SPORTAŠA"
                    disabled={form.values.membershipTypeId !== "RS"}
                  >
                    KATEGORIZACIJA SPORTAŠA
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="UZRASNA KATEGORIJA"
                    disabled={
                      !["RS", "NRS"].includes(
                        form.values?.membershipTypeId || ""
                      )
                    }
                  >
                    UZRASNA KATEGORIJA
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="TJELESNA MJERENJA"
                    disabled={
                      !["RS", "NRS"].includes(
                        form.values?.membershipTypeId || ""
                      )
                    }
                  >
                    TJELESNA MJERENJA
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="ZDRAVSTVENI PREGLEDI"
                    disabled={
                      !["RS", "NRS"].includes(
                        form.values?.membershipTypeId || ""
                      )
                    }
                  >
                    ZDRAVSTVENI PREGLEDI
                  </Tabs.Tab>
                </div>
                {activeTab === "OSNOVNI PODACI" && (
                  <div className="flex flex-col gap-4">
                    {form.values.status === "INACTIVE" && (
                      <button
                        onClick={() =>
                          memberUpdate.mutate({
                            id: form.values.id,
                            orgId: form.values.orgId,
                            status: "ACTIVE",
                          })
                        }
                        className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-green-500"
                      >
                        <IoMdCheckmarkCircle size={22} />
                        Aktiviraj člana
                      </button>
                    )}

                    {form.values.status === "ACTIVE" && (
                      <>
                        <button
                          onClick={() => setShowDeactivateConfirmation(true)}
                          className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-blue-500"
                        >
                          <MdOutlineCancel size={22} />
                          Otkaži članstvo
                        </button>
                        <button
                          onClick={() =>
                            openDeleteDialog({
                              onAccept() {
                                memberDelete.mutate({ id: id as string });
                              },
                              titleText: "Izbriši člana",
                              contentText: "Potvrdom čete izbrisati člana",
                            })
                          }
                          className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-pink-500"
                        >
                          <TbTrash size={22} />
                          <div>Izbriši člana</div>
                        </button>
                      </>
                    )}

                    <div className="h-px bg-blue-400" />

                    <Button
                      tabIndex={-1}
                      sx={{ minWidth: 190 }}
                      variant="outline"
                      onClick={() => navigate(-1)}
                      disabled={memberUpdate.isLoading}
                    >
                      Odustani
                    </Button>
                    <Button
                      // leftIcon={<MdSave size={20} />}
                      sx={{ minWidth: 190 }}
                      loading={memberUpdate.isLoading}
                      onClick={() =>
                        form.onSubmit(
                          (values) => memberUpdate.mutate({ ...values }),
                          formSubmitErrorHandler()
                        )()
                      }
                    >
                      Spremi
                    </Button>
                  </div>
                )}
              </div>
            </Tabs.List>

            <Tabs.Panel value="OSNOVNI PODACI">
              <div className="flex h-full flex-col gap-4 overflow-auto px-4">
                <CardContainer>
                  <TextInput
                    sx={{ width: "auto" }}
                    label="Ime"
                    {...form.getInputProps("firstName")}
                    withAsterisk
                  />
                  <TextInput
                    sx={{ width: "auto" }}
                    label="Prezime"
                    {...form.getInputProps("lastName")}
                    withAsterisk
                  />
                  <TextInput
                    sx={{ width: "auto" }}
                    label="OIB"
                    {...form.getInputProps("oib")}
                    onChange={(e) =>
                      /^[0-9]{0,11}$/.test(e.target.value) &&
                      form.setFieldValue("oib", e.target.value)
                    }
                    withAsterisk
                  />
                  <DatePicker
                    sx={{ width: "auto" }}
                    locale="hr"
                    label="Datum rođenja"
                    {...form.getInputProps("dateOfBirth")}
                    withAsterisk
                  />
                  <RadioGender
                    sx={{ width: "auto" }}
                    {...form.getInputProps("gender")}
                    withAsterisk
                  />
                </CardContainer>

                <CardContainer>
                  <Group noWrap>
                    <DatePicker
                      withAsterisk
                      sx={{ flex: 2 }}
                      locale="hr"
                      label="Početak članstva"
                      maxDate={form.values.membershipEndDate || undefined}
                      {...form.getInputProps("membershipStartDate")}
                    />
                    {form.values.status === "INACTIVE" && (
                      <DatePicker
                        sx={{ flex: 1 }}
                        locale="hr"
                        label="Datum kraja članstva"
                        disabled
                        minDate={form.values.membershipStartDate || undefined}
                        {...form.getInputProps("membershipEndDate")}
                      />
                    )}
                  </Group>

                  <Group noWrap align="flex-start">
                    <SelectMembershipType
                      withAsterisk
                      sx={{ flex: 2, width: "100%" }}
                      {...form.getInputProps("membershipTypeId")}
                    />

                    {form.values.membershipTypeId === "O" && (
                      <SelectMemberRole
                        {...form.getInputProps("memberRole")}
                        sx={{ flex: 1, width: "100%" }}
                      />
                    )}
                  </Group>

                  {["RS", "NRS"].includes(
                    form.values.membershipTypeId || ""
                  ) && (
                    <>
                      {form.values.membershipTypeId === "RS" && (
                        <>
                          <TextInput
                            sx={{ width: "auto" }}
                            label="Naziv nacionalne selekcije"
                            {...form.getInputProps("nationalSelectionName")}
                          />

                          <Group noWrap align="flex-end">
                            <SelectMemberCategory
                              styles={{
                                input: { borderRadius: theme.radius.md },
                              }}
                              disabled
                              value={member.CurrentCategory?.categoryNo}
                            />
                            <DatePicker
                              styles={{
                                input: { borderRadius: theme.radius.md },
                              }}
                              sx={{ flexGrow: 1 }}
                              disabled
                              label="Datum početka"
                              value={member.CurrentCategory?.dateFrom}
                            />
                            <DatePicker
                              styles={{
                                input: { borderRadius: theme.radius.md },
                              }}
                              sx={{ flexGrow: 1 }}
                              disabled
                              label="Datum završetka"
                              value={member.CurrentCategory?.dateTo}
                            />
                            <ActionIcon
                              color={theme.colors.blue[5]}
                              variant="subtle"
                              radius="xl"
                              size="xl"
                              onClick={() =>
                                setActiveTab("KATEGORIZACIJA SPORTAŠA")
                              }
                            >
                              <TbExternalLink size={24} />
                            </ActionIcon>
                          </Group>
                        </>
                      )}

                      <Group noWrap align="flex-end">
                        <TextInput
                          disabled
                          label="Uzrasna kategorija"
                          value={member.CurrentAgeGroup?.AgeGroup?.name}
                          styles={{
                            input: { borderRadius: theme.radius.md },
                          }}
                          sx={{ flexGrow: 1 }}
                        />
                        <ActionIcon
                          color={theme.colors.blue[5]}
                          variant="subtle"
                          radius="xl"
                          size="xl"
                          onClick={() => setActiveTab("UZRASNA KATEGORIJA")}
                        >
                          <TbExternalLink size={24} />
                        </ActionIcon>
                      </Group>

                      <Group align="flex-end" noWrap>
                        <Group
                          sx={{ flexGrow: 1 }}
                          noWrap
                          align={"flex-end"}
                          spacing="xs"
                        >
                          <TextInput
                            disabled
                            label="Visina"
                            value={member.CurrentPhysique?.height || ""}
                            styles={{
                              input: { borderRadius: theme.radius.md },
                            }}
                          />
                          <Text>cm</Text>
                        </Group>
                        <Group
                          sx={{ flexGrow: 1 }}
                          noWrap
                          align={"flex-end"}
                          spacing="xs"
                        >
                          <TextInput
                            disabled
                            label="Težina"
                            value={member.CurrentPhysique?.weight || ""}
                            styles={{
                              input: { borderRadius: theme.radius.md },
                            }}
                          />
                          <Text>kg</Text>
                        </Group>

                        <ActionIcon
                          color={theme.colors.blue[5]}
                          variant="subtle"
                          radius="xl"
                          size="xl"
                          onClick={() => setActiveTab("TJELESNA MJERENJA")}
                        >
                          <TbExternalLink size={24} />
                        </ActionIcon>
                      </Group>

                      <Group align="flex-end" noWrap>
                        <DatePicker
                          styles={{
                            root: { flexGrow: 1 },
                            input: { borderRadius: theme.radius.md },
                          }}
                          disabled
                          label="Datum zdravstvenog pregleda"
                          value={CurrentMedicalRecord?.date}
                        />

                        <ActionIcon
                          color={theme.colors.blue[5]}
                          variant="subtle"
                          radius="xl"
                          size="xl"
                          onClick={() => setActiveTab("ZDRAVSTVENI PREGLEDI")}
                        >
                          <TbExternalLink size={24} />
                        </ActionIcon>
                      </Group>
                    </>
                  )}
                </CardContainer>

                <CardContainer>
                  <TextInput
                    label="E-mail"
                    {...form.getInputProps("email")}
                    sx={{ width: "auto" }}
                  />
                  <TextInput
                    label="Telefonski broj"
                    {...form.getInputProps("phoneNumber")}
                    sx={{ width: "auto" }}
                    onChange={(e) =>
                      /^[0-9 ]*$/.test(e.target.value) &&
                      form.setFieldValue("phoneNumber", e.target.value)
                    }
                  />
                  <Group noWrap>
                    <TextInput
                      sx={{ flex: 2 }}
                      label="Adresa"
                      {...form.getInputProps("address")}
                    />
                    <TextInput
                      sx={{ flex: 1 }}
                      label="Kućni broj"
                      {...form.getInputProps("houseNo")}
                    />
                  </Group>
                  <Group noWrap>
                    <TextInput
                      sx={{ flex: 1 }}
                      label="Poštanski broj"
                      {...form.getInputProps("zipCode")}
                    />
                    <TextInput
                      sx={{ flex: 2 }}
                      label="Mjesto"
                      {...form.getInputProps("city")}
                    />
                  </Group>

                  <TextInput
                    sx={{ width: "auto" }}
                    label="Imena roditelja"
                    {...form.getInputProps("parentNames")}
                  />
                </CardContainer>
              </div>
            </Tabs.Panel>

            <Tabs.Panel value="KATEGORIZACIJA SPORTAŠA">
              <TabPanelCategory />
            </Tabs.Panel>

            <Tabs.Panel value="UZRASNA KATEGORIJA">
              <TabPanelAgeGroup />
            </Tabs.Panel>
            <Tabs.Panel value="TJELESNA MJERENJA">
              <TabPanelPhysique />
            </Tabs.Panel>
            <Tabs.Panel value="ZDRAVSTVENI PREGLEDI">
              <TabPanelMedical />
            </Tabs.Panel>
          </Tabs>
        </div>
      </ContentContainer>

      <Modal
        opened={showDeactivateConfirmation}
        onClose={() => {
          setShowDeactivateConfirmation(false);
        }}
        title="Otkazivanje članstva"
      >
        <Box>
          <DatePicker
            my={64}
            sx={{ width: "auto" }}
            label="Datum kraja članstva"
            initialLevel="date"
            disabled={!form.values.membershipStartDate}
            minDate={form.values.membershipStartDate || undefined}
            {...form.getInputProps("membershipEndDate")}
          />
          <Group position="right">
            <Button
              variant="outline"
              disabled={memberUpdate.isLoading}
              onClick={() => setShowDeactivateConfirmation(false)}
              sx={{}}
            >
              Odustani
            </Button>
            <Button
              loading={memberUpdate.isLoading}
              disabled={!form.values.membershipEndDate}
              onClick={() =>
                memberUpdate.mutate({
                  id: form.values.id,
                  orgId: form.values.orgId,
                  status: "INACTIVE",
                  membershipEndDate: form.values.membershipEndDate,
                })
              }
            >
              Potvrdi
            </Button>
          </Group>
        </Box>
      </Modal>
    </>
  );
};
