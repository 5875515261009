import { useSetTitle } from "sdk/common/hooks";
import { CoachDataEntryForm } from "./components/CoachDataEntryForm";
import { ulid } from "ulid";
import { useParams } from "react-router-dom";
export const NoviTrener = () => {
  useSetTitle("Novi trener", { showBackButton: true });

  const { orgId } = useParams();

  return (
    <CoachDataEntryForm
      initialValues={{
        id: ulid(),
        status: "ACTIVE",
        orgId,
      }}
    />
  );
};
