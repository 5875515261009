import React from "react";
import { Select, SelectProps } from "@mantine/core";

export const MemberStatuses = [
  {
    value: "ACTIVE",
    label: "Aktivan",
  },
  {
    value: "INACTIVE",
    label: "Neaktivan",
  },
];

export const SelectStatus = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Status"}
        label={hideLabel ? undefined : "Status"}
        {...rest}
        data={MemberStatuses}
      />
    );
  }
);

SelectStatus.displayName = "SelectStatus";
