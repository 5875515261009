import { Avatar, Button } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IoChevronBack, IoClose } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getUrlFromRecordFileId } from "sdk/common/helpers";
import { useMe, useOnClickOutside } from "sdk/common/hooks";
import { ActionButton } from "sdk/components/ActionIcon";
import { useAppState } from "state/useAppState";
import { trpc } from "utils/trpc";

export const DefaultHeader = () => {
  const navigate = useNavigate();
  const [title, showHeaderBackButton] = useAppState((state) => [
    state.title,
    state.showHeaderBackButton,
  ]);

  return (
    <div className="flex flex-row items-center gap-2 p-4 pr-6">
      {showHeaderBackButton && <BackButton onClick={() => navigate(-1)} />}
      <div className="flex-1 p-1 text-2xl font-bold leading-4 text-blue-700">
        {title}
      </div>
      <ProfileAvatar />
    </div>
  );
};

const ProfileAvatar = (props) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const ref = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [resetStore] = useAppState((state) => [state.resetStore]);

  useEffect(() => {
    setIsDropDownOpen(false);
  }, [location.pathname]);

  useOnClickOutside(ref, () => setIsDropDownOpen(false));

  const user = useMe();

  const utils = trpc.useContext();

  const userLogout = trpc.user_logout.useMutation({
    onSuccess: () => {
      resetStore();
      utils.invalidate();
      navigate("/prijava");
    },
  });

  if (!user) return null;

  return (
    <div
      ref={ref}
      className={`relative flex flex-row items-center gap-2 text-blue-700 ${
        isDropDownOpen ? "" : "cursor-pointer"
      }`}
      onClick={() => setIsDropDownOpen(true)}
    >
      <div className="font-bold">{`${user?.firstName} ${user?.lastName}`}</div>
      <Avatar
        src={getUrlFromRecordFileId(user?.avatarRecordFileId)}
        size="md"
        radius="xl"
      />
      {isDropDownOpen && (
        <div className="absolute right-0 top-0 z-50 flex w-64 flex-col items-center gap-4 rounded-lg bg-white p-4 pt-8 shadow-lg">
          <div
            className="absolute top-2 right-4 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsDropDownOpen(false);
            }}
          >
            <IoClose size={20} className="text-blue-500 hover:text-blue-700" />
          </div>

          <Avatar
            src={getUrlFromRecordFileId(user?.avatarRecordFileId)}
            className="h-24 w-24 self-center rounded-full"
          />

          <div className="flex flex-col items-center">
            <div className="flex flex-col gap-2 text-lg font-bold">
              {`${user?.firstName} ${user?.lastName}`}
            </div>
            <div className="flex flex-col gap-2 text-sm">
              {`${user?.Organization?.name}`}
            </div>
          </div>
          <div className="">
            <NavLink
              to={`/postavke/korisnici/uredi/${user?.id}`}
              className="cursor-pointer text-blue-500 no-underline hover:text-blue-700 hover:underline"
            >
              <div className="flex flex-row items-baseline gap-2 ">
                Izmjena profila
              </div>
            </NavLink>
          </div>
          <div className="p-4">
            <Button
              className="bg-red-500 hover:bg-red-800"
              size="sm"
              onClick={() => userLogout.mutate()}
              loading={userLogout.isLoading}
            >
              Odjava
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const BackButton = (props) => {
  return (
    <ActionButton onClick={props.onClick}>
      <IoChevronBack size={24}></IoChevronBack>
    </ActionButton>
  );
};
