import { ActionIcon } from "@mantine/core";

type Props = {
  onClick: () => void;
  children: React.ReactNode;
  label?: string;
  disabled?: boolean;
  className?: string;
};
export const ActionButton = (props: Props) => {
  const { children, onClick, disabled = false, className = "" } = props;
  return (
    <div
      className={`flex flex-row items-center text-blue-500 hover:text-blue-700 ${className}`}
    >
      <ActionIcon
        radius="xl"
        size="xl"
        onClick={onClick}
        disabled={disabled}
        sx={{
          boxShadow: "0px 2px 4px 0px rgba(50, 88, 149, 0.4);",
          backgroundColor: " rgba(255, 255, 255, 0.75);",
          color: "rgb(97,122,158);",
          ":hover": {
            color: "rgb(26,53,96);",
            boxShadow: "0px 8px 12px 0px rgba(50, 88, 149, 0.4);",
            backgroundColor: "#ffffff;",
          },
          ":active": {
            boxShadow: "0px 2px 4px 0px rgba(50, 88, 149, 0.4);",
          },
        }}
      >
        {children}
      </ActionIcon>
      {props.label && <div className="ml-2 ">{props.label}</div>}
    </div>
  );
};
