import { z } from "zod";
import { idSchema, isValidOIB } from "../helpers";
export const coachUpsertSchema = z.object({
    id: idSchema,
    orgId: idSchema,
    avatarRecordFileId: z.string().nullish(),
    firstName: z
        .string({
        required_error: "Ime je obvezan podatak",
        invalid_type_error: "Ime je obvezan podatak",
    })
        .min(1, "Ime je obvezan podatak"),
    lastName: z
        .string({
        required_error: "Prezime je obvezan podatak",
        invalid_type_error: "Prezime je obvezan podatak",
    })
        .min(1, "Prezime je obvezan podatak"),
    gender: z
        .string({
        required_error: "Spol je obvezan podatak",
        invalid_type_error: "Spol je obvezan podatak",
    })
        .min(1, "Spol je obvezan podatak"),
    dateOfBirth: z.date({
        required_error: "Datum rođenja je obvezan podatak",
        invalid_type_error: "Datum rođenja je obvezan podatak",
    }),
    email: z.string().email("E-mail je neispravan").nullish(),
    phoneNumber: z.string().nullish(),
    description: z.string().nullish(),
    coachEmploymentTypeId: z
        .string({
        invalid_type_error: "Status trenera je obvezan podatak",
        required_error: "Status trenera je obvezan podatak",
    })
        .min(1, { message: "Status trenera je obvezan podatak" }),
    employeeQualificationId: z
        .string({
        invalid_type_error: "Stručna osposobljenost je obvezan podatak",
        required_error: "Stručna osposobljenost je obvezan podatak",
    })
        .min(1, { message: "Stručna osposobljenost je obvezan podatak" }),
    oib: z
        .string({
        required_error: "OIB je obvezan podatak",
        invalid_type_error: "OIB je obvezan podatak",
    })
        .refine((value) => isValidOIB(value?.toString()), (value) => ({
        message: value.length ? "OIB je neispravan" : "OIB je obvezan podatak",
    })),
    nationalSelectionName: z.string().nullish(),
    workLocation: z.string().nullish(),
    ageGroups: z.string().nullish(),
    workStartDate: z.date().nullish(),
    license: z.string().nullish(),
});
