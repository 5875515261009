import { z } from "zod";
import { idSchema } from "../helpers";
export const categoryUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    memberId: idSchema,
    categoryNo: z.string({
        required_error: "Obvezan podatak",
        invalid_type_error: "Obvezan podatak",
    }),
    dateFrom: z.date({ invalid_type_error: "Obvezan podatak" }),
    dateTo: z.date().nullish(),
    description: z.string().nullish(),
});
