import { Select, SelectProps } from "@mantine/core";
import React from "react";

export const memberCategories = [
  "Nekategoriziran",
  "I.",
  "II.",
  "III.",
  "IV.",
  "V.",
  "VI.",
].map((value) => ({
  value,
  label: value,
}));

export const SelectMemberCategory = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        valuesOnly?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, valuesOnly = false, ...rest } = props;

    // if (loading) return <Loader />;
    // if ((!loading && !data) || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        withAsterisk
        clearable
        placeholder={!hideLabel ? undefined : "Kategorija"}
        label={hideLabel ? undefined : "Kategorija"}
        {...rest}
        data={memberCategories.filter((item) =>
          valuesOnly ? item.value !== "Nekategoriziran" : true
        )}
      />
    );
  }
);

SelectMemberCategory.displayName = "SelectMemberCategory";
