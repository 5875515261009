import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Button,
  Drawer,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { TbFilter } from "react-icons/tb";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpoints, useSearchParams, useSetTitle } from "sdk/common/hooks";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { MdClose } from "react-icons/md";
import { removeFalsies } from "sdk/common/helpers";
import { useSwipeable } from "react-swipeable";
import { SelectSport } from "sdk/components/Selects/SelectSport";
import _ from "lodash";
import { AddNewFAB } from "sdk/components/AddNewFAB";
import { TiPlus } from "react-icons/ti";
import { ActionButton } from "sdk/components/ActionIcon";
import { HiPencil } from "react-icons/hi";
import { IoEnterOutline } from "react-icons/io5";
import {
  MemberStatuses,
  SelectStatus,
} from "sdk/components/Selects/SelectStatus";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";

export const Klubovi = () => {
  useSetTitle("Članice");
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [params, setParams] = useSearchParams();

  const [drawerOpened, setDrawerOpened] = React.useState(false);

  const { data, isLoading } = trpc.organization_findMany.useQuery();

  const organizations = data?.filter(
    (item) =>
      (!params?.status || item.status === params.status) &&
      (!params?.sportId || item.sportId === params.sportId) &&
      (!params.searchText ||
        item.keywords
          ?.toLowerCase()
          ?.includes(params.searchText?.toLowerCase()))
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setDrawerOpened(false),
  });

  const columns = React.useMemo<TableColumn<any>[]>(
    () => [
      {
        Header: "",
        id: "eye",
        accessor: "",
        Cell: () => <IoEnterOutline size={20} />,
        width: 36,
        className: "pt-1 text-blue-400 hover:text-blue-700",
      },
      {
        Header: "",
        id: "edit",
        accessor: "",
        width: 36,
        className: "opacity-50 hover:opacity-100 text-right pt-1",
        Cell: (data) => {
          return (
            <HiPencil
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/pregled/klubovi/${data.cell.row.original.id}/detalji/uredi`
                );
              }}
            />
          );
        },
      },
      {
        Header: "NAZIV KLUBA",
        accessor: "name",
        width: 400,
        className: "text-blue-700 uppercase font-bold text-sm",
      },
      {
        Header: "SPORT",
        accessor: "Sport.name",
        className: "text-blue-500 capitalize",
        width: 250,
      },

      {
        Header: "REG.SPORT.",
        width: 120,
        accessor: "regMembersCount",
        className: "text-blue-500 text-right",
        headerClassName: "text-right",
      },
      {
        Header: "NEREG. SPORT.",
        width: 120,
        accessor: "nonRegMembersCount",
        className: "text-blue-500 text-right",
        headerClassName: "text-right",
      },
      {
        Header: "OSTALI ČLANOVI",
        width: 130,
        accessor: "otherMembersCount",
        className: "text-blue-500 text-right",
        headerClassName: "text-right",
      },
      {
        Header: "STATUS",
        width: 200,
        accessor: "status",
        className: "text-blue-500 text-center",
        headerClassName: "pl-4 text-center",
        Cell: (data) => (
          <>
            {MemberStatuses.find(
              (s) => s.value === data.cell.row.original.status
            )?.label || null}
          </>
        ),
      },
      {
        Header: "",
        id: "whitespace",
        className: "text-blue-500 text-right",
        headerClassName: "text-right",
      },
    ],
    []
  );

  const { data: sports } = trpc.sport_findMany.useQuery();

  const filterLabels = {
    sportId: sports?.find((sport) => sport.id === params.sportId)?.name,
    searchText: params.searchText,
    status: MemberStatuses.find(
      (item) => item.value === (params?.status as string)
    )?.label,
  };

  const { isLg } = useBreakpoints();

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        <div className="flex flex-row items-center justify-between pb-4">
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži po nazivu"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({
                ...params,
                searchText: e.currentTarget.value,
              })
            }
          />

          <Group spacing="xl" noWrap>
            {isLg && (
              <Button
                onClick={() => navigate("/postavke/noviklub")}
                leftIcon={<TiPlus size={20} />}
                sx={{ fontWeight: "bold" }}
              >
                Dodaj klub
              </Button>
            )}
            <ActionButton onClick={() => setDrawerOpened(true)} label="Filter">
              <TbFilter size={24} />
            </ActionButton>
            {/* <ActionButton
              onClick={() =>
                setFilter({
                  searchText: "",
                  sportId: "",
                })
              }
            >
              <MdOutlineCancel size={24} />
            </ActionButton> */}
          </Group>
        </div>
        {Object.keys(removeFalsies(params)).length ? (
          <div className="flex gap-4 px-4">
            {Object.keys(removeFalsies(params)).map((key) => (
              <Badge
                size="lg"
                sx={{
                  background: "#FCE6E4",
                  color: "#617A9E",
                }}
                key={key}
                rightSection={
                  <ActionIcon
                    size="xs"
                    radius="xl"
                    variant="transparent"
                    onClick={() => {
                      setParams(removeFalsies({ ...params, [key]: "" }));
                    }}
                  >
                    <MdClose size={16} />
                  </ActionIcon>
                }
              >
                {filterLabels[key]}
              </Badge>
            ))}
            <button
              className="ml-auto cursor-pointer border-0 bg-inherit p-1 text-blue-600 hover:underline"
              onClick={() => setParams({})}
              type="button"
            >
              Ukloni filtere
            </button>
          </div>
        ) : null}

        <DataTable
          isLoading={isLoading}
          data={organizations}
          columns={columns}
          onRowClick={(row) => navigate(`/pregled/klubovi/${row.id}/detalji`)}
          restoreScrollKey="organizationsTable"
          renderItem={(item) => <OrganizationCard item={item} />}
          renderItemHeight={50}
        />
      </ContentContainer>

      <AddNewFAB />

      <Drawer
        withCloseButton={false}
        overlayColor={theme.other.overlayColor}
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={
          <Text
            sx={{
              color: theme.colors.blue[4],
            }}
            weight={500}
            size={24}
          >
            Filter
          </Text>
        }
        position="right"
        closeOnEscape
        closeOnClickOutside
        styles={(theme) => ({
          root: {},
          title: {},
          drawer: {
            borderTopLeftRadius: theme.radius.lg,
            borderBottomLeftRadius: theme.radius.lg,
            backdropFilter: "blur(8px)",
            background: theme.other.sidebarFilterBackground,
          },
          header: {
            padding: theme.spacing.xl,
            height: isLg ? "80px" : "62px",
          },
        })}
      >
        <Stack {...swipeHandlers} spacing="xl" p="md" sx={{ height: "100%" }}>
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži po nazivu"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams(
                removeFalsies({ ...params, searchText: e.target.value })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[4],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectSport
            hideLabel
            value={params.sportId}
            onChange={(e) =>
              setParams(
                removeFalsies({
                  ...params,
                  sportId: e,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[4],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectStatus
            hideLabel
            value={params.status || null}
            onChange={(e) =>
              setParams(
                removeFalsies({
                  ...params,
                  status: e,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
        </Stack>
      </Drawer>
    </>
  );
};

const OrganizationCard = ({ item }) => {
  return (
    <div className="flex cursor-pointer flex-row content-between rounded-lg bg-white p-2 hover:shadow-main-content">
      <span className="flex-1 font-bold text-blue-600">{item?.name}</span>
      <span className="text-blue-500">{item?.Sport?.name}</span>
    </div>
  );
};
