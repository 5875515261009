import React from "react";
import { DocumentsTableView } from "./DocumentsTableView";
import { useParams } from "react-router-dom";
import { trpc } from "utils/trpc";
type TProps = {
  folderId: string;
};

export const FolderDocumentsView = (props: TProps) => {
  const { folderId } = props;
  const { orgId } = useParams();

  //TODO: fetch documents from the server using document_findInFolder graphql query
  const { data, isLoading } = trpc.documents_findMany.useQuery({
    folderId,
    orgId: orgId as string,
  });

  const handleDocumentOpen = () => console.log;
  const handleDocumentDelete = () => console.log;

  return (
    <div className="flex w-full flex-1 flex-col  items-center gap-2 overflow-hidden">
      <DocumentsTableView
        data={data || []}
        onDocumentDelete={handleDocumentDelete}
        onDocumentOpen={handleDocumentOpen}
        loading={isLoading}
      />
    </div>
  );
};
