import {
  useMantineTheme,
  LoadingOverlay,
  Text,
  Group,
  Stack,
} from "@mantine/core";
import axios from "axios";
import React from "react";
import { IoMdPerson } from "react-icons/io";
import { getUrlFromRecordFileId } from "sdk/common/helpers";
import { trpc } from "utils/trpc";

export const ProfilePicture = ({
  recordId,
  disableEdit = false,
  avatarRecordFileId,
  onChange,
}: {
  recordId: string;
  avatarRecordFileId?: string | null;
  disableEdit?: boolean;
  onChange?: (newRecordFileId: string) => void;
}) => {
  const theme = useMantineTheme();
  const [isUploading, setIsUploading] = React.useState(false);

  const onFileUpload = React.useCallback(async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("recordId", recordId);
    data.append("fileKindId", "AVATAR");

    setIsUploading(true);
    const result = await axios.post(
      import.meta.env.VITE_SERVER_URI + "/api/recordFileUpload",
      data,
      {
        withCredentials: true,
      }
    );

    onChange?.(result.data as string);

    setIsUploading(false);
  }, []);

  const inputRef = React.useRef(null);

  return (
    <Stack
      align="center"
      spacing="xs"
      sx={{
        position: "relative",
        borderRadius: theme.radius.lg,
        width: "140px",
        height: "200px",
        cursor: disableEdit ? "initial" : "pointer",

        ".avatar-edit": {
          color: theme.colors.blue[5],
          transition: "all 0.2s",
          opacity: 0.5,
        },
        ".avatar-placeholder": { opacity: 0.4, transition: "all 0.2s" },
        ":hover .avatar-edit": {
          opacity: 1,
        },
        ":hover .avatar-placeholder": {
          opacity: 1,
        },
      }}
      onClick={() => {
        if (!disableEdit) {
          (inputRef.current as any).click();
        }
      }}
    >
      <LoadingOverlay visible={isUploading} overlayBlur={2} />

      {avatarRecordFileId ? (
        <img
          width={140}
          height={200}
          style={{
            borderRadius: "10px",
            width: "140px",
            height: "200px",
            objectFit: "contain",
            objectPosition: "top",
          }}
          src={getUrlFromRecordFileId(avatarRecordFileId)}
        />
      ) : (
        <IoMdPerson
          size={120}
          color={theme.colors.blue[4]}
          className="avatar-placeholder"
        />
      )}
      {!disableEdit && (
        <>
          <Group noWrap className="avatar-edit" spacing="xs">
            {/* <MdEdit size={24} /> */}
            <Text sx={{ whiteSpace: "nowrap" }}>Promijeni sliku</Text>
          </Group>

          <input
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={(event) => {
              const [file] = event?.target?.files as any;
              if (file) {
                onFileUpload(file);
              }
              //
            }}
          />
        </>
      )}
    </Stack>
  );
};
