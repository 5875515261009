import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { PrintRoutes } from "./PrintRoutes";

export const AppRouter = () => (
  <Routes>
    <Route path="/print/*" element={<PrintRoutes />} />
    <Route path="/*" element={<AppRoutes />} />
  </Routes>
);
