import "dayjs/locale/hr";
import { useForm, zodResolver } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";

import { useNavigate } from "react-router-dom";
import { SelectAgeGroup } from "sdk/components/Selects/SelectAgeGroup";
import { SelectCompetitionType } from "sdk/components/Selects/SelectCompetitionType";
import { CompetitionUpsertSchema, competitionUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { notifications } from "sdk/common/helpers";
import { DatePicker } from "@mantine/dates";

export const CompetitionNewForm = ({
  initialValues,
  onClose,
}: {
  initialValues: CompetitionUpsertSchema;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const formHandler = useForm({
    initialValues,
    validate: zodResolver(competitionUpsertSchema),
  });

  const competitionUpsert = trpc.competition_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.competition_find.invalidate({ id: data.id });
      navigate(`/pregled/klubovi/${data.orgId}/natjecanja/detalji/${data.id}`);
    },
  });

  const utils = trpc.useContext();

  return (
    <form
      onSubmit={formHandler.onSubmit(
        (values) => competitionUpsert.mutate(values),
        (err) => console.log({ err })
      )}
      className="flex flex-col gap-4"
    >
      <TextInput
        autoFocus
        label="Naziv natjecanja"
        withAsterisk
        {...formHandler.getInputProps("name")}
        className="w-auto"
      />
      <SelectCompetitionType
        withAsterisk
        {...formHandler.getInputProps("typeId")}
        className="w-auto"
      />
      <SelectAgeGroup
        withAsterisk
        {...formHandler.getInputProps("ageGroupId")}
        className="w-auto"
      />

      <div className="flex gap-4">
        <DatePicker
          withAsterisk
          initialLevel="date"
          label="Početak"
          {...formHandler.getInputProps("startDate")}
          maxDate={formHandler.values.endDate || undefined}
        />
        <DatePicker
          initialLevel="date"
          label="Kraj"
          {...formHandler.getInputProps("endDate")}
          minDate={formHandler.values.startDate || undefined}
        />
      </div>

      <div className="mt-16 flex items-center justify-between">
        <Button
          sx={{ minWidth: 190 }}
          variant="outline"
          onClick={onClose}
          disabled={competitionUpsert.isLoading}
        >
          Odustani
        </Button>
        <Button
          sx={{ minWidth: 190 }}
          type="submit"
          loading={competitionUpsert.isLoading}
          disabled={competitionUpsert.isLoading}
        >
          Spremi
        </Button>
      </div>
    </form>
  );
};
