import { OrganizationForm } from "./components/OrganizationForm";
import { useGetSelectedOrg, useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";

export const UrediKlub = () => {
  useSetTitle("Izmjena podataka kluba", { showBackButton: true });
  // assert(orgId, "missing orgId param");

  const org = useGetSelectedOrg();

  // assert(org);

  if (!org) return null;

  return (
    <ContentContainer className="h-full">
      <OrganizationForm initialValues={org} isEdit={true} />;
    </ContentContainer>
  );
};
