import { z } from "zod";
import numbro from "numbro";
import { parseISO } from "date-fns";
import { format, formatInTimeZone, zonedTimeToUtc, } from "date-fns-tz";
export const isValidOIB = (input) => {
    const oib = input.toString();
    if (oib.match(/\d{11}/) === null) {
        return false;
    }
    let calculated = 10;
    for (const digit of oib.substring(0, 10)) {
        calculated += parseInt(digit);
        calculated %= 10;
        if (calculated === 0) {
            calculated = 10;
        }
        calculated *= 2;
        calculated %= 11;
    }
    let check = 11 - calculated;
    if (check === 10) {
        check = 0;
    }
    return check === parseInt(oib[10]);
};
export const idSchema = z
    .string({
    invalid_type_error: "Obvezan podatak",
    required_error: "Obvezan podatak",
})
    .min(1, { message: "Obvezan podatak" });
export const formatter = {
    number: {
        toAmount: (value) => {
            return numbro(value).format({
                thousandSeparated: true,
                mantissa: 2, // number of decimals displayed
            });
        },
    },
    dateTime: {
        toIsoDateString: (date) => {
            if (!date)
                return "";
            return format(new Date(date), "yyyy-MM-dd");
        },
        toShortDateString: (date) => {
            if (!date)
                return "";
            let parsedDate = date instanceof Date ? date : parseISO(date);
            return format(parsedDate, "dd.MM.yyyy");
        },
        toIsoDateTimeString: (date) => {
            let parsedDate = date instanceof Date ? date : parseISO(date);
            return parsedDate.toISOString();
            return formatInTimeZone(zonedTimeToUtc(parsedDate, "Europe/Zagreb"), "UTC", "yyyy-MM-dd'T'kk:mm:ss'Z'");
        },
    },
};
