import { Select, SelectProps } from "@mantine/core";
import React from "react";

export const memberRoles = [
  "Običan član",
  "Član upravnog odbora",
  "Član izvršnog odbora",
].map((item) => ({ value: item, label: item }));

export const SelectMemberRole = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    // if (loading) return <Loader />;
    // if ((!loading && !data) || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        placeholder={!hideLabel ? undefined : "Uloga člana"}
        label={hideLabel ? undefined : "Uloga člana"}
        {...rest}
        data={memberRoles}
        clearable={false}
      />
    );
  }
);

SelectMemberRole.displayName = "SelectMemberRole";
