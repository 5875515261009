import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.css";
import { ServiceWorkerProvider } from "./useServiceWorker";
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  </React.StrictMode>
);
