import {
  useMantineTheme,
  Stack,
  TextInput,
  Group,
  Button,
  Tabs,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formSubmitErrorHandler } from "sdk/common/helpers";
import { useGetSelectedOrg, useSetTitle } from "sdk/common/hooks";
import { RadioGender } from "sdk/components/Radio/RadioGender";
import { SelectMembershipType } from "sdk/components/Selects/SelectMembershipType";
import { ulid } from "ulid";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { SelectAgeGroup } from "sdk/components/Selects/SelectAgeGroup";
import React from "react";
import { SelectMemberCategory } from "sdk/components/Selects/SelectMemberCategory";
import { CardContainer } from "./components";
import { SelectMemberRole } from "sdk/components/Selects/SelectMemberRole";
import { trpc } from "utils/trpc";
import { memberCreateSchema } from "models";
import { ContentContainer } from "sdk/components/ContentContainer";

export const NoviClan = () => {
  useSetTitle("Novi član");
  const id = ulid();

  const { orgId } = useParams();

  const selectedOrg = useGetSelectedOrg();

  const initialValues = React.useMemo(
    () => ({
      id,
      orgId,
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: new Date(2010, 0, 1),
      countryId: "HR",
      parentNames: "",
      email: "",
      phoneNumber: "",
      membershipStartDate: new Date(),
      membershipEndDate: undefined,
      description: "",
      status: "",
      membershipTypeId: "",
      // isCategorized: false,
      // isNationalSelectionMember: false,
      nationalSelectionName: "",
      // isMedicallyExamined: false,
      address: "",
      city: "",
      zipCode: "",
      oib: "",
      houseNo: "",
      memberRole: "Običan član",
      sportId: selectedOrg?.sportId as string,

      MemberCategories: [
        {
          id: ulid(),
          orgId,
          categoryNo: "",
          dateFrom: new Date(),
          dateTo: undefined,
          description: "",
        },
      ],
      MemberAgeGroups: [
        {
          id: ulid(),
          orgId,
          ageGroupId: "",
          dateFrom: new Date(),
        },
      ],
      MemberPhysiqueRecords: [
        {
          id: ulid(),
          orgId,
          height: "",
          weight: "",
          date: new Date(),
        },
      ],
      MemberMedicalRecords: [
        {
          id: ulid(),
          orgId,
          description: "",
          date: null,
        },
      ],
    }),
    [selectedOrg]
  );

  if (!initialValues || !selectedOrg) return null;

  return <MemberCreateForm initialValues={initialValues} />;
};

const MemberCreateForm = ({ initialValues }) => {
  const theme = useMantineTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // const setNewMember = useAppState((state) => state.setNewMember);
  const utils = trpc.useContext();

  const memberCreate = trpc.member_create.useMutation({
    onSuccess: () => {
      // setNewMember(data as any);
      utils.member_findMany.reset();
      navigate(pathname.replace("/novi", ""), {
        replace: true,
      });
    },
  });

  const form = useForm({
    initialValues,
    validate: zodResolver(memberCreateSchema),
    validateInputOnBlur: true,
    transformValues: (values) => ({
      ...values,
      MemberCategories: values.MemberCategories?.filter(
        (item) =>
          item.categoryNo === "Nekategoriziran" ||
          (item.id && item.categoryNo && item.dateFrom)
      ),
      MemberMedicalRecords: values.MemberMedicalRecords?.filter(
        (item) => !!item.date
      ),
      MemberAgeGroups: values.MemberAgeGroups?.filter(
        (item) => item.ageGroupId && item.dateFrom
      ),
    }),
  });

  // console.log({ values: form.values });

  return (
    <ContentContainer className="h-full">
      <div className="mx-auto flex h-full max-w-[1400px] gap-16 overflow-auto p-8">
        <div className="">
          <ProfilePicture
            recordId={form.values.id as string}
            avatarRecordFileId={form.values.avatarRecordFileId}
            onChange={(value) =>
              form.setFieldValue("avatarRecordFileId", value)
            }
          />
        </div>

        <Tabs
          defaultValue="personal"
          orientation="vertical"
          placement="right"
          styles={{
            root: { flex: 1, height: "100%", overflow: "auto" },
            tabsList: {
              border: "none",

              width: "max(25%, 230px)",
              background: "transparent",
              padding: theme.spacing.xl,
            },
            tab: {
              height: "36px",
              opacity: 0.5,

              '&[aria-selected="true"]': {
                opacity: 1,
                ">*": {
                  borderBottomWidth: "4px",
                  borderBottomStyle: "solid",
                  borderColor: theme.colors.blue[5],
                  padding: 2,
                },
              },
              ":hover": {
                background: theme.colors.blue[4],
                borderColor: theme.colors.blue[4],
              },
              ":disabled": {
                opacity: 0.2,
              },
            },
            tabLabel: {
              fontWeight: "bold",
            },
            panel: {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "stretch",
              flexDirection: "column",
            },
          }}
        >
          <Tabs.List>
            <div className="flex h-full flex-col justify-between ">
              <Tabs.Tab value="personal">OSNOVNI PODACI</Tabs.Tab>

              <div className="flex flex-col gap-4 p-2">
                <Button
                  tabIndex={-1}
                  sx={{ minWidth: 190 }}
                  variant="outline"
                  onClick={() => navigate(-1)}
                  disabled={memberCreate.isLoading}
                >
                  Odustani
                </Button>
                <Button
                  // leftIcon={<TiPlus size={20} />}
                  sx={{ minWidth: 190 }}
                  loading={memberCreate.isLoading}
                  onClick={() =>
                    form.onSubmit(
                      (values) =>
                        memberCreate.mutate({
                          ...values,
                        }),
                      formSubmitErrorHandler()
                    )()
                  }
                >
                  Spremi
                </Button>
              </div>
            </div>
          </Tabs.List>
          <Tabs.Panel value="personal">
            {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}
            <div className="flex h-full flex-col gap-4 overflow-auto px-4">
              <CardContainer>
                <TextInput
                  label="Ime"
                  {...form.getInputProps("firstName")}
                  sx={{ width: "auto" }}
                  withAsterisk
                  autoFocus
                />
                <TextInput
                  label="Prezime"
                  {...form.getInputProps("lastName")}
                  sx={{ width: "auto" }}
                  withAsterisk
                />
                <TextInput
                  label="OIB"
                  {...form.getInputProps("oib")}
                  sx={{ width: "auto" }}
                  onChange={(e) =>
                    /^[0-9]{0,11}$/.test(e.target.value) &&
                    form.setFieldValue("oib", e.target.value)
                  }
                  withAsterisk
                />
                <DatePicker
                  withAsterisk
                  label="Datum rođenja"
                  {...form.getInputProps("dateOfBirth")}
                  sx={{ width: "auto" }}
                />
                <RadioGender
                  withAsterisk
                  {...form.getInputProps("gender")}
                  sx={{ width: "auto" }}
                />
              </CardContainer>

              <CardContainer>
                <Group align="start" noWrap>
                  <DatePicker
                    withAsterisk
                    sx={{ width: "auto" }}
                    label="Početak članstva"
                    maxDate={form.values.membershipEndDate}
                    {...form.getInputProps("membershipStartDate")}
                  />
                  <DatePicker
                    sx={{ width: "auto" }}
                    label="Kraj članstva"
                    disabled={!form.values.membershipStartDate}
                    minDate={form.values.membershipStartDate}
                    {...form.getInputProps("membershipEndDate")}
                  />
                </Group>

                <Group noWrap align="flex-start">
                  <SelectMembershipType
                    {...form.getInputProps("membershipTypeId")}
                    sx={{ flex: 2, width: "100%" }}
                    withAsterisk
                  />
                  {form.values.membershipTypeId === "O" && (
                    <SelectMemberRole
                      {...form.getInputProps("memberRole")}
                      sx={{ flex: 1, width: "100%" }}
                    />
                  )}
                </Group>

                {form.values.membershipTypeId === "RS" && (
                  <Stack>
                    <TextInput
                      sx={{ width: "auto" }}
                      label="Naziv nacionalne selekcije"
                      {...form.getInputProps("nationalSelectionName")}
                    />
                    <Group noWrap align="flex-start">
                      <SelectMemberCategory
                        sx={{ flexGrow: 1 }}
                        {...form.getInputProps(`MemberCategories.0.categoryNo`)}
                      />
                      {form.values.MemberCategories[0].categoryNo &&
                        form.values.MemberCategories[0].categoryNo !==
                          "Nekategoriziran" && (
                          <>
                            <DatePicker
                              withAsterisk
                              label="Početak kategorizacije"
                              maxDate={
                                form.values.MemberCategories?.[0]?.dateTo
                              }
                              {...form.getInputProps(
                                `MemberCategories.0.dateFrom`
                              )}
                              disabled={
                                !form.values.MemberCategories?.[0]?.categoryNo
                              }
                            />
                            <DatePicker
                              label="Kraj kategorizacije"
                              disabled={
                                !form.values.MemberCategories?.[0]?.dateFrom
                              }
                              minDate={
                                form.values.MemberCategories?.[0]?.dateFrom
                              }
                              {...form.getInputProps(
                                `MemberCategories.0.dateTo`
                              )}
                            />
                          </>
                        )}
                    </Group>
                  </Stack>
                )}
                {["RS", "NRS"].includes(form.values.membershipTypeId) && (
                  <>
                    <SelectAgeGroup
                      withAsterisk
                      {...form.getInputProps("MemberAgeGroups.0.ageGroupId")}
                      sx={{ width: "auto" }}
                    />

                    <DatePicker
                      label="Datum zadnjeg medicinskog pregleda"
                      {...form.getInputProps(`MemberMedicalRecords.0.date`)}
                      sx={{ width: "auto" }}
                    />

                    <Group noWrap>
                      <TextInput
                        sx={{ flexGrow: 1 }}
                        label="Visina (cm)"
                        {...form.getInputProps(
                          "MemberPhysiqueRecords.0.height"
                        )}
                      />
                      <TextInput
                        sx={{ flexGrow: 1 }}
                        label="Težina (kg)"
                        {...form.getInputProps(
                          "MemberPhysiqueRecords.0.weight"
                        )}
                      />
                    </Group>
                  </>
                )}
              </CardContainer>

              <CardContainer>
                <TextInput
                  label="E-mail"
                  {...form.getInputProps("email")}
                  sx={{ width: "auto" }}
                />
                <TextInput
                  label="Telefonski broj"
                  {...form.getInputProps("phoneNumber")}
                  sx={{ width: "auto" }}
                  onChange={(e) =>
                    /^[0-9 ]*$/.test(e.target.value) &&
                    form.setFieldValue("phoneNumber", e.target.value)
                  }
                />
                <Group noWrap>
                  <TextInput
                    sx={{ flex: 2 }}
                    label="Ulica"
                    {...form.getInputProps("address")}
                  />
                  <TextInput
                    sx={{ flex: 1 }}
                    label="Kućni broj"
                    {...form.getInputProps("houseNo")}
                  />
                </Group>
                <Group noWrap>
                  <TextInput
                    sx={{ flex: 1 }}
                    label="Poštanski broj"
                    {...form.getInputProps("zipCode")}
                  />
                  <TextInput
                    sx={{ flex: 2 }}
                    label="Mjesto"
                    {...form.getInputProps("city")}
                  />
                </Group>

                <TextInput
                  label="Imena roditelja"
                  {...form.getInputProps("parentNames")}
                  sx={{ width: "auto" }}
                />
                {/* <TextInput
                    label="Napomena"
                    {...form.getInputProps("description")}
                  /> */}
              </CardContainer>
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </ContentContainer>
  );
};
