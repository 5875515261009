import { useEffect } from "react";
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useParams,
  useRoutes,
} from "react-router-dom";
import { useAppState } from "state/useAppState";

import { DefaultHeader } from "../layout/Header/DefaultHeader";
import { AdminSidebar } from "../layout/Sidebar/AdminSidebar";
import { Drawer, useMantineTheme } from "@mantine/core";
import { AdminSettingsSidebar } from "../layout/Sidebar/SettingsSidebar";

import { useSwipeable } from "react-swipeable";
import { MobileHeader } from "../layout/HeaderMobile/MobileHeader";
import { NoviKlub } from "../screens/appScreens/postavke/NoviKlub";
import { SviClanovi } from "../screens/appScreens/pregled/Clanovi";
import { ClanoviKluba } from "../screens/appScreens/pregled/klubovi/$orgId/clanovi/Clanovi";
import { NoviClan } from "../screens/appScreens/pregled/klubovi/$orgId/clanovi/NoviClan";
import { DetaljiClana } from "../screens/appScreens/pregled/klubovi/$orgId/clanovi/DetaljiClana";
import { UrediClana } from "../screens/appScreens/pregled/klubovi/$orgId/clanovi/UrediClana";
import { Sjednice } from "../screens/appScreens/pregled/klubovi/$orgId/sjednice/Sjednice";
import { UrediSjednicu } from "../screens/appScreens/pregled/klubovi/$orgId/sjednice/UrediSjednicu";
import { Natjecanja } from "../screens/appScreens/pregled/klubovi/$orgId/natjecanja/Natjecanja";
import { DetaljiNatjecanja } from "../screens/appScreens/pregled/klubovi/$orgId/natjecanja/DetaljiNatjecanja";
import { DetaljiSusreta } from "../screens/appScreens/pregled/klubovi/$orgId/natjecanja/DetaljiSusreta";
import { UrediNatjecanje } from "../screens/appScreens/pregled/klubovi/$orgId/natjecanja/UrediNatjecanje";
import { Treneri } from "../screens/appScreens/pregled/klubovi/$orgId/treneri/Treneri";
import { UrediTrenera } from "../screens/appScreens/pregled/klubovi/$orgId/treneri/UrediTrenera";
import { DetaljiTrenera } from "../screens/appScreens/pregled/klubovi/$orgId/treneri/DetaljiTrenera";
import { NoviTrener } from "../screens/appScreens/pregled/klubovi/$orgId/treneri/NoviTrener";
import { Dokumenti } from "../screens/appScreens/pregled/klubovi/$orgId/dokumenti/Dokumenti";
import { RasporediTreninga } from "../screens/appScreens/pregled/klubovi/$orgId/rasporedi/RasporediTreninga";
import { DashboardKluba } from "../screens/appScreens/pregled/klubovi/$orgId/DashboardKluba";
import { UrediKlub } from "../screens/appScreens/pregled/klubovi/$orgId/UrediKlub";
import { Korisnici } from "../screens/appScreens/pregled/Korisnici";
import { NoviKorisnik } from "../screens/appScreens/postavke/korisnici/NoviKorisnik";
import { DetaljiKorisnika } from "../screens/appScreens/postavke/korisnici/DetaljiKorisnika";
import { UrediKorisnika } from "../screens/appScreens/postavke/korisnici/UrediKorisnika";
import { BaseRouteRedirect } from "../screens/commonScreens/BaseRouteRedirect";
import { ActiveOrgSidebar } from "../layout/Sidebar/ActiveOrgSidebar";
import { ForbiddenScreen } from "../screens/commonScreens/ForbiddenScreen";
import { GenerateDocumentDefaultPage } from "../screens/printScreens";
import { Klubovi } from "../screens/appScreens/pregled/Klubovi";
import { AdminDashboard } from "../screens/appScreens/pregled/AdminDashboard";
import logo from "assets/logo/szgz.png";
import { OrgReportsDefaultPage } from "../screens/appScreens/pregled/klubovi/$orgId/izvjestaji";
import { useMe } from "sdk/common/hooks";
import React from "react";

const SelectedOrgRoutes = () => {
  const { orgId } = useParams();

  const me = useMe();
  const isAdmin = me?.orgId === "szgz";

  if (!isAdmin && me?.orgId !== orgId) return <BaseRouteRedirect />;

  return (
    <Routes>
      <Route path="" element={<Navigate to={"detalji"} />} />
      <Route path="detalji" element={<DashboardKluba />} />
      <Route path="detalji/uredi" element={<UrediKlub />} />
      <Route path="clanovi" element={<ClanoviKluba />} />
      <Route path="clanovi/novi" element={<NoviClan />} />
      <Route path="clanovi/detalji/:id" element={<DetaljiClana />} />
      <Route path="clanovi/uredi/:id" element={<UrediClana />} />
      <Route path="sjednice" element={<Sjednice />} />
      <Route path="sjednice/uredi/:id" element={<UrediSjednicu />} />
      <Route path="natjecanja" element={<Natjecanja />} />
      <Route path="natjecanja/detalji/:id" element={<DetaljiNatjecanja />} />
      <Route
        path="natjecanja/detalji/:id/susret/:competitionMatchId"
        element={<DetaljiSusreta />}
      />
      <Route path="natjecanja/uredi/:id" element={<UrediNatjecanje />} />
      <Route path="treneri" element={<Treneri />} />
      <Route path="treneri/uredi/:id" element={<UrediTrenera />} />
      <Route path="treneri/detalji/:id" element={<DetaljiTrenera />} />
      <Route path="treneri/novi" element={<NoviTrener />} />
      <Route path="dokumenti" element={<Dokumenti />} />
      <Route path="rasporedi" element={<RasporediTreninga />} />
      <Route path="izvjestaji" element={<OrgReportsDefaultPage />} />
    </Routes>
  );
};

export const appRoutes = [
  { path: "/", element: <BaseRouteRedirect /> },

  { path: "/odbijenpristup", element: <ForbiddenScreen /> },
  // {
  //   path: "/profil",
  //   element: <ProfileScreen />,
  // },
  {
    path: "",
    element: <BaseRouteRedirect />,
  },
  {
    path: "/pregled",
    element: <AdminDashboard />,
    isAdminRoute: true,
  },
  {
    path: "/pregled/klubovi",
    element: <Klubovi />,
    isAdminRoute: true,
  },
  {
    path: "/postavke/noviklub",
    element: <NoviKlub />,
    isAdminRoute: true,
  },
  {
    path: "/pregled/klubovi/:orgId/*",
    element: <SelectedOrgRoutes />,
  },
  {
    path: "/pregled/clanovi",
    element: <SviClanovi />,
    isAdminRoute: true,
  },
  {
    path: "/izvjestaji/*",
    element: <Navigate to="/izvjestaji" replace />,
    isAdminRoute: true,
  },
  {
    path: "/izvjestaji",
    element: <GenerateDocumentDefaultPage />,
    isAdminRoute: true,
  },
  {
    path: "/postavke",
    element: <Navigate to="/postavke/korisnici" />,
    isAdminRoute: true,
  },
  {
    path: "/pregled/korisnici",
    element: <Korisnici />,
    isAdminRoute: true,
  },
  {
    path: "/postavke/korisnici/novi",
    element: <NoviKorisnik />,
    isAdminRoute: true,
  },
  {
    path: "/postavke/korisnici/detalji/:id",
    element: <DetaljiKorisnika />,
    isAdminRoute: true,
  },
  {
    path: "/postavke/korisnici/uredi/:id",
    element: <UrediKorisnika />,
  },
];

export const AppRoutes = () => {
  const [isSidebarOpen, setSidebarOpen] = useAppState((state) => [
    state.isSidebarOpen,
    state.setSidebarOpen,
  ]);

  const theme = useMantineTheme();

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setSidebarOpen(false),
  });

  const userMe = useMe();
  const isAdmin = userMe?.orgId === "szgz";

  const routes = React.useMemo(
    () =>
      appRoutes.map((route) =>
        !route.isAdminRoute || isAdmin
          ? route
          : { path: route.path, element: <BaseRouteRedirect /> }
      ),
    [userMe?.orgId]
  );

  return (
    <div className="flex h-full w-full flex-row  overflow-hidden bg-gradient-color bg-main-gradient">
      {/**
       * ROUTER za sidebar; samo jedan ili nijedan moze biti aktivan...
       */}
      <Routes>
        <Route path="/" element={null} />
        <Route path="/pregled" element={null} />
        <Route path="/pregled/klubovi/:orgId/detalji" element={null} />
        <Route
          path="/pregled/klubovi/:orgId/*"
          element={<ActiveOrgSidebar />}
        />
        <Route path="/*" element={<AdminSidebar />} />
      </Routes>

      <div className="flex h-full flex-1 flex-col overflow-x-hidden">
        <div className="hidden xl:block">
          <DefaultHeader />
        </div>
        <div className="xl:hidden">
          <MobileHeader />
        </div>
        <div className="flex-1 overflow-hidden xl:m-4 xl:mb-6 xl:mr-6">
          {useRoutes(routes)}
        </div>
      </div>

      <NavLink to="/" className="absolute top-2 left-2">
        <img className="self-center" width={50} src={logo} />
      </NavLink>

      <div className="absolute bottom-2 left-2 text-sm text-blue-700">
        {import.meta.env.VITE_VERSION}
      </div>

      <Drawer
        overlayColor={theme.other.overlayColor}
        opened={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        // size="sm"
        closeOnEscape
        closeOnClickOutside
        withCloseButton={false}
        styles={(theme) => ({
          drawer: {
            // width: "auto",
            // width: "330px",
            borderTopRightRadius: theme.radius.xl,
            borderBottomRightRadius: theme.radius.xl,
            overflow: "hidden",
          },
        })}
      >
        <div {...swipeHandlers}>
          <Routes>
            <Routes>
              <Route path="/pregled/klubovi/:orgId/detalji" element={null} />
              <Route
                path="/pregled/klubovi/:orgId/*"
                element={<ActiveOrgSidebar />}
              />
            </Routes>
            <Route path="/postavke/*" element={<AdminSettingsSidebar />} />
          </Routes>
        </div>
      </Drawer>
    </div>
  );
};
