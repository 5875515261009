import { Group, Stack, Text } from "@mantine/core";
import dayjs from "dayjs";
import szgzLogo from "../../assets/logo/szgz_text.png";

export const PrintHeader = () => {
  return (
    <Group
      sx={{
        justifyContent: "space-between",
      }}
    >
      <img src={szgzLogo} style={{ height: 60 }} />
      <Stack spacing={0} sx={{ alignItems: "flex-end" }}>
        <Text>{dayjs().locale("hr").format("D. MMMM, YYYY")}</Text>
        <Text>{dayjs().locale("hr").format("hh:mm")}</Text>
      </Stack>
    </Group>
  );
};
