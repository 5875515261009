import { Button, Modal, useMantineTheme } from "@mantine/core";
import React from "react";
import { TiPlus } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "sdk/common/helpers";
import { useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { trpc } from "utils/trpc";
import { CompetitionNewForm } from "./components/CompetitionNewForm";
import { ulid } from "ulid";
import { assert } from "sdk/assert";

export const Natjecanja = (props) => {
  const { orgId } = useParams();
  assert(orgId);

  const navigate = useNavigate();
  useSetTitle("Natjecanja");

  const theme = useMantineTheme();

  const [showNew, setShowNew] = React.useState(false);

  const {
    data: competitions,
    isLoading,
    isError,
  } = trpc.competition_findMany.useQuery({ orgId });

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "Naziv",
        accessor: "name",
        width: 200,
      },
      {
        Header: "Vrsta",
        accessor: "CompetitionType.name",
        width: 250,
      },
      {
        Header: "Dobna skupina",
        accessor: "AgeGroup.name",
        width: 200,
      },
      {
        Header: "Početak",
        accessor: "startDate",
        width: 200,
        Cell: ({ value }) => <span>{formatDate.toDateString(value)}</span>,
      },
      {
        Header: "Kraj",
        accessor: "endDate",
        width: 200,
        Cell: ({ value }) => <span>{formatDate.toDateString(value)}</span>,
      },
    ],
    []
  );

  if (isError) return <div>Greška</div>;

  return (
    <>
      <ContentContainer className="flex h-full flex-col p-2">
        <div className="flex flex-col items-end p-4">
          <Button
            onClick={() => setShowNew(true)}
            leftIcon={<TiPlus size={20} />}
            sx={{ fontWeight: "bold", alignSelf: "flex-end" }}
          >
            Dodaj novo
          </Button>
        </div>
        <DataTable
          isLoading={isLoading}
          data={competitions}
          columns={columns}
          onRowClick={(row) => navigate(`detalji/${row.id}`)}
        />
      </ContentContainer>

      <Modal
        opened={showNew}
        onClose={() => setShowNew(false)}
        title="Novo natjecanje"
        styles={{ modal: { width: 540 } }}
      >
        <CompetitionNewForm
          initialValues={{
            id: ulid(),
            orgId,
            startDate: new Date(),
            ageGroupId: "",
            name: "",
            typeId: "",
          }}
          onClose={() => setShowNew(false)}
        />
      </Modal>
    </>
  );
};
