import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { trpc } from "utils/trpc";

export const SelectCompetitionType = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    const { data, isLoading } = trpc.competitionType_findMany.useQuery();

    const competitionTypes =
      data?.map((item) => ({
        value: item.id,
        label: item.name!,
      })) || [];

    // if (loading) return <Loader />;
    // if ((!loading && !data) || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Vrsta natjecanja"}
        label={hideLabel ? undefined : "Vrsta natjecanja"}
        data={competitionTypes}
        {...rest}
      />
    );
  }
);

SelectCompetitionType.displayName = "SelectCompetitionType";
