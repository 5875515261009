import { useMantineTheme } from "@mantine/core";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import React, { useEffect } from "react";
import {
  URLSearchParamsInit,
  useParams,
  useSearchParams as useRRSearchParams,
} from "react-router-dom";
import { TDialogProps, useAppState } from "../../state/useAppState";
import { trpc } from "utils/trpc";
export const useSetTitle = (
  title: string | null,
  options?: { showBackButton?: boolean }
) => {
  const [setTitle, setHeaderShowBackButton] = useAppState((state) => [
    state.setTitle,
    state.setHeaderShowBackButton,
  ]);
  React.useEffect(() => {
    setTitle(title);
    setHeaderShowBackButton(options?.showBackButton ?? false);
  }, [title]);
};

export const useBreakpoints = () => {
  const theme = useMantineTheme();

  return {
    isXs: useMediaQuery(`(min-width: ${theme.breakpoints.xs}px)`, true, {
      getInitialValueInEffect: false,
    }),
    isSm: useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`, true, {
      getInitialValueInEffect: false,
    }),
    isMd: useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true, {
      getInitialValueInEffect: false,
    }),
    isLg: useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`, true, {
      getInitialValueInEffect: false,
    }),
    isXl: useMediaQuery(`(min-width: ${theme.breakpoints.xl}px)`, true, {
      getInitialValueInEffect: false,
    }),
  };
};

export const useDebouncedRefetch = (searchText, refetch) => {
  const [debounced] = useDebouncedValue(searchText, 500);

  React.useEffect(() => {
    refetch({ input: { pageNumber: 1, searchText } });
  }, [debounced]);
};

export const useSearchParams = (
  defaultProps?: any
): [
  any,
  (nextInit: URLSearchParamsInit, navigateOptions?: any | undefined) => void
] => {
  const [search, setSearch] = useRRSearchParams(defaultProps);
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  return [searchAsObject, setSearch];
};

export const useGetSelectedOrg = () => {
  const { orgId } = useParams();

  const { data } = trpc.organization_find.useQuery(
    { id: orgId! },
    { enabled: !!orgId }
  );
  return data;
};

export const useOnClickOutside = (ref, callback) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export const useMe = () => {
  const { data } = trpc.user_me.useQuery();
  return data;
};

export const openDeleteDialog = (dialogOptions: Omit<TDialogProps, "show">) => {
  const setDialog = useAppState.getState().setDialog;
  setDialog({ ...dialogOptions, show: true });
};
