import { Button, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { notifications } from "sdk/common/helpers";
import "dayjs/locale/hr";

import { SelectOrganization } from "sdk/components/Selects/SelectOrganization";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { FormSectionContainer } from "sdk/components/FormSectionContainer";
import { useRef, useState } from "react";
import { Input } from "sdk/components/Input";
import React from "react";
import { userUpsertSchema } from "models";
import { DatePicker } from "@mantine/dates";
import { trpc } from "utils/trpc";
import { useMe } from "sdk/common/hooks";

export const UserForm = ({
  initialValues,
  onSubmit,
  isSubmitting,
  isEdit,
}: {
  initialValues: any;
  onSubmit: any;
  isSubmitting: boolean;
  isEdit?: boolean;
}) => {
  const navigate = useNavigate();
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const form = useForm({
    initialValues,
    validate: zodResolver(userUpsertSchema),
    validateInputOnBlur: true,
  });

  const handleIsPasswordChangeOpen = (isOpen: boolean) => {
    setIsPasswordChangeOpen(isOpen);
    if (isOpen) {
      setTimeout(() => {
        6;
        containerRef.current &&
          (containerRef.current.scrollTop = containerRef.current.scrollHeight);
      });
    }
  };

  const user = useMe();

  const isOrgChangeEnabled = user?.orgId === "szgz" || !isEdit;

  return (
    <div className="mx-auto flex max-w-[1400px] flex-row gap-8 p-2">
      <div className="p-12">
        <ProfilePicture
          recordId={form.values.id}
          avatarRecordFileId={form.values.avatarRecordFileId || ""}
          onChange={(value) => form.setFieldValue("avatarRecordFileId", value)}
        />
      </div>
      <div ref={containerRef} className="flex flex-1 flex-col gap-6 p-4">
        <form className="flex flex-col gap-6">
          <FormSectionContainer>
            {/* <pre>{JSON.stringify(form.errors, null, 2)}</pre> */}

            {/* <DatePicker
              {...form.getInputProps("dateOfBirth")}
              locale="hr"
              label="Datum rođenja"
              withAsterisk
              className="w-auto"
            /> */}
            <TextInput
              autoComplete="new-password"
              label="E-mail (Korisničko ime)"
              withAsterisk
              {...form.getInputProps("email")}
              className="w-auto"
              disabled={isEdit}
              autoFocus
            />
            <SelectOrganization
              disabled={!isOrgChangeEnabled}
              className="w-auto"
              {...form.getInputProps("orgId")}
              searchable
              placeholder={
                form.values.orgId === "szgz" || !form.values.orgId
                  ? "SZGZ ADMINISTRACIJA"
                  : ""
              }
            />
            <TextInput
              autoComplete="new-password"
              label="Ime"
              className="w-auto"
              // withAsterisk
              {...form.getInputProps("firstName")}
            />

            <TextInput
              autoComplete="new-password"
              label="Prezime"
              className="w-auto"
              // withAsterisk
              {...form.getInputProps("lastName")}
            />

            {/* <TextInput
              autoComplete="new-password"
              label="Telefonski broj"
              {...form.getInputProps("phoneNumber")}
              className="w-auto"
              onChange={(e) =>
                // /^[0-9 ]*$/.test(e.target.value) &&
                form.setFieldValue("phoneNumber", e.target.value)
              }
            /> */}
          </FormSectionContainer>
          {!isEdit && (
            <FormSectionContainer>
              <Input
                type="password"
                label="Inicijalna Lozinka"
                {...form.getInputProps("password")}
                className="w-auto"
              />
            </FormSectionContainer>
          )}
        </form>
        {isEdit && (
          <FormSectionContainer>
            <ChangePasswordForm
              userId={form.values.id}
              onIsOpen={handleIsPasswordChangeOpen}
            />
          </FormSectionContainer>
        )}
      </div>
      <div className="flex w-[200px] flex-col items-center gap-3 py-4">
        <div className="mt-4 self-start border-0 border-b-4 border-solid border-blue-600 text-sm font-bold">
          KORISNIČKI PROFIL
        </div>
        <div className="flex-1" />
        {!isPasswordChangeOpen ? (
          <>
            <Button
              sx={{ minWidth: 190 }}
              variant="outline"
              onClick={() => navigate(-1)}
            >
              Odustani
            </Button>
            <Button
              sx={{ minWidth: 190 }}
              disabled={isSubmitting}
              type="button"
              onClick={() =>
                form.onSubmit((values) =>
                  onSubmit({
                    ...values,
                    orgId: isOrgChangeEnabled
                      ? values.orgId
                      : initialValues.orgId,
                  })
                )()
              }
              loading={isSubmitting}
            >
              Spremi
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};
const ChangePasswordForm = (props: {
  userId: string;
  onIsOpen: (isOpen: boolean) => void;
}) => {
  const { userId, onIsOpen } = props;
  const [formState, setFormState] = useState<"initial" | "update">("initial");

  const [state, setState] = React.useState({ password: "", confirm: "" });

  const isFormValid =
    !!state.password.length && state.password === state.confirm;

  const changePassword = trpc.user_changePassword.useMutation({
    onSuccess: () => {
      notifications.success("Lozinka korisnika je uspješno promijenjena");
      setState({ confirm: "", password: "" });
      setFormState("initial");
      onIsOpen(false);
    },
  });

  if (formState === "initial") {
    return (
      <Button
        className="self-start"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onIsOpen(true);
          setFormState("update");
        }}
      >
        Promjena lozinke
      </Button>
    );
  }

  return (
    <>
      <Input
        name="newPassword"
        type={"password"}
        value={state.password}
        onChange={(e) =>
          setState((prev) => ({ ...prev, password: e.target?.value }))
        }
        label="Nova lozinka"
        autoFocus
        error={!state.password?.length ? "Upišite lozinku" : ""}
      />
      <Input
        name="confirmNewPassword"
        label="Potvrdi novu lozinku"
        type={"password"}
        value={state.confirm}
        onChange={(e) =>
          setState((prev) => ({ ...prev, confirm: e.target.value }))
        }
        error={
          state.confirm?.length && state.password !== state.confirm
            ? "Nova lozinka i potvrda nove lozinke se ne podudaraju. Molimo provjerite i pokušajte ponovno."
            : ""
        }
      />
      <div className="mt-4 flex flex-row justify-end gap-4">
        <Button
          sx={{ minWidth: 190 }}
          variant="outline"
          onClick={() => {
            setState({ confirm: "", password: "" });
            onIsOpen(false);
            setFormState("initial");
          }}
        >
          Odustani
        </Button>
        <Button
          className="self-end"
          loading={changePassword.isLoading}
          disabled={changePassword.isLoading || !isFormValid}
          onClick={() =>
            changePassword.mutate({
              userId: userId,
              newPassword: state.password,
            })
          }
        >
          Potvrdi promjenu lozinke
        </Button>
      </div>
    </>
  );
};
