import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Button,
  Drawer,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { MdClose } from "react-icons/md";
import { TbFilter } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { removeFalsies } from "sdk/common/helpers";
import { useBreakpoints, useSearchParams, useSetTitle } from "sdk/common/hooks";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { useSwipeable } from "react-swipeable";
import { AddNewFAB } from "sdk/components/AddNewFAB";
import { TiPlus } from "react-icons/ti";
import { HiChevronDown, HiChevronUp, HiPencil } from "react-icons/hi";
import { SelectEmploymentType } from "sdk/components/Selects/SelectEmploymentType";
import { ActionButton } from "sdk/components/ActionIcon";
import { IoEnterOutline } from "react-icons/io5";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";

export const Treneri = (props) => {
  const navigate = useNavigate();
  useSetTitle("Treneri");
  const theme = useMantineTheme();
  const { orgId } = useParams();
  const [params, setParams] = useSearchParams();

  const { isLg } = useBreakpoints();

  const [drawerOpened, setDrawerOpened] = React.useState(false);

  const [sortInput, setSortInput] = React.useState<{
    orderBy: string;
    asc: boolean;
  }>({ orderBy: "id", asc: true });

  const [debouncedSearchText] = useDebouncedValue(params.searchText, 300);

  const coachFindMany = trpc.coach_findMany.useQuery({
    orgId: orgId!,
    searchText: debouncedSearchText,
    coachEmploymentTypeId: params.coachEmploymentTypeId,
  });
  const coaches = coachFindMany?.data;

  const getSortHeader = React.useCallback(
    (columnId, columnLabel) => (
      <Group
        spacing="xs"
        sx={{ userSelect: "none", cursor: "pointer" }}
        onClick={() => {
          setSortInput((prev) => ({
            orderBy: prev.orderBy === columnId && !prev.asc ? "id" : columnId,
            asc: prev.orderBy === columnId && prev.asc ? false : true,
          }));
        }}
      >
        <Text>{columnLabel.toLowerCase()}</Text>
        {sortInput.orderBy === columnId &&
          (sortInput.asc ? <HiChevronUp /> : <HiChevronDown />)}
      </Group>
    ),
    //
    [sortInput]
  );

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "",
        id: "eye",
        accessor: "",
        className: "pt-1 text-blue-400 hover:text-blue-700",
        Cell: () => <IoEnterOutline size={20} />,
        width: 36,
      },
      {
        Header: "",
        id: "edit",
        accessor: "",
        className: "pt-1 text-blue-400 hover:text-blue-700",
        Cell: (data) => {
          return (
            <HiPencil
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`uredi/${data.cell.row.original.id}`);
              }}
            />
          );
        },
        width: 36,
      },
      {
        id: "firstName",
        Header: getSortHeader("firstName", "ime"),
        accessor: "firstName",
        width: 120,
        className: "text-blue-700 capitalize font-bold",
      },
      {
        id: "lastName",
        Header: getSortHeader("lastName", "prezime"),
        accessor: "lastName",
        width: 150,
        className: "text-blue-700 capitalize font-bold",
      },

      {
        id: "age",
        Header: getSortHeader("dateOfBirth", "Dob"),
        accessor: "dateOfBirth",
        width: 60,
        headerClassName: "text-right",
        className: "text-center text-blue-500",
        Cell: (props) => (
          <div>
            {props.value ? dayjs().diff(dayjs(props.value), "y") : "-/-"}
          </div>
        ),
      },

      {
        Header: getSortHeader("gender", "Spol"),
        accessor: "gender",
        width: 120,
        className: "text-blue-500",
        Cell: ({ value }) => value?.toString()?.charAt(0) || "-/-",
      },
      {
        id: "phoneNumber",
        Header: getSortHeader("phoneNumber", "Telefonski broj"),
        accessor: "phoneNumber",
        width: 200,
        // className: "text-blue-700 capitalize font-bold",
      },
      {
        Header: "Status",
        id: "status",
        width: 420,
        className: "text-blue-500",
        Cell: ({ cell }) => {
          return cell.row.original.CoachEmploymentType.name;
        },
      },
    ],
    [sortInput]
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setDrawerOpened(false),
  });

  const { data } = trpc.employmentType_findMany.useQuery();
  const employmentTypes = data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const filterLabels = {
    coachEmploymentTypeId: employmentTypes?.find(
      (item) => item.value === params.coachEmploymentTypeId
    )?.label,

    searchText: params.searchText,
  };

  if (coachFindMany.isError) return <div>Greška</div>;

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        <div className="flex flex-row items-center justify-between pb-4">
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({ ...params, searchText: e.target.value })
            }
          />

          <div className="flex flex-row items-center gap-6">
            {isLg && (
              <Button
                leftIcon={<TiPlus size={20} />}
                onClick={() => navigate("novi")}
                sx={{ fontWeight: "bold" }}
              >
                Dodaj trenera
              </Button>
            )}

            <ActionButton onClick={() => setDrawerOpened(true)} label="Filter">
              <TbFilter size={24} />
            </ActionButton>
          </div>
        </div>

        {/**
         * ACTIVE FILTERS
         */}
        {Object.keys(removeFalsies(params)).length ? (
          <Group px="xl">
            {Object.keys(removeFalsies(params)).map((key) => (
              <Badge
                size="lg"
                sx={{
                  background: "#FCE6E4",
                  color: "#617A9E",
                }}
                key={key}
                rightSection={
                  <ActionIcon
                    size="xs"
                    radius="xl"
                    variant="transparent"
                    onClick={() => setParams(_.omit(params, key))}
                  >
                    <MdClose size={16} />
                  </ActionIcon>
                }
              >
                {filterLabels[key]}
              </Badge>
            ))}
          </Group>
        ) : null}

        <DataTable
          isLoading={coachFindMany.isLoading}
          data={coaches}
          columns={columns}
          onRowClick={(row) => navigate(`detalji/${row.id}`)}
          renderItem={(row) => (
            <Group noWrap>
              <Text>
                {row.firstName} {row.lastName}
              </Text>
              <Text>{row.dateOfBirth || "-/-"}</Text>
            </Group>
          )}
          renderItemHeight={55}
          restoreScrollKey={"coachesListScreen"}
        />
      </ContentContainer>

      <AddNewFAB />

      <Drawer
        withCloseButton={false}
        overlayColor={theme.other.overlayColor}
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={
          <Text
            sx={{
              color: theme.colors.blue[4],
            }}
            weight={500}
            size={24}
          >
            Filter
          </Text>
        }
        position="right"
        closeOnEscape
        closeOnClickOutside
        styles={(theme) => ({
          root: {},
          title: {},
          drawer: {
            borderTopLeftRadius: theme.radius.lg,
            borderBottomLeftRadius: theme.radius.lg,
            backdropFilter: "blur(8px)",
            background: theme.other.sidebarFilterBackground,
          },
          header: {
            padding: theme.spacing.xl,
            height: isLg ? "80px" : "62px",
          },
        })}
      >
        <Stack
          {...swipeHandlers}
          spacing="xl"
          px="md"
          py="xl"
          sx={{ height: "100%" }}
        >
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({ ...params, searchText: e.target.value })
            }
            styles={{ input: { background: "white" } }}
            sx={{ width: "auto" }}
          />
          <SelectEmploymentType
            hideLabel
            styles={{ input: { background: "white" } }}
            value={params.coachEmploymentTypeId}
            onChange={(coachEmploymentTypeId) =>
              setParams({
                ...params,
                coachEmploymentTypeId,
              })
            }
            sx={{ width: "auto" }}
          />
        </Stack>
      </Drawer>
    </>
  );
};
