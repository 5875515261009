import { TextInput, Select, Button } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { matchUpsertSchema } from "models";
import { notifications } from "sdk/common/helpers";
import { trpc } from "utils/trpc";

export const MatchNewForm = ({ match, onClose }) => {
  const utils = trpc.useContext();

  const { data: competition } = trpc.competition_find.useQuery({
    id: match.competitionId,
  });

  const matchUpsert = trpc.match_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.competition_find.invalidate({ id: data.competitionId });
      utils.match_find.invalidate({ id: data.id });
      onClose?.();
    },
  });

  const form = useForm({
    initialValues: match,
    validate: zodResolver(matchUpsertSchema),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => matchUpsert.mutate(values))}
      className="flex flex-col gap-8 overflow-y-auto px-2 py-4"
    >
      <div className="flex flex-col gap-4">
        <TextInput
          sx={{ width: "auto" }}
          label="Naziv"
          {...form.getInputProps("name")}
        />
        <DatePicker
          withAsterisk
          sx={{ width: "auto" }}
          initialLevel="date"
          label="Datum"
          {...form.getInputProps("startDate")}
        />
        <TimeInput
          sx={{ width: "auto" }}
          label="Vrijeme početka"
          placeholder="hh:mm"
          {...form.getInputProps("startTime")}
        />
        <TextInput
          withAsterisk
          sx={{ width: "auto" }}
          label="Lokacija"
          {...form.getInputProps("location")}
        />
        <Select
          sx={{ width: "auto" }}
          label="Dio natjecanja"
          data={
            competition?.CompetitionPeriods?.map((item) => ({
              value: item.id,
              label: item.name,
            })) || []
          }
          {...form.getInputProps("competitionPeriodId")}
        />
        <TextInput
          sx={{ width: "auto" }}
          label="Strana"
          {...form.getInputProps("side")}
        />
      </div>
      <div className={`flex flex-row justify-between`}>
        <Button
          sx={{ minWidth: 170 }}
          variant="outline"
          onClick={() => onClose?.()}
          disabled={matchUpsert.isLoading}
        >
          Odustani
        </Button>
        <Button
          sx={{ minWidth: 170 }}
          type="submit"
          loading={matchUpsert.isLoading}
          disabled={matchUpsert.isLoading}
        >
          Spremi
        </Button>
      </div>
    </form>
  );
};
