/* eslint-disable react/prop-types */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import React, { useState } from "react";
import { HiPencil } from "react-icons/hi";
import { IoEnterOutline } from "react-icons/io5";
import { TiPlus } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useSetTitle } from "sdk/common/hooks";
import { AddNewFAB } from "sdk/components/AddNewFAB";
import { ContentContainer } from "sdk/components/ContentContainer";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { trpc } from "utils/trpc";

export const Korisnici = () => {
  const navigate = useNavigate();
  useSetTitle("Korisnici");

  const [filter, setFilter] = useState({ searchText: "" });
  const [debouncedFilter] = useDebouncedValue(filter, 500);

  const userFindMany = trpc.user_findMany.useInfiniteQuery(
    { searchText: debouncedFilter.searchText || "" },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    }
  );
  const users = userFindMany.data?.pages.flatMap((arr) => arr.items);

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "",
        id: "eye",
        accessor: "",
        className: "pt-1 text-blue-400 hover:text-blue-700",
        Cell: () => <IoEnterOutline size={20} />,
        width: 36,
      },
      {
        Header: "",
        id: "edit",
        accessor: "",
        className: "pt-1 text-blue-400 hover:text-blue-700",
        Cell: (data) => (
          <HiPencil
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/postavke/korisnici/uredi/${data.cell.row.original.id}`
              );
            }}
          />
        ),
        width: 36,
      },
      {
        Header: "E-mail/Korisničko ime",
        accessor: "email",
        width: 250,
        className: "text-blue-500",
      },
      {
        Header: "Sportski klub",
        accessor: "Organization.name",
        width: 350,
        className: "text-blue-500",
      },
      {
        Header: "Sport",
        accessor: "Organization.Sport.name",
        width: 350,
        className: "text-blue-500",
      },

      // {
      //   Header: "Telefonski broj",
      //   accessor: "phoneNumber",
      //   width: 150,
      //   className: "text-blue-500",
      // },
      {
        Header: "Ime",
        accessor: "firstName",
        width: 150,
        className: "text-blue-700 font-bold",
      },
      {
        Header: "Prezime",
        accessor: "lastName",
        width: 150,
        className: "text-blue-700 font-bold",
      },
    ],
    []
  );

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        <div className="flex flex-row items-center justify-between pb-4">
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-gray-500"
              />
            }
            placeholder="Traži"
            value={filter?.searchText || ""}
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, searchText: e.target.value }))
            }
            styles={{ input: { background: "white" }, root: { flexGrow: 1 } }}
            sx={{ flex: "0 1 400px" }}
          />

          <Button
            leftIcon={<TiPlus size={20} />}
            onClick={() => navigate("/postavke/korisnici/novi")}
            className="self-end font-bold"
          >
            Dodaj korisnika
          </Button>
        </div>

        <DataTable
          isLoading={userFindMany.isLoading}
          data={users}
          columns={columns}
          onRowClick={(row) =>
            navigate(`/postavke/korisnici/detalji/${row.id}`)
          }
          fetchMore={userFindMany.fetchNextPage}
          restoreScrollKey="UsersListScreen"
        />
      </ContentContainer>
      <AddNewFAB />
    </>
  );
};
