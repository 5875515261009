import "dayjs/locale/hr";
import { useForm, zodResolver } from "@mantine/form";
import { Button, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import { competitionPeriodUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { notifications } from "sdk/common/helpers";

export const PeriodForm = ({ period, onClose }) => {
  const formHandler = useForm({
    initialValues: period,
    validate: zodResolver(competitionPeriodUpsertSchema),
  });

  const utils = trpc.useContext();

  const periodUpsert = trpc.period_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.competition_find.invalidate({ id: data.competitionId });
      onClose();
    },
  });

  return (
    <form
      onSubmit={formHandler.onSubmit((values) => periodUpsert.mutate(values))}
      className="flex flex-col gap-16"
    >
      <div className="flex flex-col gap-4 rounded-xl ">
        <TextInput
          withAsterisk
          className="w-auto flex-1"
          label="Naziv dijela natjecanja"
          {...formHandler.getInputProps("name")}
        />
        <div className="flex gap-8">
          <DatePicker
            initialLevel="date"
            withAsterisk
            className="flex-1"
            label="Početak"
            maxDate={formHandler.values.endDate}
            {...formHandler.getInputProps("startDate")}
          />
          <DatePicker
            initialLevel="date"
            className="flex-1"
            label="Kraj"
            minDate={formHandler.values.startDate}
            {...formHandler.getInputProps("endDate")}
          />
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <Button
          sx={{ minWidth: 190 }}
          variant="outline"
          onClick={onClose}
          disabled={periodUpsert.isLoading}
        >
          Odustani
        </Button>
        <Button
          sx={{ minWidth: 190 }}
          type="submit"
          loading={periodUpsert.isLoading}
          disabled={periodUpsert.isLoading}
        >
          Spremi
        </Button>
      </div>
    </form>
  );
};
