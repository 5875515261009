import React, { useState } from "react";
import { folderIcons, FolderSwitcher } from "./components/FolderSwitcher";
import { FolderDocumentsView } from "./components/FolderDocumentsView";
import { useParams } from "react-router-dom";
import { Button } from "@mantine/core";
import { ulid } from "ulid";
import { FileWithPath } from "@mantine/dropzone";
import { TiPlus } from "react-icons/ti";
import { NewDocumentModal } from "./components/NewDocumentModal";

import { useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";
import { trpc } from "utils/trpc";

export type TDocument =
  | {
      id?: any;
      recordId?: any;
      folderId?: any;
      fileKindId?: any;
      fileName?: any;
      file?: FileWithPath;
    }
  | undefined;

/**
 * Main component for the organization documents screen
 * Should be used as a container for the folder switcher and the folder documents view
 * Behind /pregled/klubovi/:orgId/dokumenti route
 */
export const Dokumenti = () => {
  //TODO:
  // get the folders from the backend using folders_findMany query
  // show the folders in the folder switcher
  // once a query data is loaded, show the first folder in the folder switcher
  // when a selected folder is FolderSwitcher is changed, show the documents for that folder in the FolderDocuments view

  const { orgId } = useParams();

  const [newDocument, setNewDocument] = React.useState<TDocument>();

  const { data: folders } = trpc.folder_findMany.useQuery();

  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(
    "IZVJESTAJI"
  );

  useSetTitle("Dokumenti");

  return (
    <>
      <ContentContainer className="flex h-full flex-1 flex-row border">
        <FolderSwitcher
          folders={
            folders?.map((f) => ({ ...f, lastUpdated: new Date() })) || []
          }
          onFolderSelected={setSelectedFolderId}
          selectedFolderId={selectedFolderId}
        />
        <div className="w-1 bg-[rgb(209,220,235)]" />

        <div className="flex flex-1 flex-col gap-2 overflow-hidden p-8">
          <div className="flex flex-row justify-end ">
            <Button
              className="self-end"
              leftIcon={<TiPlus />}
              onClick={() =>
                setNewDocument({
                  id: ulid(),
                  recordId: orgId as string,
                  fileKindId: "",
                  fileName: "",
                  folderId: selectedFolderId,
                })
              }
            >
              Novi dokument
            </Button>
          </div>

          {selectedFolderId && folders?.length ? (
            <>
              <div className="flex gap-2">
                <img
                  src={
                    folderIcons[
                      folders?.find((f) => f.id === selectedFolderId)?.color ||
                        "yellow"
                    ]?.open
                  }
                  width={40}
                  height="auto"
                />
                <div className="text-xl font-bold text-blue-700">
                  {folders?.find((f) => f.id === selectedFolderId)?.name}
                </div>
              </div>

              <FolderDocumentsView folderId={selectedFolderId} />
            </>
          ) : null}
        </div>
      </ContentContainer>
      <NewDocumentModal
        folders={folders || []}
        newDocument={newDocument}
        setNewDocument={setNewDocument}
      />
    </>
  );
};
