import { z } from "zod";
import { idSchema, isValidOIB } from "../helpers";
export const memberCreateSchema = z
    .object({
    id: idSchema,
    orgId: z.string().min(1),
    sportId: z.string(),
    firstName: z.string().min(1, { message: "Ime je obvezan podatak" }),
    lastName: z.string().min(1, { message: "Prezime je obvezan podatak" }),
    gender: z.string().min(1, { message: "Spol je obvezan podatak" }),
    // countryId: z.string().min(2),
    dateOfBirth: z.date({
        required_error: "Datum rođenja je obvezan podatak",
        invalid_type_error: "Datum rođenja je obvezan podatak",
    }),
    parentNames: z.string().optional(),
    email: z
        .string()
        .email("E-mail je neispravan")
        .optional()
        .or(z.literal("")),
    phoneNumber: z.string().optional(),
    description: z.string().optional(),
    membershipTypeId: z
        .string({
        invalid_type_error: "Tip člana je obvezan podatak",
        required_error: "Tip člana je obvezan podatak",
    })
        .min(1, { message: "Tip člana je obvezan podatak" }),
    oib: z.string({
        required_error: "OIB je obvezan podatak",
        invalid_type_error: "OIB je obvezan podatak",
    }),
    MemberAgeGroups: z.array(z.object({
        id: z.string().min(1),
        ageGroupId: z.string({
            invalid_type_error: "Dobna skupina je obvezan podatak",
        }),
        dateFrom: z.date().nullish(),
    }), { invalid_type_error: "Dobna skupina je obvezan podatak" }),
    MemberCategories: z.array(z.object({
        id: z.string().min(1),
        categoryNo: z.string({
            invalid_type_error: "Sportska kategorija je obvezan podatak",
        }),
        dateFrom: z
            .date({
            invalid_type_error: "Obvezan podatak",
            required_error: "Obvezan podatak",
        })
            .nullish(),
        dateTo: z
            .date({
            invalid_type_error: "Obvezan podatak",
            required_error: "Obvezan podatak",
        })
            .nullish(),
        description: z.string().nullish(),
    })),
    MemberPhysiqueRecords: z.array(z.any()),
    MemberMedicalRecords: z.array(z.any()),
    // MemberMe,
    membershipStartDate: z
        .date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    })
        .nullish(),
    membershipEndDate: z
        .date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    })
        .nullish(),
    address: z.string().nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    houseNo: z.string().nullish(),
    memberRole: z.string().nullish(),
    nationalSelectionName: z.string().nullish(),
})
    .superRefine((values, ctx) => {
    if (isValidOIB(values.oib) === false) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "OIB je neispravnog formata",
            path: ["oib"],
        });
    }
    if (["RS", "NRS"].includes(values.membershipTypeId) &&
        !values.MemberAgeGroups[0].ageGroupId) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Dobna skupina je obvezan podatak",
            path: ["MemberAgeGroups", "0", "ageGroupId"],
        });
    }
    if (values.membershipTypeId === "RS" &&
        !values.MemberCategories?.[0].categoryNo) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Sportska kategorija je obvezan podatak",
            path: ["MemberCategories", "0", "categoryNo"],
        });
    }
    if (values.membershipTypeId === "RS" &&
        values.MemberCategories[0].categoryNo &&
        values.MemberCategories[0].categoryNo !== "Nekategoriziran" &&
        !values.MemberCategories?.[0].dateFrom) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Datum početka je obvezan podatak",
            path: ["MemberCategories", "0", "dateFrom"],
        });
    }
    // if (
    //   values.membershipTypeId === "RS" &&
    //   values.MemberCategories[0].categoryNo &&
    //   values.MemberCategories[0].categoryNo !== "Nekategoriziran" &&
    //   !values.MemberCategories?.[0].dateTo
    // ) {
    //   ctx.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: "Datum kraja je obvezan podatak",
    //     path: ["MemberCategories", "0", "dateTo"],
    //   });
    // }
});
