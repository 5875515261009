import React from "react";
import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { trpc } from "utils/trpc";

export const SelectSport = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & { hideLabel?: boolean },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    const { data, isLoading, isError } = trpc.sport_findMany.useQuery();

    const sports =
      data?.map((c) => ({
        value: c?.id,
        label: c?.name,
      })) || [];

    if (isLoading) return <Loader />;
    if ((!isLoading && !data) || isError) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Sport"}
        label={hideLabel ? undefined : "Sport"}
        {...rest}
        data={sports}
      />
    );
  }
);

SelectSport.displayName = "SelectSport";
