import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export type TDialogProps = {
  show?: boolean;
  titleText?: string;
  contentText?: string;
  buttonText?: string;
  onReject?: () => void;
  onAccept?: () => void;
  isLoading?: boolean;
};

interface IAppState {
  isSidebarOpen: boolean;
  title?: string | null;
  showHeaderBackButton: boolean;
  newMember?: any;

  dialog?: TDialogProps;
}
interface IAppActions {
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  setTitle: (title: string | null | undefined) => void;
  setHeaderShowBackButton: (showBackButton: boolean) => void;
  resetStore: () => void;
  setNewMember: (newMember: any) => void;

  setDialog: (dialog: TDialogProps) => void;
}

const initialValues: IAppState = {
  isSidebarOpen: false,
  title: "",
  showHeaderBackButton: false,
  newMember: undefined,

  dialog: {},
};

export const useAppState = create<IAppState & IAppActions>()(
  devtools(
    persist(
      (set) => ({
        ...initialValues,

        setSidebarOpen: (isSidebarOpen) =>
          set((state) => ({ ...state, isSidebarOpen })),

        setTitle: (title) => set((state) => ({ ...state, title })),
        setHeaderShowBackButton: (showHeaderBackButton) =>
          set((state) => ({ ...state, showHeaderBackButton })),
        resetStore: () => set(initialValues),
        setNewMember: (newMember) => set((state) => ({ ...state, newMember })),
        setDialog: (dialog) => set((state) => ({ ...state, dialog })),
      }),
      {
        name: "state",
        getStorage: () => localStorage,
      }
    )
  )
);
