import { Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import { assert } from "sdk/assert";
import { useSetTitle } from "sdk/common/hooks";
import { trpc } from "utils/trpc";
import { MatchUpdateForm } from "./components/MatchUpdateForm";
import { ContentContainer } from "sdk/components/ContentContainer";

export const DetaljiSusreta = () => {
  const { competitionMatchId, orgId } = useParams();
  assert(competitionMatchId && orgId);
  useSetTitle("Detalji susreta", { showBackButton: true });

  const { data, isLoading, isError } = trpc.match_find.useQuery({
    id: competitionMatchId,
    orgId,
  });

  if (isLoading) return <Loader mx="auto" size="lg" />;
  if (isError || !data) return <div>error</div>;

  return (
    <ContentContainer>
      <MatchUpdateForm match={data} />;
    </ContentContainer>
  );
};
