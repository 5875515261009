import { useForm, zodResolver } from "@mantine/form";
import {
  Text,
  Box,
  Button,
  Group,
  TextInput,
  Stack,
  useMantineTheme,
  Modal,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { SelectSport } from "sdk/components/Selects/SelectSport";
import { DatePicker } from "@mantine/dates";

import { ProfilePicture } from "sdk/components/ProfilePicture";
import { notifications } from "sdk/common/helpers";
import { useRef } from "react";
import dayjs from "dayjs";
import React from "react";
import { organizationUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { useMe } from "sdk/common/hooks";
import { MdOutlineCancel } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";

export const OrganizationForm = ({ initialValues, isEdit = false }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();

  const user = useMe();

  const formRef = useRef<HTMLDivElement | null>(null);
  const formHandler = useForm({
    initialValues,
    validate: zodResolver(organizationUpsertSchema),
    validateInputOnBlur: true,
  });

  const [showStatusChangeModal, setShowStatusChangeModal] =
    React.useState(false);

  const theme = useMantineTheme();
  const utils = trpc.useContext();

  const organizationUpsert = trpc.organization_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.organization_find.invalidate({ id: data.id });
      utils.organization_findMany.invalidate();
      navigate(`/pregled/klubovi/${data.id}`);
    },
  });

  return (
    <>
      <form
        onSubmit={formHandler.onSubmit(
          (values) => organizationUpsert.mutate(values),
          (err) => console.log({ err })
        )}
        className="mx-auto flex h-full max-w-[1400px] flex-1"
      >
        <div
          ref={formRef}
          className="flex h-full flex-1 flex-col gap-4 overflow-auto p-6 "
        >
          <Section sectionNo="01" title="LOGO">
            <div className="flex flex-1 flex-col items-center pr-40">
              <ProfilePicture
                recordId={orgId as string}
                avatarRecordFileId={formHandler.values.avatarRecordFileId || ""}
                onChange={(value) =>
                  formHandler.setFieldValue("avatarRecordFileId", value)
                }
              />
            </div>
          </Section>
          <Section sectionNo="02" title="KROVNA ORGANIZACIJA">
            <SelectSport
              {...formHandler.getInputProps("sportId")}
              sx={{ width: "auto" }}
              clearable
              searchable
              withAsterisk
            />
            <TextInput
              sx={{ minWidth: "100%" }}
              label="Puni naziv"
              withAsterisk
              {...formHandler.getInputProps("name")}
            />
            <TextInput
              label="Skraćeni naziv"
              sx={{ minWidth: "100%" }}
              // withAsterisk
              {...formHandler.getInputProps("shortName")}
            />
          </Section>

          <Section sectionNo="03" title="ADRESA SJEDIŠTA">
            <Stack sx={{ flex: 1 }}>
              <Group>
                <TextInput
                  sx={{ flex: 0.67 }}
                  label="Ulica"
                  withAsterisk
                  {...formHandler.getInputProps("address")}
                />
                <TextInput
                  sx={{ flex: 0.33 }}
                  label="Kućni broj"
                  {...formHandler.getInputProps("houseNumber")}
                />
              </Group>
              <Group>
                <TextInput
                  label="Poštanski broj"
                  withAsterisk
                  sx={{ flex: 0.33 }}
                  {...formHandler.getInputProps("zipCode")}
                />
                <TextInput
                  label="Grad"
                  sx={{ flex: 0.67 }}
                  withAsterisk
                  {...formHandler.getInputProps("city")}
                />
              </Group>
            </Stack>
          </Section>

          <Section sectionNo="04" title="PREDSJEDNIK/CA">
            <Stack>
              <TextInput
                label="Ime i prezime"
                className="w-full"
                withAsterisk
                {...formHandler.getInputProps("authorizedPersonName")}
              />
              <TextInput
                label="Telefon"
                className="w-full"
                {...formHandler.getInputProps("authorizedPersonPhone")}
              />
              <TextInput
                label="E-mail"
                className="w-full"
                {...formHandler.getInputProps("authorizedPersonEmail")}
              />
              <div className="flex flex-row gap-6">
                <DatePicker
                  locale="hr"
                  label="Trajanje mandata od"
                  {...formHandler.getInputProps("mandateStartDate")}
                  onChange={(date) => {
                    formHandler.setFieldValue("mandateStartDate", date);
                    formHandler.setFieldValue(
                      "mandateEndDate",
                      date ? dayjs(date).add(4, "year").toDate() : null
                    );
                  }}
                  className="flex-1"
                  initialLevel="year"
                  maxDate={new Date()}
                />
                <DatePicker
                  locale="hr"
                  label="Trajanje mandata do"
                  {...formHandler.getInputProps("mandateEndDate")}
                  className="flex-1"
                  initialLevel="year"
                ></DatePicker>
              </div>
            </Stack>
          </Section>
          <Section sectionNo="05" title="GLAVNI TAJNIK/CA">
            <Stack>
              <TextInput
                label="Ime i prezime"
                className="w-full"
                {...formHandler.getInputProps("contactPersonName")}
              />
              <TextInput
                label="Telefon"
                className="w-full"
                {...formHandler.getInputProps("contactPersonPhone")}
              />
              <TextInput
                label="E-mail"
                className="w-full"
                {...formHandler.getInputProps("contactPersonEmail")}
              />
              <div className="flex flex-row gap-6">
                <DatePicker
                  locale="hr"
                  label="Trajanje mandata od"
                  className="flex-1"
                  initialLevel="year"
                  {...formHandler.getInputProps("secretaryMandateStartDate")}
                  onChange={(date) => {
                    formHandler.setFieldValue(
                      "secretaryMandateStartDate",
                      date
                    );
                    formHandler.setFieldValue(
                      "secretaryMandateEndDate",
                      date ? dayjs(date).add(4, "year").toDate() : null
                    );
                  }}
                  maxDate={new Date()}
                />
                <DatePicker
                  locale="hr"
                  label="Trajanje mandata do"
                  {...formHandler.getInputProps("secretaryMandateEndDate")}
                  className="flex-1"
                  initialLevel="year"
                ></DatePicker>
              </div>
            </Stack>
          </Section>

          <Section sectionNo="06" title="OSTALO">
            <Stack>
              <TextInput
                label="OIB"
                withAsterisk
                className="w-full"
                {...formHandler.getInputProps("vatId")}
                onChange={(e) =>
                  /^[0-9]{0,11}$/.test(e.target.value) &&
                  formHandler.setFieldValue("vatId", e.target.value)
                }
              />
              <TextInput
                label="E-mail"
                className="w-full"
                {...formHandler.getInputProps("email")}
                withAsterisk
              />
              <TextInput
                label="Matični broj"
                className="w-full"
                {...formHandler.getInputProps("mb")}
              />
              <TextInput
                label="RNO"
                className="w-full"
                {...formHandler.getInputProps("rno")}
              />
              <TextInput
                label="RB"
                className="w-full"
                {...formHandler.getInputProps("rb")}
              />
              <TextInput
                label="Ime banke"
                className="w-full"
                {...formHandler.getInputProps("bankName")}
              />
              <TextInput
                label="IBAN"
                className="w-full"
                {...formHandler.getInputProps("iban")}
              />
              <TextInput
                label="Web stranica"
                className="w-full"
                {...formHandler.getInputProps("webAddress")}
              />
            </Stack>
          </Section>
          <Section sectionNo="07" title="VAŽNI DATUMI">
            <Stack>
              <DatePicker
                locale="hr"
                label="Datum osnivačke skupštine"
                {...formHandler.getInputProps("foundingAssemblyDate")}
                className="w-full"
                initialLevel="year"
                maxDate={new Date()}
              />
              <DatePicker
                locale="hr"
                label="Datum prijema u članstvo"
                {...formHandler.getInputProps("membershipAdmissionDate")}
                className="w-full"
                initialLevel="year"
                maxDate={new Date()}
              />
            </Stack>
          </Section>
        </div>
        <div className="flex w-64 flex-col items-center gap-4 pl-8 pr-8 pb-8 pt-4 ">
          <div className="mt-4 self-start border-0 border-b-4 border-solid border-blue-600 text-sm font-bold">
            PODACI KLUBA
          </div>
          <div className="flex-1" />
          {user?.orgId?.toLowerCase() === "szgz" && isEdit && (
            <>
              {formHandler.values.status === "ACTIVE" && (
                <button
                  onClick={() => setShowStatusChangeModal(true)}
                  className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-blue-500"
                >
                  <MdOutlineCancel size={22} />
                  Deaktiviraj članicu
                </button>
              )}

              {formHandler.values.status === "INACTIVE" && (
                <button
                  onClick={() => setShowStatusChangeModal(true)}
                  className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-green-500"
                >
                  <IoMdCheckmarkCircle size={22} />
                  Aktiviraj članicu
                </button>
              )}

              <div className="h-px self-stretch bg-blue-400" />
            </>
          )}

          <Button
            sx={{ minWidth: 190 }}
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Odustani
          </Button>
          <Button
            loading={organizationUpsert.isLoading}
            sx={{ minWidth: 190 }}
            type="submit"
          >
            Spremi
          </Button>
        </div>
      </form>

      <Modal
        overlayColor={theme.other.overlayColor}
        opened={showStatusChangeModal}
        onClose={() => setShowStatusChangeModal(false)}
        // styles={{
        //   modal: {
        //     padding: "0 !important",
        //     overflow: "hidden",
        //     borderRadius: theme.radius.lg,
        //     boxShadow: theme.shadows.xl,
        //   },
        // }}
        title={
          formHandler.values.status === "ACTIVE"
            ? "Deaktiviranje članice"
            : "Aktiviranje članice"
        }
      >
        <Box>
          <Stack align="center" p="xl" sx={{}}>
            <Text my={32}>
              {formHandler.values.status === "ACTIVE"
                ? "Potvrdite deaktivaciju članice"
                : "Potvrdite aktivaciju članice"}
            </Text>
            <Group>
              <Button
                variant="outline"
                disabled={organizationUpsert.isLoading}
                onClick={() => setShowStatusChangeModal(false)}
                sx={{}}
              >
                Odustani
              </Button>
              <Button
                loading={organizationUpsert.isLoading}
                onClick={() =>
                  organizationUpsert.mutate({
                    ...initialValues,
                    status:
                      initialValues.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                  })
                }
              >
                Potvrdi
              </Button>
            </Group>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
const Section = (props: {
  children?: React.ReactNode;
  title: string;
  sectionNo: string;
}) => {
  const { children, sectionNo, title } = props;
  return (
    <div className="flex-1 rounded-2xl bg-form-background p-4 focus-within:shadow-table-row hover:shadow-table-row">
      <Text>{sectionNo}</Text>
      <div className="flex flex-1 flex-row gap-4">
        <HeaderLabel text={title}></HeaderLabel>
        <div className="flex flex-1 flex-col gap-2">{children}</div>
      </div>
    </div>
  );
};
const HeaderLabel = (props: { text: string }) => {
  return (
    <div className="min-w-[200px]">
      <Text
        sx={(theme) => ({
          color: theme.colors.blue[5],
          fontWeight: 400,
          fontFamily: "Satoshi-Black",
        })}
      >
        {props.text}
      </Text>
    </div>
  );
};
