import {
  Stack,
  Text,
  Image,
  Group,
  Box,
  ActionIcon,
  Loader,
  Modal,
  useMantineTheme,
  Title,
} from "@mantine/core";

import axios from "axios";
import { useParams } from "react-router-dom";
import React from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileDownload from "js-file-download";
import { useBreakpoints } from "../common/hooks";
import { getUrlFromRecordFileId } from "../common/helpers";
import { trpc } from "utils/trpc";

export const RecordFilesView = ({
  recordId,
}: {
  recordId: string;
  isReadOnly?: boolean;
}) => {
  const { orgId } = useParams();

  const theme = useMantineTheme();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>();

  React.useEffect(() => {
    selectedFile && setIsModalOpen(true);
  }, [selectedFile]);

  const {
    data: recordFiles,
    isLoading,
    error,
    refetch,
  } = trpc.recordFile_findMany.useQuery({
    recordId,
  });

  const { isSm, isMd } = useBreakpoints();

  const [iframeUrl, setIframeUrl] = React.useState<any>();

  React.useEffect(() => {
    if (selectedFile) {
      axios
        .get(
          `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${
            selectedFile?.id
          }`,
          {
            responseType: "blob",
          }
        )
        .then((res) => setIframeUrl(URL.createObjectURL(res.data)));
    }
  }, [selectedFile]);

  return (
    <>
      <Group spacing="xl" sx={{ alignItems: "flex-start" }}>
        {recordFiles?.map((recordFile) => (
          <Box
            key={recordFile.id}
            sx={{
              cursor: "zoom-in",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFile({ ...recordFile });
            }}
          >
            {recordFile.mimeType.includes("image") ? (
              <Image
                height={80}
                radius="md"
                src={getUrlFromRecordFileId(recordFile?.id)}
              />
            ) : (
              <DocumentBox recordFile={recordFile} />
            )}

            {recordFile.FileKind?.name && (
              <Text sx={{ textAlign: "center" }}>
                {recordFile.FileKind?.name}
              </Text>
            )}
          </Box>
        ))}
      </Group>

      <Modal
        withCloseButton={true}
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={isMd ? "960px" : "100%"}
        overflow="inside"
        styles={{ inner: { padding: 100 } }}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack p="md">
          <Title order={5}>{selectedFile?.fileName}</Title>
          {selectedFile?.mimeType?.includes("image") ? (
            <Image src={getUrlFromRecordFileId(selectedFile?.id)} />
          ) : (
            <iframe width="100%" height={isSm ? 800 : 500} src={iframeUrl} />
          )}
        </Stack>
      </Modal>
    </>
  );
};

const DocumentBox = ({ recordFile }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        height: 80,
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: theme.radius.md,
        padding: 8,
        gap: 8,
        background: theme.colors.blue[0],
      })}
    >
      <Text>{recordFile.fileName}</Text>
      <ActionIcon
        disabled={isDownloading}
        onClick={() => {
          setIsDownloading(true);
          axios
            .get(
              `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${
                recordFile?.id
              }`,
              {
                responseType: "blob",
              }
            )
            .then((res) => FileDownload(res.data, recordFile.fileName || ""))
            .finally(() => setIsDownloading(false));
        }}
      >
        {isDownloading ? <Loader /> : <FontAwesomeIcon icon={faDownload} />}
      </ActionIcon>
    </Box>
  );
};
