import { Loader } from "@mantine/core";
import { IoMdCreate } from "react-icons/io";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { assert } from "sdk/assert";
import { useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { trpc } from "utils/trpc";

export const DetaljiKorisnika = () => {
  const { id } = useParams();
  useSetTitle("Korisnici", { showBackButton: true });

  const { data: user, isLoading } = trpc.user_find.useQuery({
    id: id as string,
  });

  if (isLoading) return <Loader />;
  assert(user);
  return (
    <ContentContainer className="h-full overflow-auto">
      <div className="mx-auto flex max-w-[1400px] flex-row gap-12 p-8">
        <div className="ml-10 flex flex-col gap-2 text-blue-600">
          <div className="text-4xl font-bold text-blue-700">{`${user?.firstName} ${user?.lastName}`}</div>
          <div className="flex flex-col">
            <div>{user?.Organization?.name}</div>
            <div>{user?.email}</div>
            <div>{user?.phoneNumber}</div>
          </div>
        </div>
        <div className="flex flex-col ">
          <ProfilePicture
            disableEdit={true}
            recordId={user.id}
            avatarRecordFileId={user.avatarRecordFileId}
          />
        </div>
        <div className="relative flex flex-1 flex-col gap-8 rounded-xl bg-[#fdfaff] p-4 px-8">
          <NavLink
            to={`/postavke/korisnici/uredi/${user.id}`}
            className={`absolute top-0 right-0 flex h-12 w-12 translate-x-1/3
                        -translate-y-1/3 items-center justify-center rounded-full
                        bg-blue-700 text-2xl text-[#fdfaff]
                        shadow-button
                        hover:shadow-button-hover
                        active:shadow-button-active`}
          >
            <IoMdCreate />
          </NavLink>

          <DetailsCardLabelAndValue label="Korisničko ime" value={user.email} />
          <DetailsCardLabelAndValue
            label="Klub"
            value={user?.Organization?.name}
          />
          <DetailsCardLabelAndValue label="Telefon" value={user?.phoneNumber} />
          <DetailsCardLabelAndValue label="Status" value="AKTIVAN" />
          <DetailsCardLabelAndValue label="Zadnja aktivnost" />
        </div>
      </div>
    </ContentContainer>
  );
};

const DetailsCardLabelAndValue = (props) => {
  const { label, value } = props;
  return (
    <div className="flex flex-row gap-4 border-0 border-b-1 border-solid border-blue-500 pb-2">
      <div className="font-bold uppercase text-szgz-details-card-label">
        {label}
      </div>
      <div className="flex-1"></div>
      <div className="self-end font-bold text-blue-700">{value}</div>
    </div>
  );
};
