import { useNavigate, useParams } from "react-router-dom";

import { openDeleteDialog, useSetTitle } from "sdk/common/hooks";
import {
  ActionIcon,
  Button,
  Loader,
  Modal,
  TextInput,
  Text,
} from "@mantine/core";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import RichTextEditor from "@mantine/rte";
import { CompetitionPeriodUpsertSchema, competitionUpsertSchema } from "models";
import React from "react";
import { MdOutlineAddCircle } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { notifications } from "sdk/common/helpers";
import { RecordFiles } from "sdk/components/RecordFiles";
import { SelectAgeGroup } from "sdk/components/Selects/SelectAgeGroup";
import { SelectCompetitionType } from "sdk/components/Selects/SelectCompetitionType";
import { ulid } from "ulid";
import { PeriodForm } from "./components/PeriodForm";
import { Label } from "sdk/components/Label";

export const UrediNatjecanje = () => {
  useSetTitle("Izmjena natjecanja", { showBackButton: true });
  const { id } = useParams();

  const {
    data: competition,
    isLoading,
    isError,
  } = trpc.competition_find.useQuery({ id: id! });

  if (isLoading) return <Loader mx="auto" size="lg" />;
  if (isError || !competition) return <div>Error</div>;

  return <CompetitionUpdateForm competition={competition} />;
};

const CompetitionUpdateForm = ({ competition }) => {
  const navigate = useNavigate();

  const { id, orgId } = useParams();

  const [modalPeriod, setModalPeriod] = React.useState<
    CompetitionPeriodUpsertSchema | undefined
  >();

  const formHandler = useForm({
    initialValues: competition,
    validate: zodResolver(competitionUpsertSchema),
  });

  const utils = trpc.useContext();

  const competitionUpsert = trpc.competition_upsert.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.competition_find.invalidate({ id });
      utils.competition_findMany.invalidate({ orgId });
      navigate(`/pregled/klubovi/${orgId}/natjecanja/detalji/${id}`);
    },
  });

  const periodDelete = trpc.period_delete.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.competition_find.invalidate({ id: data.competitionId });
    },
  });

  const competitionDelete = trpc.competition_delete.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.competition_find.invalidate({ id });
      utils.competition_findMany.invalidate({ orgId });
      navigate(`/pregled/klubovi/${orgId}/natjecanja`);
    },
  });

  return (
    <>
      <ContentContainer className="h-full">
        <form
          onSubmit={formHandler.onSubmit(
            (values) => competitionUpsert.mutate(values),
            (err) => console.log({ err })
          )}
          className="mx-auto flex h-full max-w-[1400px]"
        >
          <div className="flex flex-1 flex-col  gap-16 overflow-auto  p-4">
            {/* <pre>{JSON.stringify({ values: formHandler.errors }, null, 2)}</pre> */}
            <div className="flex flex-col gap-2">
              <TextInput
                label="Naziv natjecanja"
                withAsterisk
                {...formHandler.getInputProps("name")}
              />
              <div className="flex gap-4">
                <SelectCompetitionType
                  withAsterisk
                  className="flex-1"
                  {...formHandler.getInputProps("typeId")}
                />
                <SelectAgeGroup
                  withAsterisk
                  className="flex-1"
                  {...formHandler.getInputProps("ageGroupId")}
                />
              </div>
              <div className="flex gap-4">
                <DatePicker
                  initialLevel="date"
                  withAsterisk
                  className="flex-1"
                  label="Početak"
                  {...formHandler.getInputProps("startDate")}
                />
                <DatePicker
                  initialLevel="date"
                  className="flex-1"
                  label="Kraj"
                  {...formHandler.getInputProps("endDate")}
                />
              </div>
            </div>

            <div className="flex flex-col rounded-lg bg-[#fdfaff] p-4">
              <Label>Dijelovi natjecanja</Label>
              <div className="mt-2 flex flex-col gap-4">
                {competition?.CompetitionPeriods?.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between gap-8"
                  >
                    <div
                      className="flex flex-1 cursor-pointer items-end gap-4 rounded-xl bg-blue-200 p-4 transition-all hover:rounded-2xl hover:bg-blue-300"
                      key={item.id}
                      onClick={() => setModalPeriod(item)}
                    >
                      <TextInput
                        withAsterisk
                        className="flex-1"
                        disabled
                        label="Naziv dijela natjecanja"
                        value={item.name}
                      />
                      <DatePicker
                        initialLevel="date"
                        withAsterisk
                        className="flex-1"
                        label="Početak"
                        value={item.startDate}
                        disabled
                      />
                      <DatePicker
                        initialLevel="date"
                        // withAsterisk
                        className="flex-1"
                        label="Kraj"
                        disabled
                        value={item.endDate}
                      />
                    </div>
                    <ActionIcon
                      className=""
                      color="pink"
                      variant="light"
                      radius="xl"
                      size="xl"
                      disabled={competition.CompetitionPeriods?.length === 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteDialog({
                          onAccept() {
                            periodDelete.mutate({ id: item.id });
                          },
                          titleText: "Izbriši dio natjecanje",
                          contentText: "Potvrdom čete izbrisati dio natjecanja",
                        });
                      }}
                    >
                      <TbTrash />
                    </ActionIcon>
                  </div>
                ))}
              </div>
              <div
                className="mt-8 flex cursor-pointer gap-1 self-end"
                onClick={() =>
                  setModalPeriod({
                    competitionId: id!,
                    id: ulid(),
                    startDate: new Date(),
                    name: "",
                  })
                }
              >
                <MdOutlineAddCircle
                  style={{ color: "rgb(120, 169, 247)" }}
                  size={24}
                />
                <Text weight="bold" color={"rgb(120, 169, 247)"}>
                  Dodaj dio
                </Text>
              </div>
            </div>

            <>
              <div>
                <label className="text-sm font-medium text-blue-500">
                  Opis natjecanja
                </label>
                <RichTextEditor
                  id="rte"
                  mih={300}
                  // placeholder="Dodatan opis natjecanja..."

                  {...formHandler.getInputProps("description")}
                />
              </div>

              <RecordFiles recordId={id as string} />
            </>
          </div>
          <div className="flex flex-col items-center justify-end gap-8 px-8 pb-8">
            <button
              type="button"
              onClick={() =>
                openDeleteDialog({
                  onAccept() {
                    competitionDelete.mutate({
                      id: id as string,
                      orgId: orgId as string,
                    });
                  },
                  titleText: "Izbriši natjecanje",
                  contentText: "Potvrdom čete izbrisati natjecanje",
                })
              }
              className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-pink-500"
            >
              <TbTrash size={22} />
              <div>Izbriši natjecanje</div>
            </button>

            <div className="h-px self-stretch bg-blue-400" />

            <Button
              sx={{ minWidth: 190 }}
              variant="outline"
              onClick={() => navigate(-1)}
              disabled={competitionUpsert.isLoading}
            >
              Odustani
            </Button>
            <Button
              sx={{ minWidth: 190 }}
              type="submit"
              loading={competitionUpsert.isLoading}
              disabled={competitionUpsert.isLoading}
            >
              Spremi
            </Button>
          </div>
        </form>
      </ContentContainer>

      <Modal
        opened={!!modalPeriod}
        onClose={() => setModalPeriod(undefined)}
        title={
          competition?.CompetitionPeriods?.find((p) => p.id === modalPeriod?.id)
            ? "Uredi dio natjecanja"
            : "Novi dio natjecanja"
        }
      >
        <PeriodForm
          period={modalPeriod}
          onClose={() => setModalPeriod(undefined)}
        />
      </Modal>
    </>
  );
};
