import React, { MouseEventHandler, useRef } from "react";
import FolderIconPurple_Closed from "assets/icons/folder_ljub_svi.svg";
import FolderIconPurple_Open from "assets/icons/folder_ljub_open.svg";
import FolderIconPurple_Hover from "assets/icons/folder_ljub_hover.svg";
import FolderIconBlue_Closed from "assets/icons/folder_plavi_svijetli.svg";
import FolderIconBlue_Open from "assets/icons/folder_plavi_open.svg";
import FolderIconBlue_Hover from "assets/icons/folder_plavi_hover.svg";
import FolderIconYellow_Closed from "assets/icons/folder_zuti_svijetli.svg";
import FolderIconYellow_Open from "assets/icons/folder_zuti_open.svg";
import FolderIconYellow_Hover from "assets/icons/folder_zuti_hover.svg";
import { formatDate } from "sdk/common/helpers";

export const folderIcons = {
  purple: {
    open: FolderIconPurple_Open,
    closed: FolderIconPurple_Closed,
    hover: FolderIconPurple_Hover,
  },
  blue: {
    open: FolderIconBlue_Open,
    closed: FolderIconBlue_Closed,
    hover: FolderIconBlue_Hover,
  },
  yellow: {
    open: FolderIconYellow_Open,
    closed: FolderIconYellow_Closed,
    hover: FolderIconYellow_Hover,
  },
};

type TProps = {
  folders: {
    id: string;
    name: string;
    lastUpdated: Date;
    color?: "purple" | "blue" | "yellow" | string | undefined | null;
  }[];
  selectedFolderId?: string;
  onFolderSelected: (id: string) => void;
};

export const FolderSwitcher = (props: TProps) => {
  const { folders, selectedFolderId, onFolderSelected } = props;
  return (
    <div className="flex w-60 flex-col p-2 pl-6">
      <h1 className="p-0 text-2xl text-blue-700">Mape</h1>

      <nav className="flex flex-col gap-6 overflow-auto p-2 pb-8">
        {folders.map((f) => (
          <FolderCard
            onClick={() => onFolderSelected(f.id)}
            key={f.id}
            folder={f}
            selected={f.id === selectedFolderId}
          />
        ))}
      </nav>
    </div>
  );
};

const FolderCard = (props: {
  folder: {
    id: string;
    name: string;
    color?: string | undefined | null;
    lastUpdated: Date;
  };
  selected: boolean;
  onClick: () => void;
}) => {
  const { folder, selected, onClick } = props;
  const { color = "yellow" } = folder;
  const imgRef = useRef<HTMLImageElement>(null);
  const handleMouseOver: MouseEventHandler<HTMLImageElement> = (e) => {
    if (selected) return;
    imgRef.current?.setAttribute("src", folderIcons[color as string].hover);
  };
  const handleMouseOut: MouseEventHandler<HTMLImageElement> = (e) => {
    if (selected) return;
    imgRef.current?.setAttribute("src", folderIcons[color as string].closed);
  };

  return (
    <div
      className={`flex w-48 cursor-pointer flex-col gap-1 rounded-2xl p-2 pt-4 pb-4 ${
        selected ? "bg-[#ffffff] shadow-table-row" : "bg-[#fdfaffcc]"
      } hover:shadow-table-row`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
    >
      <img
        src={
          selected
            ? folderIcons[color as string].open
            : folderIcons[color as string].closed
        }
        ref={imgRef}
        width={80}
        height="auto"
      />
      <div
        className={`pl-1 text-lg ${
          selected ? "font-bold text-blue-600" : "font-medium text-blue-500"
        }`}
      >
        {folder.name}
      </div>
      <div className="flex flex-row pl-1 text-xs">
        <span className="mr-1 text-blue-500">Zadnja izmjena:</span>
        <span className="font-semibold text-blue-700">
          {formatDate.toDateString(folder.lastUpdated)}
        </span>
      </div>
    </div>
  );
};
