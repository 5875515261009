import { z } from "zod";
export const sportUpsertSchema = z.object({
    id: z.string().min(3),
    orgId: z.string().min(1),
    name: z.string().min(1, "Obvezan podatak"),
    sportsAssociation: z.string().nullish(),
    AgeGroups: z
        .array(z.object({
        id: z.string(),
        name: z.string().min(3),
        ageFrom: z.string().optional(),
        ageTo: z.string().optional(),
    }))
        .nullish(),
});
