import "dayjs/locale/hr";
import { TextInput, Button } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useNavigate } from "react-router-dom";

import { SelectEmploymentType } from "sdk/components/Selects/SelectEmploymentType";
import { SelectEmployeeQualification } from "sdk/components/Selects/SelectEmployeeQualification";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { RadioGender } from "sdk/components/Radio/RadioGender";
import { formSubmitErrorHandler, notifications } from "sdk/common/helpers";
import { FormSectionContainer } from "sdk/components/FormSectionContainer";
import dayjs from "dayjs";
import { coachUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";
import { TbTrash } from "react-icons/tb";
import { openDeleteDialog } from "sdk/common/hooks";

function diff_year_month_day(dt1, dt2) {
  const date1 = dayjs(dt2);
  const date2 = dayjs(dt1);
  const years = date2.diff(date1, "years");
  const date3 = date1.add(years, "years");
  const days = date2.diff(date3, "days");
  return years + " godina/e" + " i " + days + " dan(a)";
}

export const CoachDataEntryForm = ({ initialValues, isUpdate = false }) => {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const coachUpsert = trpc.coach_upsert.useMutation({
    onSuccess: (data) => {
      utils.member_find.invalidate({ id: data.id });
      utils.coach_findMany.invalidate();
      navigate(`/pregled/klubovi/${data.orgId}/treneri`);
    },
  });

  const deleteMember = trpc.member_delete.useMutation({
    onSuccess: (data) => {
      notifications.success("Uspješno ste obrisali trenera");
      utils.member_find.invalidate({ id: data.id });
      utils.coach_findMany.invalidate();
      navigate(`/pregled/klubovi/${data.orgId}/treneri`);
    },
  });

  const form = useForm({
    initialValues,
    validate: zodResolver(coachUpsertSchema),
  });

  return (
    <ContentContainer className="h-full">
      <div className="mx-auto flex h-full max-w-[1400px] gap-16 border-4  p-8">
        <div className="p-4">
          <div className="font-bold">OSNOVNI PODACI</div>
          <div>
            <ProfilePicture
              recordId={initialValues.id as string}
              avatarRecordFileId={form.values?.avatarRecordFileId as string}
              onChange={(newRecordFileId) =>
                form.setFieldValue("avatarRecordFileId", newRecordFileId)
              }
            />
          </div>
        </div>

        <form
          className="flex flex-1 gap-6 "
          onSubmit={form.onSubmit(
            (values) => coachUpsert.mutate(values),
            formSubmitErrorHandler()
          )}
        >
          <div className="flex flex-1  flex-col gap-6 overflow-auto px-4">
            <FormSectionContainer className="mt-4">
              <TextInput
                {...form.getInputProps("firstName")}
                withAsterisk
                label="Ime"
                autoFocus
                className="w-full"
              />
              <TextInput
                withAsterisk
                label="Prezime"
                name="x-field-lastName"
                {...form.getInputProps("lastName")}
                className="w-full"
              />
              <TextInput
                label="OIB"
                {...form.getInputProps("oib")}
                className="w-full"
                withAsterisk
                onChange={(e) =>
                  /^[0-9]{0,11}$/.test(e.target.value) &&
                  form.setFieldValue("oib", e.target.value)
                }
              />
              <DatePicker
                {...form.getInputProps("dateOfBirth")}
                locale="hr"
                label="Datum rođenja"
                withAsterisk
                className="w-full"
                maxDate={dayjs().toDate()}
              />
              <RadioGender withAsterisk {...form.getInputProps("gender")} />
            </FormSectionContainer>
            <FormSectionContainer>
              <TextInput
                label="E-mail"
                name="x-field-email"
                {...form.getInputProps("email")}
                className="w-full"
              />
              <TextInput
                label="Telefonski broj"
                {...form.getInputProps("phoneNumber")}
                sx={{ width: "auto" }}
              />
              <SelectEmploymentType
                withAsterisk
                {...form.getInputProps("coachEmploymentTypeId")}
                className="w-full"
              />
              <SelectEmployeeQualification
                withAsterisk
                {...form.getInputProps("employeeQualificationId")}
                className="w-full"
              />
              <TextInput
                {...form.getInputProps("workLocation")}
                label="Mjesto rada"
                className="w-full"
              />
              <TextInput
                {...form.getInputProps("license")}
                label="Licenca"
                className="w-full"
              />
              <TextInput
                {...form.getInputProps("nationalSelectionName")}
                label="Nacionalna selekcija"
                className="w-full"
              />
            </FormSectionContainer>
            <FormSectionContainer className="mb-4">
              <div className="flex flex-col gap-1">
                <DatePicker
                  {...form.getInputProps("workStartDate")}
                  locale="hr"
                  label="Datum početka radnog staža"
                  maxDate={new Date()}
                />
                {form.values.workStartDate ? (
                  <span className="text-blue-600">
                    {diff_year_month_day(new Date(), form.values.workStartDate)}
                  </span>
                ) : null}
              </div>
              <TextInput
                {...form.getInputProps("ageGroups")}
                label="Uzrasne kategorije"
                className="w-full"
              />
            </FormSectionContainer>
          </div>
          <div className="flex flex-col justify-end gap-4 p-4">
            {isUpdate && (
              <>
                <button
                  type="button"
                  onClick={() =>
                    openDeleteDialog({
                      onAccept() {
                        deleteMember.mutate({ id: form.values.id });
                      },
                      titleText: "Izbriši trenera",
                      contentText: "Potvrdom čete izbrisati trenera",
                    })
                  }
                  className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-pink-500"
                >
                  <TbTrash size={22} />
                  <div> Izbriši trenera</div>
                </button>
                <div className="h-px bg-blue-400" />
              </>
            )}

            <Button
              tabIndex={-1}
              sx={{ minWidth: 190 }}
              variant="outline"
              onClick={() => navigate(-1)}
              disabled={coachUpsert.isLoading}
            >
              Odustani
            </Button>
            <Button
              type="submit"
              sx={{ minWidth: 190 }}
              loading={coachUpsert.isLoading}
            >
              Spremi
            </Button>
          </div>
        </form>
      </div>
    </ContentContainer>
  );
};
