import React from "react";
import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { trpc } from "utils/trpc";
import { useGetSelectedOrg } from "sdk/common/hooks";

export const SelectAgeGroup = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        sportId?: string;
      },
    ref: any
  ) => {
    const { hideLabel, ...rest } = props;

    const org = useGetSelectedOrg();

    const sportId = props.sportId || org?.sportId;

    const { data, isLoading, error } = trpc.ageGroup_findMany.useQuery({
      sportId: sportId || undefined,
    });

    if (isLoading) return <Loader />;
    if ((!isLoading && !data) || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Uzrasna kategorija"}
        label={hideLabel ? undefined : "Uzrasna kategorija"}
        {...rest}
        data={
          data
            ?.filter((item) => item.sportId === sportId)
            .map((item) => ({
              value: (item as any).id,
              label: `${item.ageFrom || ""}-${item.ageTo || ""} ${
                (item as any).name
              } `,
            })) || []
        }
      />
    );
  }
);

SelectAgeGroup.displayName = "SelectAgeGroup";
