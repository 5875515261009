import {
  ActionIcon,
  Loader,
  Modal,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { MatchUpsertSchema } from "models";
import React from "react";
import { IoMdCreate } from "react-icons/io";
import { MdOutlineAddCircle } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate, notifications } from "sdk/common/helpers";
import {
  openDeleteDialog,
  useBreakpoints,
  useSetTitle,
} from "sdk/common/hooks";
import { ulid } from "ulid";
import { trpc } from "utils/trpc";
import { MatchNewForm } from "./components/MatchNewForm";
import { ContentContainer } from "sdk/components/ContentContainer";
import { Label } from "sdk/components/Label";

// const WebcamComponent = () => <Webcam />;

export const DetaljiNatjecanja = () => {
  useSetTitle("Detalji natjecanja", { showBackButton: true });

  const { id, orgId } = useParams();
  const { pathname } = useLocation();
  const { isLg } = useBreakpoints();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const [match, setMatch] = React.useState<MatchUpsertSchema | undefined>();

  const {
    data: competition,
    isLoading,
    isError,
    refetch,
  } = trpc.competition_find.useQuery({ id: id! });

  const matchDelete = trpc.match_delete.useMutation({
    onSuccess: () => {
      notifications.success();
      refetch();
    },
  });

  if (isLoading) return <Loader mx="auto" size="lg" />;
  if (isError || !competition) return <div>error</div>;

  return (
    <>
      <ContentContainer className="h-full">
        <div className="mx-auto flex h-full max-w-[1400px] flex-col gap-8 overflow-auto p-8">
          <div className="relative flex flex-col gap-2">
            <NavLink
              to={pathname.replace("detalji", "uredi")}
              className={`absolute top-0 right-0 flex h-12 w-12 translate-x-1/3 -translate-y-1/3 items-center justify-center rounded-full bg-white
                        shadow-button
                        hover:shadow-button-hover
                        active:shadow-button-active
            ${isLg ? "visible" : "collapse"}`}
            >
              <IoMdCreate size={30} color={theme.colors.blue[9]} />
            </NavLink>

            <div className="flex flex-col gap-8 rounded-xl bg-blue-100 p-4 ">
              <div className="flex gap-16">
                <div>
                  <Label>Naziv natjecanja</Label>
                  <div>{competition?.name}</div>
                </div>
                <div>
                  <Label>Vrsta natjecanja</Label>
                  <div>{competition?.CompetitionType?.name}</div>
                </div>
                <div>
                  <Label>Dobna skupina</Label>
                  <div>{competition.AgeGroup?.name}</div>
                </div>

                <div>
                  <Label>Početak natjecanja</Label>
                  <div>
                    {formatDate.toDateString(competition.startDate) || "-"}
                  </div>
                </div>
                <div>
                  <Label>Kraj natjecanja</Label>
                  <div>
                    {formatDate.toDateString(competition.endDate) || "-"}
                  </div>
                </div>
              </div>

              <div>
                <Label>
                  {competition.CompetitionPeriods?.length
                    ? "Dijelovi natjecanja"
                    : "Nema dodanih djelova natjecanja"}
                </Label>

                {competition?.CompetitionPeriods?.map((item) => (
                  <div
                    key={item.id}
                    className="grid grid-cols-[200px_200px_200px] gap-2 rounded-xl bg-blue-100 p-2"
                  >
                    <div className="truncate">{item.name}</div>
                    <div>
                      od {formatDate.toDateString(item.startDate) || "-"}
                    </div>
                    <div>do {formatDate.toDateString(item.endDate) || "-"}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <Label>Susreti</Label>
              <div
                className="flex cursor-pointer items-center gap-2"
                onClick={() => {
                  setMatch({
                    orgId: orgId!,
                    competitionId: id!,
                    id: ulid(),
                    name: "",
                    location: "",
                    startDate: new Date(),
                    side: "",
                  });
                  //
                }}
              >
                <MdOutlineAddCircle
                  style={{ color: "rgb(120, 169, 247)" }}
                  size={24}
                />
                <Label>Dodaj susret</Label>
              </div>
            </div>

            <div className="mt-2 flex flex-col gap-1">
              <div className="grid grid-cols-[2fr_2fr_1fr_2fr_2fr_2fr_1fr]  gap-4  rounded-t-md bg-blue-450 px-4 py-1 font-semibold">
                <div>naziv</div>
                <div>lokacija</div>
                <div>strana</div>
                <div>datum početka</div>
                <div>vrijeme početka</div>
                <div>period</div>
                <div />
              </div>
              {competition.CompetitionMatches?.map((item, index) => (
                <div
                  key={item?.id}
                  className={`grid grid-cols-[2fr_2fr_1fr_2fr_2fr_2fr_1fr] gap-4 bg-[rgba(253,250,255,0.8)] py-2 px-4 text-blue-700 ${
                    index === competition.CompetitionMatches?.length - 1
                      ? "rounded-b-md"
                      : ""
                  } cursor-pointer transition-all hover:bg-white hover:shadow-documents-table-row`}
                  onClick={() => navigate(`susret/${item.id}`)}
                >
                  <div>{item?.name}</div>
                  <div>{item?.location}</div>
                  <div>{item?.side}</div>
                  <div>{formatDate.toDateString(item?.startDate)}</div>
                  <div>{formatDate.toTimeString(item?.startTime)}</div>
                  <div>{item.CompetitionPeriod?.name}</div>
                  <ActionIcon
                    className=""
                    color="pink"
                    variant="light"
                    radius="xl"
                    size="lg"
                    disabled={competition.CompetitionPeriods?.length === 0}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteDialog({
                        onAccept() {
                          matchDelete.mutate({ id: item.id! });
                        },
                        titleText: "Izbriši susret",
                        contentText: "Potvrdom čete izbrisati susret",
                      });
                      //
                    }}
                  >
                    <TbTrash />
                  </ActionIcon>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ContentContainer>

      {!!match && (
        <Modal
          opened={!!match}
          onClose={() => setMatch(undefined)}
          title={
            <Text size="lg" weight="bold">
              Novi susret
            </Text>
          }
          styles={{
            inner: { paddingTop: "5%" },
          }}
          size="md"
        >
          <MatchNewForm match={match} onClose={() => setMatch(undefined)} />
        </Modal>
      )}
    </>
  );
};
