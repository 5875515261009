import "dayjs/locale/hr";
import { useForm, zodResolver } from "@mantine/form";
import { Box, Button, TextInput, Textarea } from "@mantine/core";

import { DatePicker } from "@mantine/dates";
import { RecordFiles } from "sdk/components/RecordFiles";
import { useNavigate, useParams } from "react-router-dom";
import { TbTrash } from "react-icons/tb";

import { meetingUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { notifications } from "sdk/common/helpers";
import { openDeleteDialog } from "sdk/common/hooks";
import { CardContainer } from "../../clanovi/components";

export const EditMeetingForm = ({ initialValues }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const formHandler = useForm({
    initialValues,
    validate: zodResolver(meetingUpsertSchema),
  });

  const utils = trpc.useContext();

  const meetingDelete = trpc.meeting_delete.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.meeting_findMany.invalidate({ orgId });
      utils.meeting_find.invalidate({ id: initialValues.id });
      navigate(`/pregled/klubovi/${orgId}/sjednice`);
    },
  });

  const meetingUpsert = trpc.meeting_upsert.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.meeting_findMany.invalidate({ orgId });
      utils.meeting_find.invalidate({ id: initialValues.id });
      navigate(`/pregled/klubovi/${orgId}/sjednice`);
    },
  });

  return (
    <>
      <form
        onSubmit={formHandler.onSubmit(
          (values) => meetingUpsert.mutate(values),
          (error) => console.log({ error })
        )}
        className="mx-auto flex h-full max-w-[1400px] p-8"
      >
        <CardContainer className="flex-1 overflow-hidden ">
          <DatePicker
            label="Datum"
            withAsterisk
            locale="hr"
            {...formHandler.getInputProps("date")}
          />
          <TextInput
            label="Lokacija"
            withAsterisk
            {...formHandler.getInputProps("location")}
          />
          <Textarea
            label="Bilješke"
            withAsterisk
            sx={{ width: "auto" }}
            {...formHandler.getInputProps("notes")}
            minRows={5}
          />
          <Box sx={{ marginTop: 32 }}>
            <RecordFiles recordId={formHandler.values.id!} isReadOnly={false} />
          </Box>
        </CardContainer>
        <div className="flex flex-col justify-end gap-4 px-8 py-4">
          <button
            type="button"
            onClick={() =>
              openDeleteDialog({
                onAccept() {
                  meetingDelete.mutate({ id: formHandler.values.id });
                },
                titleText: "Izbriši sjednicu",
                contentText: "Potvrdom čete izbrisati sjednicu",
              })
            }
            className="flex cursor-pointer items-end gap-2 rounded-2xl border-0 bg-transparent text-blue-700 hover:text-pink-500"
          >
            <TbTrash size={22} />
            <div>Izbriši sjednicu</div>
          </button>

          <div className="h-px bg-blue-400" />

          <Button
            sx={{ minWidth: 190 }}
            variant="outline"
            onClick={() => {
              formHandler.validate();
              navigate(-1);
            }}
          >
            Odustani
          </Button>
          <Button
            sx={{ minWidth: 190 }}
            type="submit"
            loading={meetingUpsert.isLoading}
          >
            Spremi
          </Button>
        </div>
      </form>
    </>
  );
};
