type Props = {
  className?: string;
  children: React.ReactNode;
};
export const FormSectionContainer = (props: Props) => {
  const { className } = props;
  return (
    <div
      className={`flex flex-col gap-4 rounded-2xl bg-card-background p-8 focus-within:shadow-table-row hover:shadow-table-row ${
        className || ""
      }`}
    >
      {props.children}
    </div>
  );
};
