import { MantineThemeOverride } from "@mantine/core";
import dayjs from "dayjs";

export const theme: MantineThemeOverride = {
  globalStyles: (theme) => ({
    fontFamily: `Satoshi-Variable, Satoshi-VariableItalic, Satoshi-Light, Satoshi-LightItalic, Satoshi-Regular, Satoshi-Italic, Satoshi-Medium, Satoshi-MediumItalic, Satoshi-Bold, Satoshi-BoldItalic, Satoshi-Black, Satoshi-BlackItalic, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,

    "::-webkit-scrollbar": {
      width: "10px",
      height: "12px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: theme.radius.xl,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.blue[5],
      borderRadius: theme.radius.xl,
    },
  }),
  colorScheme: "light",
  fontFamily: `Satoshi-Variable, Satoshi-VariableItalic, Satoshi-Light, Satoshi-LightItalic, Satoshi-Regular, Satoshi-Italic, Satoshi-Medium, Satoshi-MediumItalic, Satoshi-Bold, Satoshi-BoldItalic, Satoshi-Black, Satoshi-BlackItalic, Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  colors: {
    blue: [
      "#FAFBFF", // 0
      "#F4F5FE", // 1
      "#ECEDF6", // 2
      "#D1DCEB", // 3
      "#97A8BF", // 4
      "#617A9E", // 5
      "#284a80", // 6
      "#1a3560", // 7
      "#142540", // 8
      "#0a1220", // 9
    ],
    shadow: ["#32589528"],
  },
  shadows: {
    // md: "0 0px 2px 0px rgba(50,88,149,.4)",
    // lg: "0 0 4px 2px rgba(50,88,149,.4)",
    xl: "rgb(50 88 149 / 30%) 0px 8px 12px 0px",
  },
  components: {
    // ActionIcon: {
    //   styles: (theme, params) => ({
    //     root: {
    //       boxShadow: "0px 2px 4px 0px rgba(50, 88, 149, 0.4);",
    //       backgroundColor: " rgba(255, 255, 255, 0.75);",
    //       color: "rgb(97,122,158);",
    //       ":hover": {
    //         color: "rgb(26,53,96);",
    //         boxShadow: "0px 8px 12px 0px rgba(50, 88, 149, 0.4);",
    //         backgroundColor: "#ffffff;",
    //       },
    //       ":active": {
    //         boxShadow: "0px 2px 4px 0px rgba(50, 88, 149, 0.4);",
    //       },
    //     },
    //   }),
    // },
    Title: {
      styles: (theme, params) => ({
        root: {
          color: params.color || theme.colors.blue[5],
        },
      }),
    },
    Text: {
      styles: (theme, params) => ({
        root: {
          color: params.color || theme.colors.blue[7],
        },
      }),
    },
    Button: {
      styles: (theme, params) => ({
        root: {
          "&[data-loading]::before": {
            background: "transparent",
          },
          borderRadius: theme.radius.xl,
          paddingRight: theme.spacing.xl,
          paddingLeft: theme.spacing.xl,
          height: 40,
          minWidth: 160,
          fontSize: 16,
          color: params.variant === "outline" ? "rgb(97,122,158)" : "white",
          backgroundColor:
            params.variant === "outline"
              ? "rgba(255, 255, 255, 0.75)"
              : "#284a80",
          border: 0,
          "&:hover":
            params.variant === "outline"
              ? {
                  boxShadow: theme.other.shadows.button.hover,
                  backgroundColor: "white;",
                  color: "rgb(26,53,96)",
                }
              : {
                  boxShadow: theme.other.shadows.button.hover,
                  backgroundColor: "#1a3560;",
                },
          "&:active":
            params.variant === "outline"
              ? {
                  boxShadow: theme.other.shadows.button.default,
                  backgroundColor: "white;",
                }
              : {
                  boxShadow: theme.other.shadows.button.default,
                  backgroundColor: "#284a80;",
                },
          boxShadow: theme.other.shadows.button.default,
        },
      }),
    },
    Radio: {
      styles: (theme, params) => ({
        label: {
          color: theme.colors.blue[5],
        },
        description: {
          color: theme.colors.blue[5],
        },
      }),
    },
    TextInput: {
      defaultProps: {
        autoComplete: "off",
      },
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },
        label: {
          color: theme.colors.blue[5],
        },
        input: {
          borderRadius: theme.radius.xl,
          color: theme.colors.blue[7],
          height: 40,
          lineHeight: 1.4,
          transition: "all .2s",
          fontSize: 16,
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          ":disabled": {
            color: "#617A9E",
            opacity: 1,
          },
        },
      }),
    },
    NumberInput: {
      defaultProps: {
        autoComplete: "off",
      },
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },
        label: {
          color: theme.colors.blue[5],
        },
        input: {
          borderRadius: theme.radius.xl,
          color: theme.colors.blue[7],
          height: 40,
          lineHeight: 1.4,
          fontSize: 16,
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          ":disabled": {
            color: "#617A9E",
            opacity: 1,
          },
        },
      }),
    },
    PasswordInput: {
      defaultProps: {
        autoComplete: "off",
      },
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },

        label: {
          color: theme.colors.blue[5],
        },
        input: {
          height: 40,
          borderRadius: theme.radius.xl,
          fontSize: 16,
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
        },
        innerInput: {
          color: theme.colors.blue[7],
          height: 40,
          fontSize: 16,
          lineHeight: 40,
        },
      }),
    },
    Select: {
      defaultProps: {
        clearButtonTabIndex: -1,
        clearable: true,
      },
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },
        label: {
          color: theme.colors.blue[5],
        },
        dropdown: {
          borderRadius: theme.radius.lg,
        },
        item: {
          borderRadius: theme.radius.xl,
        },
        input: {
          height: 40,
          color: theme.colors.blue[6],
          borderRadius: theme.radius.xl,
          fontSize: 16,
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          ":disabled": {
            color: "#617A9E",
            opacity: 1,
          },
        },
      }),
    },
    DatePicker: {
      defaultProps: {
        locale: "hr",
        clearButtonTabIndex: -1,
        inputFormat: "DD. MM. YYYY.",
        labelFormat: "DD. MM. YYYY.",
        allowFreeInput: true,
        initialLevel: "year",
        dateParser: (dateString) => {
          const parts = /(\d{1,2})\D(\d{1,2})\D(\d{2,4})/;
          const [_, day, month, year] = Array.from(
            parts.exec(dateString) || []
          );
          return new Date(
            Date.parse(`${Math.min(12, +month)}.${Math.min(31, +day)}.${year}`)
          );
        },
      },
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },
        label: {
          color: theme.colors.blue[5],
        },
        input: {
          height: 40,
          borderRadius: theme.radius.xl,
          fontSize: 16,
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          ":disabled": {
            color: "#617A9E",
            opacity: 1,
          },
        },
      }),
    },
    TimeInput: {
      styles: (theme, params) => ({
        root: { width: "min(100%, 316px)" },
        label: {
          color: theme.colors.blue[5],
        },
        input: {
          borderRadius: theme.radius.xl,
          color: theme.colors.blue[7],
          height: 40,
          lineHeight: 1.4,
          transition: "all .2s",
          fontSize: 16,
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus-within, :hover": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          ":disabled": {
            color: "#617A9E",
            opacity: 1,
          },
        },
      }),
    },
    Textarea: {
      styles: (theme, params) => ({
        input: {
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",
          fontSize: 16,
          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
          borderRadius: theme.radius.lg,
        },
      }),
    },
    RadioGroup: {
      styles: (theme, params) => ({
        label: { color: theme.colors.blue[5] },
      }),
    },
    Modal: {
      defaultProps: (theme) => ({
        overlayColor: theme.other.overlayColor,
      }),
      styles: (theme, params) => ({
        inner: { paddingTop: "12%" },
      }),
    },
    RichTextEditor: {
      defaultProps: {
        controls: [
          ["bold", "italic", "underline", "link"],
          ["orderedList", "unorderedList"],
          ["h1", "h2", "h3"],
          ["alignLeft", "alignCenter", "alignRight"],
        ],
      },
      styles: (theme, params) => ({
        root: {
          transition: "all .2s",
          border: "1px solid rgba(151, 168, 191, 1)",
          background: "rgba(255, 255, 255, 0.5)",

          ":focus:enabled, :hover:enabled": {
            border: "4px solid rgba(130, 171, 237, 1)",
            // paddingLeft: theme.spacing.md,
            background: "white",
          },
        },
      }),
    },
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 46,
  },
  radius: {
    xl: 62,
  },
  other: {
    shadows: {
      button: {
        default: "0px 2px 4px 0px rgba(50, 88, 149, 0.4)",
        hover: "0px 8px 12px 0px rgba(50, 88, 149, 0.4)",
      },
    },
    overlayColor: "rgba(50,88,140,.3)",
    sidebarFilterBackground:
      "linear-gradient(rgba(236, 237, 246, 0.7) 0%, rgb(231, 239, 253) 100%)",
  },
  // globalStyles: (theme) => ({
  //   "*, *::before, *::after": {
  //     boxSizing: "border-box",
  //   },
  // }),
};
