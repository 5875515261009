import {
  Group,
  Stack,
  Button,
  Text,
  useMantineTheme,
  Modal,
  ActionIcon,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React from "react";
import { MdEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { formatDate } from "sdk/common/helpers";
import { ulid } from "ulid";
import { CardContainer, TabPanelContainer } from ".";
import { useForm, zodResolver } from "@mantine/form";
import { categoryUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import RichTextEditor from "@mantine/rte";
import { assert } from "sdk/assert";
import { SelectMemberCategory } from "sdk/components/Selects/SelectMemberCategory";
import { openDeleteDialog } from "sdk/common/hooks";

export const TabPanelCategory = () => {
  const { id, orgId } = useParams();
  assert(id);

  const { data: member, refetch } = trpc.member_find.useQuery({ id });
  const theme = useMantineTheme();

  const deleteCategory = trpc.member_categoryDelete.useMutation({
    onSuccess: () => {
      refetch();
    },
  });

  const [selectedItem, setSelectedItem] = React.useState<any>();

  if (!member) return null;

  const items = member.MemberCategories;
  const isEdit = !!items?.find((item) => item.id === selectedItem?.id);

  return (
    <>
      <TabPanelContainer
        addButtonTitle={"Dodaj novu kategoriju"}
        addButtonOnClick={() =>
          setSelectedItem({
            id: ulid(),
            orgId,
            memberId: id,
            dateFrom: new Date(),
            dateTo: null,
            description: "",
          })
        }
      >
        <CardContainer>
          <Text>Povijest kategorizacije</Text>
          <Stack spacing="xs" sx={{ position: "relative" }}>
            {items?.map((item, index) => (
              <div className="flex gap-8" key={index}>
                <div
                  className="flex grow cursor-pointer justify-between rounded-lg bg-blue-200 p-2 transition-all hover:rounded-2xl hover:bg-blue-300"
                  key={item.id}
                  onClick={() => setSelectedItem({ ...item })}
                >
                  <Text>
                    {formatDate.toDateString(item.dateFrom)}
                    {"\t-\t"}
                    {formatDate.toDateString(item.dateTo)}
                  </Text>
                  <Text>{item.categoryNo || "-/-"}</Text>
                </div>
                <div className="flex gap-4 px-2">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    radius="xl"
                    size="xl"
                    onClick={() => setSelectedItem(item)}
                  >
                    <MdEdit />
                  </ActionIcon>
                  <ActionIcon
                    color="pink"
                    variant="light"
                    radius="xl"
                    size="xl"
                    disabled={items.length < 2}
                    onClick={() =>
                      items.length > 1 &&
                      openDeleteDialog({
                        onAccept() {
                          deleteCategory.mutate({
                            id: item.id,
                            memberId: id,
                          });
                        },
                        titleText: "Izbriši  kategoriju",
                        contentText: "Potvrdom čete izbrisati kategoriju",
                      })
                    }
                  >
                    <TbTrash />
                  </ActionIcon>
                </div>
              </div>
            ))}
          </Stack>
        </CardContainer>
      </TabPanelContainer>

      <Modal
        overlayOpacity={0.2}
        opened={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title={isEdit ? "Izmjena podataka" : "Nova kategorija"}
        styles={{
          modal: { width: `min(100%, ${theme.breakpoints.sm}px)` },
          title: { fontWeight: "bold" },
        }}
      >
        <CategoryUpsert
          category={selectedItem}
          onClose={() => setSelectedItem(undefined)}
        />
      </Modal>
    </>
  );
};

const CategoryUpsert = ({ category, onClose }) => {
  const utils = trpc.useContext();
  const categoryUpsert = trpc.member_categoryUpsert.useMutation({
    onSuccess: () => {
      utils.member_find.invalidate({ id: category.memberId });
      onClose();
    },
  });

  const form = useForm({
    validate: zodResolver(categoryUpsertSchema),
    initialValues: category,
  });

  return (
    <form
      onSubmit={form.onSubmit(
        (values) => categoryUpsert.mutate(values),
        (err) => console.log({ err })
      )}
    >
      <Stack>
        <Group align="flex-start" noWrap>
          <SelectMemberCategory
            {...form.getInputProps("categoryNo")}
            valuesOnly
          />
          <DatePicker
            withAsterisk
            label="Početak"
            {...form.getInputProps("dateFrom")}
            maxDate={form.values.dateTo}
          />
          <DatePicker
            // withAsterisk
            label="Kraj"
            {...form.getInputProps("dateTo")}
            minDate={form.values.dateFrom}
          />
        </Group>
        <RichTextEditor {...form.getInputProps("description")} />
        <Group position="right">
          <Button
            disabled={categoryUpsert.isLoading}
            variant="outline"
            onClick={onClose}
            type="button"
          >
            Odustani
          </Button>
          <Button
            loading={categoryUpsert.isLoading}
            disabled={categoryUpsert.isLoading}
            type="submit"
          >
            Spremi
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
