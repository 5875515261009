import { IoMdCalendar, IoMdCreate, IoMdMail, IoMdPerson } from "react-icons/io";
import { Link, NavLink, useParams } from "react-router-dom";
import { assert } from "sdk/assert";
import { useSetTitle } from "sdk/common/hooks";
import { formatDate } from "sdk/common/helpers";
import { Spinner } from "sdk/components/Spinner";
import { IoCall, IoGlobeOutline, IoLocation } from "react-icons/io5";
import { BsFillAwardFill } from "react-icons/bs";
import { AiFillBank } from "react-icons/ai";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";
import { orgLinks } from "../../../../../layout/Sidebar/ActiveOrgSidebar";

export const DashboardKluba = () => {
  const { orgId } = useParams();
  useSetTitle("");
  assert(orgId);

  const {
    data: organization,
    isLoading,
    isError,
  } = trpc.organization_find.useQuery({ id: orgId! });

  if (isLoading) return <Spinner />;
  if (!organization || isError) return <div>Greška</div>;

  return (
    <div className="mx-auto flex h-full max-w-max flex-col gap-8 overflow-auto p-8">
      <div className="flex gap-8 ">
        <ContentContainer className="relative flex flex-1 flex-col gap-8 p-6">
          <NavLink
            to={"uredi"}
            className={`absolute top-0 right-0 flex h-12 w-12 translate-x-1/3 -translate-y-1/3 items-center justify-center rounded-full bg-white
                        shadow-button
                        hover:shadow-button-hover
                        active:shadow-button-active`}
          >
            <IoMdCreate size={30} className="text-blue-800" />
          </NavLink>
          {/* {organization.avatarRecordFileId ? (
            <img
              src={getUrlFromRecordFileId(organization.avatarRecordFileId)}
              className="h-20 w-20 rounded-lg"
            />
          ) : null} */}
          <div className="flex flex-row gap-4">
            <div className="flex flex-col">
              <span className="text-4xl font-semibold ">
                {organization.name}
              </span>
              <span className="font-semibold ">{organization.shortName}</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 rounded-3xl bg-blue-700 p-8 text-zinc-50">
            <div className="flex  flex-1 flex-row items-center gap-4 ">
              <div className="flex items-center self-stretch rounded-xl bg-zinc-50 p-2 text-blue-700">
                <IoLocation size={20} />
              </div>
              <div className="flex flex-col gap-0 ">
                <span className="font-bold ">Adresa</span>
                <span className="">
                  {`${organization.address || "-"} ${
                    organization.houseNumber || ""
                  }`}
                </span>
                <span className="">{`${organization.zipCode || ""} ${
                  organization.city || ""
                }`}</span>
              </div>
            </div>
            <div className="flex  flex-1 flex-row items-center gap-4 ">
              <div className="flex items-center self-stretch rounded-xl bg-zinc-50 p-2 text-blue-700">
                <IoCall size={20} />
              </div>
              <div className="flex flex-col ">
                <span className="font-bold ">Telefon</span>
                <span className="">
                  {organization.phoneNumber ||
                    organization.authorizedPersonPhone ||
                    "-"}
                </span>
              </div>
            </div>
            <div className="flex  flex-1 flex-row items-center gap-4 ">
              <div className="flex items-center self-stretch rounded-xl bg-zinc-50 p-2 text-blue-700">
                <IoMdMail size={20} />
              </div>
              <div className="flex flex-col ">
                <span className="font-bold ">E-mail</span>
                <a
                  className="text-sm text-zinc-50 no-underline "
                  href={`mailto://${organization.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {organization.email}
                </a>
              </div>
            </div>
            <div className="flex  flex-1 flex-row items-center gap-4 ">
              <div className="flex items-center self-stretch rounded-xl bg-zinc-50 p-2 text-blue-700">
                <IoMdPerson size={20} />
              </div>
              <div className="flex flex-col ">
                <span className="font-bold ">Predsjednik/ca</span>
                <span className="">
                  {organization.authorizedPersonName || "-"}
                </span>
              </div>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="relative flex-1 p-6">
          <NavLink
            to={"uredi"}
            className={`absolute top-0 right-0 flex h-12 w-12 translate-x-1/3 -translate-y-1/3 items-center justify-center rounded-full bg-white
                        shadow-button
                        hover:shadow-button-hover
                        active:shadow-button-active`}
          >
            <IoMdCreate size={30} className="text-blue-800" />
          </NavLink>
          <div className="grid h-full grid-cols-3 items-start gap-4  rounded-3xl bg-white p-8">
            {/* <div
            className="hover: absolute top-2 right-3  cursor-pointer"
            onClick={() => {
              navigate(`/pregled/klubovi/${orgId}/detalji/uredi`);
            }}
          >
            <IoMdCreate size={24} />
          </div> */}

            <div className="flex gap-4 ">
              <AiFillBank size={24} className="shrink-0" />
              <div className="flex flex-col truncate">
                <div className="font-bold ">IBAN</div>
                <div className="text-sm ">{organization.bankName || "-"}</div>
                <div className="truncate text-sm">{organization.iban}</div>
              </div>
            </div>

            <div className="flex gap-4">
              <IoMdPerson size={24} className="shrink-0" />
              <div className="flex flex-col">
                <div className="font-bold ">Glavni tajnik/ca</div>
                <div className="text-sm ">
                  {organization.contactPersonName || "-"}
                </div>
                <a
                  className="text-sm text-blue-50 "
                  href={`mailto://${organization.contactPersonEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {organization.contactPersonEmail}
                </a>
                <div className="text-sm ">
                  {organization.contactPersonPhone || "-"}
                </div>
                <div className="text-sm ">{`Mandat: ${formatDate.toDateString(
                  organization.secretaryMandateStartDate
                )} - ${formatDate.toDateString(
                  organization.secretaryMandateEndDate
                )}`}</div>
              </div>
            </div>

            <div className="flex gap-4">
              <BsFillAwardFill size={24} className="shrink-0" />
              <div className="flex flex-col">
                <div className="font-bold ">Registracija</div>
                <div className="text-sm ">{`OIB: ${
                  organization.vatId || ""
                }`}</div>
                <div className="text-sm ">{`MB: ${organization.mb || ""}`}</div>
                <div className="text-sm ">{`RNO: ${
                  organization.rno || ""
                }`}</div>
                <div className="text-sm ">{`RB: ${organization.rb || ""}`}</div>
              </div>
            </div>

            <div className="col-span-3 h-[1px] bg-zinc-300" />

            <div className="flex gap-4">
              <IoGlobeOutline size={24} className="shrink-0" />
              <div className="flex flex-col">
                <div className="font-bold ">Web stranica</div>
                <a
                  className="text-sm text-blue-50 "
                  href={normalizeUrl(organization?.webAddress) || "-"}
                  target="_blank"
                  rel="noreferrer"
                >
                  {organization.webAddress || "-"}
                </a>
              </div>
            </div>

            <div className="flex gap-4">
              <IoMdPerson size={24} className="shrink-0" />
              <div className="flex flex-col">
                <div className="font-bold ">Predsjednik/ca</div>
                <div className="text-sm ">
                  {organization.authorizedPersonName || "-"}
                </div>
                <a
                  className="text-sm text-blue-50 "
                  href={`mailto://${organization.authorizedPersonEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {organization.authorizedPersonEmail}
                </a>
                <div className="text-sm ">
                  {organization.authorizedPersonPhone}
                </div>
                <div className="text-sm ">{`Mandat: ${formatDate.toDateString(
                  organization.mandateStartDate
                )} - ${formatDate.toDateString(
                  organization.mandateEndDate
                )}`}</div>
              </div>
            </div>

            <div className="flex gap-4">
              <IoMdCalendar size={24} className="shrink-0" />
              <div className="flex flex-col">
                <div className="font-bold ">Datumi</div>
                <div className="text-sm ">{`Osnivačka skupština: ${formatDate.toDateString(
                  organization.foundingAssemblyDate
                )}`}</div>
                <div className="text-sm ">{`Prijem u članstvo: ${formatDate.toDateString(
                  organization.membershipAdmissionDate
                )}`}</div>
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>

      <ContentContainer className="flex flex-wrap items-center justify-center gap-12 p-16">
        {orgLinks
          .filter((link) => link.title !== "Detalji")
          .map((link) => (
            <NavLink
              to={link.to.replace(":orgId", orgId)}
              className="relative flex h-40 w-40 items-center justify-center rounded-2xl bg-zinc-50 text-xl font-bold text-zinc-900 no-underline transition-all hover:shadow-[0px_8px_12px_0px_rgba(50,88,149,0.4)]"
              key={link.to}
            >
              {link.title}
              <div className="absolute top-0 flex h-1/3 w-1/3 -translate-y-1/2 items-center justify-center rounded-full bg-indigo-400 text-zinc-50">
                <link.Icon />
              </div>
            </NavLink>
          ))}
      </ContentContainer>
    </div>
  );
};
function normalizeUrl(
  webAddress: string | null | undefined
): string | undefined {
  if (!webAddress) return;
  if (webAddress.startsWith("http://") || webAddress.startsWith("https://"))
    return webAddress;
  return `http://${webAddress}`;
}

const InfoCard = (props) => {
  const { children, title, to } = props;
  return (
    <Link
      to={to}
      className="flex h-52 max-h-52 w-52 cursor-pointer flex-col items-center gap-3 overflow-hidden rounded-3xl bg-form-background p-2 text-lg text-blue-50
  
  hover:shadow-table-row  
  "
    >
      <div className="text-2xl">{title}</div>
      {children}
    </Link>
  );
};
