import React from "react";
import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { trpc } from "utils/trpc";
import { assert } from "sdk/assert";

export const SelectCoach = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        filterBySportId?: string;
      },
    ref: any
  ) => {
    const { hideLabel, filterBySportId, ...rest } = props;

    const { orgId } = useParams();

    assert(orgId);

    const {
      data: coaches,
      isLoading,
      isError,
    } = trpc.coach_findMany.useQuery({ orgId });

    if (isLoading) return <Loader />;
    if (!coaches || isError) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Trener"}
        label={hideLabel ? undefined : "Trener"}
        {...rest}
        data={coaches?.map((o) => ({
          value: o.id,
          label: o.firstName || "" + " " + o.lastName || "",
        }))}
      />
    );
  }
);

SelectCoach.displayName = "SelectCoach";
