import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Group,
  Stack,
  TextInput,
  Title,
  Text,
  Center,
  useMantineTheme,
  PasswordInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import szgz_background from "assets/login-screen-bkg.svg";
import szgz_mobile_background from "assets/SZGZ ilustracija mobilni.svg";
import szgzLogo from "assets/logo/szgz_text.png";
import { useBreakpoints } from "sdk/common/hooks";
import { Input } from "sdk/components/Input";
import { trpc } from "utils/trpc";
import { notifications } from "sdk/common/helpers";

export const LoginScreen = () => {
  const navigate = useNavigate();

  const theme = useMantineTheme();

  const { isLg } = useBreakpoints();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const utils = trpc.useContext();

  const userLogin = trpc.user_login.useMutation({
    onSuccess: (data) => {
      notifications.success("Prijava uspješna");
      utils.invalidate();
      navigate(
        data?.orgId?.toLowerCase() === "szgz"
          ? `/pregled`
          : `/pregled/klubovi/${data?.orgId}`
      );
    },
    onError: () => notifications.error("Prijava neuspješna"),
  });

  return (
    <form
      className="h-full w-full bg-main-gradient"
      style={{
        backgroundSize: "cover",
      }}
      onSubmit={form.onSubmit((values) => userLogin.mutate(values))}
    >
      {isLg ? (
        <Group
          style={{
            height: "100vh",
            maxHeight: "100vh",
            background: "transparent",
            backgroundSize: "cover",
            backgroundImage: `url(${szgz_background})`,
          }}
          spacing={0}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <img
              src={szgzLogo}
              style={{
                position: "absolute",
                top: 10,
                left: 10,
              }}
            />
          </Box>
          <Center
            sx={{
              flex: 1,
              height: "100vh",
              maxWidth: "960px",
              background:
                "linear-gradient(321deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%);",
            }}
            style={{
              backdropFilter: "blur(10px)",
            }}
          >
            <Stack
              sx={{
                borderRadius: "24px",
                backgroundColor: "white",
                height: "85%",
                width: "75%",
                paddingTop: "calc(7.22222%)",
                paddingBottom: "calc(7.22222%)",
                borderWidth: "1px",
                borderColor: "#FFFFFF",
                borderStyle: "solid",
                background: "#fdfaff;",
                boxShadow: "0px 8px 12px 0px rgba(50, 88, 149, 0.19);",
              }}
            >
              <Title
                sx={{
                  fontWeight: 400,
                  fontSize: "32px",
                  width: "78%",
                  alignSelf: "center",
                  fontFamily: "Satoshi-Bold",
                  color: "#1a3560",
                  letterSpacing: "-0.5px",
                  lineHeight: "1.2",
                }}
                align="center"
              >
                Dobrodošli u web aplikaciju Športske zajednice Grada Zadra!
              </Title>
              <Text align="center" sx={{ color: "#617A9E" }}>
                Molimo upišite svoju e-mail adresu i lozinku
              </Text>
              <Center
                sx={{
                  flex: 3,
                  width: "70%",
                  alignSelf: "center",
                }}
              >
                <Stack
                  spacing="md"
                  sx={{
                    flex: 1,
                  }}
                >
                  <TextInput
                    sx={{
                      width: "100%",
                      alignSelf: "center",
                      marginBottom: "2vh",
                    }}
                    autoFocus
                    label="E-mail"
                    {...form.getInputProps("email")}
                    placeholder="Upišite svoj E-mail"
                    styles={{
                      input: {
                        height: "50px",
                      },
                    }}
                    autoComplete="email"
                  />

                  <Input
                    className="h-[50px] w-full"
                    {...form.getInputProps("password")}
                    label="Lozinka"
                    type="password"
                    placeholder="Upišite lozinku"
                  />

                  {/* <Text
                    sx={{
                      fontSize: "14px",
                      marginTop: "16px",
                    }}
                    underline
                    align="end"
                  >
                    Zaboravili ste lozinku?
                  </Text> */}
                </Stack>
              </Center>
              <Box sx={{ flex: 1 }}></Box>
              <Button
                sx={{
                  alignSelf: "center",
                  width: "70%",
                  height: "50px",
                  fontSize: "20px",
                }}
                type="submit"
                loading={userLogin.isLoading}
              >
                Prijavi se
              </Button>
            </Stack>
          </Center>
        </Group>
      ) : (
        <Stack
          style={{
            height: "100vh",
            background:
              "linear-gradient(rgb(172, 184, 208) 0%, rgba(255, 255, 255, 0.27) 100%)",
          }}
          spacing={0}
        >
          <Box
            sx={{
              flex: 0.27,
            }}
          >
            <img
              src={szgzLogo}
              style={{
                position: "absolute",
                top: 5,
                left: 5,
                height: "50px",
              }}
            />
            <img
              src={szgz_mobile_background}
              style={{
                position: "absolute",
                objectFit: "contain",
                zIndex: 0,
              }}
            />
          </Box>
          <Box sx={{ flex: 0.73, zIndex: 1 }}>
            <Stack
              sx={{
                borderRadius: "24px",
                height: "100%",
                paddingTop: "calc(7.22222%)",
                paddingBottom: "calc(7.22222%)",
                borderWidth: "1px",
                borderColor: "#fff",
                borderStyle: "solid",
                background:
                  "linear-gradient(325deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48) 100%);",
              }}
              style={{
                boxShadow:
                  "rgb(50 88 149 / 12%) 0.398421px 0.398421px 2.81726px -0.8125px, rgb(50 88 149 / 12%) 1.20684px 1.20684px 8.53364px -1.625px, rgb(50 88 149 / 11%) 3.19014px 3.19014px 22.5577px -2.4375px, rgb(50 88 149 / 7%) 10px 10px 70.7107px -3.25px, rgb(255 255 255 / 93%) 2px -2px 7px 0px inset",
                backdropFilter: "blur(10px)",
              }}
            >
              <Title
                sx={{
                  fontWeight: 400,
                  fontSize: "32px",
                  width: "78%",
                  alignSelf: "center",
                  fontFamily: "Satoshi-Bold",
                  color: "#1a3560",
                  letterSpacing: "-1px",
                  lineHeight: "1.2em",
                }}
                align="center"
              >
                Dobrodošli u web aplikaciju Športske Zajednice Grada Zadra!
              </Title>
              <Text align="center">
                Molimo upišite svoje korisničko ime i lozinku
              </Text>
              <Center
                sx={{
                  flex: 3,
                  width: "70%",
                  alignSelf: "center",
                }}
              >
                <Stack
                  spacing="md"
                  sx={{
                    flex: 1,
                  }}
                >
                  <TextInput
                    sx={{
                      width: "100%",
                      alignSelf: "center",
                      marginBottom: "2vh",
                    }}
                    {...form.getInputProps("email")}
                    placeholder="E-mail adresa"
                    styles={{
                      input: {
                        background: theme.colors.blue[0],
                      },
                    }}
                  />

                  <PasswordInput
                    sx={{ width: "100%", alignSelf: "center" }}
                    {...form.getInputProps("password")}
                    // label="Lozinka"
                    placeholder="Lozinka"
                    styles={{ input: { background: theme.colors.blue[0] } }}
                  />
                  {/* <Anchor
                    sx={{
                      alignSelf: "flex-end",
                      width: "70%",
                    }}
                    href="#"
                  >
                    <Text
                      sx={{
                        fontSize: "14px",
                      }}
                      underline
                      align="end"
                    >
                      Zaboravili ste lozinku?
                    </Text>
                  </Anchor> */}
                </Stack>
              </Center>
              <Box sx={{ flex: 1 }}></Box>
              <Button
                sx={{
                  alignSelf: "center",
                  width: "70%",
                  fontSize: "20px",
                }}
                type="submit"
                loading={userLogin.isLoading}
              >
                Prijavi se
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
    </form>
  );
};
