import { z } from "zod";
import { idSchema } from "../helpers";
export const meetingUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    date: z.date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    }),
    location: z
        .string({
        invalid_type_error: "Obvezan Podatak",
        required_error: "Obvezan Podatak",
    })
        .min(1, "Obvezan podatak"),
    notes: z
        .string({
        invalid_type_error: "Obvezan Podatak",
        required_error: "Obvezan Podatak",
    })
        .min(1, "Obvezan podatak"),
});
