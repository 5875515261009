import {
  AiFillSchedule,
  AiFillTrophy,
  AiOutlineSchedule,
  AiOutlineTrophy,
} from "react-icons/ai";
import {
  IoBarChart,
  IoBarChartOutline,
  IoCard,
  IoCardOutline,
  IoDocument,
  IoDocumentOutline,
} from "react-icons/io5";
import {
  MdMeetingRoom,
  MdOutlineMeetingRoom,
  MdPeople,
  MdPeopleOutline,
} from "react-icons/md";
import { useGetSelectedOrg } from "sdk/common/hooks";
import { ILink, SidebarMenuItem } from "./components/SideMenuItem";
import { NavLink } from "react-router-dom";

export const orgLinks: Array<ILink> = [
  // {
  //   to: "/pregled/klubovi/:orgId/detalji",
  //   title: "Detalji",
  //   ActiveIcon: IoGrid,
  //   Icon: IoGridOutline,
  // },
  {
    to: "/pregled/klubovi/:orgId/clanovi?status=ACTIVE",
    title: "Članovi",
    ActiveIcon: IoCard,
    Icon: IoCardOutline,
  },
  // { to: "/:orgId/treneri", title: "Treneri", icon: faPeopleLine },

  {
    to: "/pregled/klubovi/:orgId/sjednice",
    title: "Sjednice",
    ActiveIcon: MdMeetingRoom,
    Icon: MdOutlineMeetingRoom,
  },
  {
    to: "/pregled/klubovi/:orgId/treneri",
    title: "Treneri",
    ActiveIcon: MdPeople,
    Icon: MdPeopleOutline,
  },
  {
    to: "/pregled/klubovi/:orgId/natjecanja",
    title: "Natjecanja",
    ActiveIcon: AiFillTrophy,
    Icon: AiOutlineTrophy,
  },
  {
    to: "/pregled/klubovi/:orgId/dokumenti",
    title: "Dokumenti",
    ActiveIcon: IoDocument,
    Icon: IoDocumentOutline,
  },
  {
    to: "/pregled/klubovi/:orgId/rasporedi",
    title: "Rasporedi",
    ActiveIcon: AiFillSchedule,
    Icon: AiOutlineSchedule,
  },
  {
    to: "/pregled/klubovi/:orgId/izvjestaji",
    title: "Izvještaji",
    ActiveIcon: IoBarChart,
    Icon: IoBarChartOutline,
  },
];

export const ActiveOrgSidebar = () => {
  const selectedOrg = useGetSelectedOrg();
  if (!selectedOrg) return null;

  return (
    <div className="hidden w-[220px] min-w-[220px] flex-col gap-10 px-2 pt-24 xl:flex">
      <NavLink
        to={`/pregled/klubovi/${selectedOrg.id}`}
        className="text-center text-xl font-bold text-blue-50 no-underline hover:text-blue-700 "
      >
        {selectedOrg?.shortName || selectedOrg?.name}
      </NavLink>

      <div className="flex flex-col gap-4">
        {orgLinks?.map((link) => (
          <SidebarMenuItem key={link.to} {...link} />
        ))}
      </div>
    </div>
  );
};
