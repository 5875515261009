import { TextInput, Select, Button } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import RichTextEditor from "@mantine/rte";
import { matchUpsertSchema } from "models";
import { useParams, useNavigate } from "react-router-dom";
import { notifications } from "sdk/common/helpers";
import { RecordFiles } from "sdk/components/RecordFiles";
import { trpc } from "utils/trpc";

export const MatchUpdateForm = ({ match }: { match: any }) => {
  const { competitionMatchId } = useParams();
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { data: competition } = trpc.competition_find.useQuery({
    id: match.competitionId,
  });

  const matchUpsert = trpc.match_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.competition_find.invalidate({ id: data.competitionId });
      utils.match_find.invalidate({ id: data.id });
      navigate(-1);
    },
  });

  const form = useForm({
    initialValues: match,
    validate: zodResolver(matchUpsertSchema),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => matchUpsert.mutate(values))}
      className="overflow-y-auto"
    >
      <div className={`flex justify-center gap-8 py-4 `}>
        <div className="flex max-w-lg flex-col gap-4 p-4 ">
          <DatePicker
            withAsterisk
            sx={{ width: "auto" }}
            initialLevel="date"
            label="Datum"
            {...form.getInputProps("startDate")}
          />
          <TimeInput
            sx={{ width: "auto" }}
            label="Vrijeme početka"
            placeholder="hh:mm"
            {...form.getInputProps("startTime")}
          />

          <TextInput
            withAsterisk
            sx={{ width: "auto" }}
            label="Lokacija"
            {...form.getInputProps("location")}
          />
          <TextInput
            sx={{ width: "auto" }}
            label="Naziv"
            {...form.getInputProps("name")}
          />

          <Select
            sx={{ width: "auto" }}
            label="Dio natjecanja"
            data={
              competition?.CompetitionPeriods?.map((item) => ({
                value: item.id,
                label: item.name,
              })) || []
            }
            {...form.getInputProps("competitionPeriodId")}
          />

          <TextInput
            sx={{ width: "auto" }}
            label="Strana"
            {...form.getInputProps("side")}
          />

          <div>
            <label className="text-sm font-medium text-blue-500">
              Detalji susreta
            </label>
            <RichTextEditor
              sx={{ minHeight: 200, width: "auto" }}
              placeholder="Opis..."
              {...form.getInputProps("description")}
            />
          </div>
          {competitionMatchId && <RecordFiles recordId={competitionMatchId} />}
        </div>
        <div className={`flex flex-col gap-4 self-end`}>
          <Button
            sx={{ minWidth: 190 }}
            variant="outline"
            onClick={() => navigate(-1)}
            disabled={matchUpsert.isLoading}
          >
            Odustani
          </Button>
          <Button
            sx={{ minWidth: 190 }}
            type="submit"
            loading={matchUpsert.isLoading}
            disabled={matchUpsert.isLoading}
          >
            Spremi
          </Button>
        </div>
      </div>
    </form>
  );
};
