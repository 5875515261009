import { IoDocumentTextOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";

export const ProgressBar = ({ fileName, fileSize, progress, uploadStatus }) => {
  return (
    <div className="relative overflow-hidden rounded-lg">
      <div
        style={{
          width: `${progress * 100}%`,
        }}
        className={`absolute top-0 left-0 bottom-0 z-0 bg-[#C2D9FF]`}
      />
      <div className="relative z-10 flex items-center gap-6 py-2 px-4 ">
        <IoDocumentTextOutline size={32} />
        <div className="font-bold">
          <div>{fileName}</div>
          <div className="text-sm">
            {fileSize > 1024 ** 2
              ? `${(fileSize / 1024 ** 2).toFixed(2)} MB`
              : `${(fileSize / 1024).toFixed(0)} KB`}
          </div>
        </div>
        {uploadStatus === "UPLOADED" && (
          <FaCheck className="ml-auto text-white" size={32} />
        )}
      </div>
    </div>
  );
};
