import { z } from "zod";
import { idSchema } from "../helpers";
export const matchUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    competitionId: idSchema,
    competitionPeriodId: idSchema.nullish(),
    startDate: z.date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    }),
    startTime: z.date().nullish(),
    location: z.string().min(1, "Obvezan podatak"),
    name: z.string(),
    side: z.string(),
    description: z.string().nullish(),
});
