import {
  Grid,
  Space,
  Stack,
  Sx,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useSetTitle } from "sdk/common/hooks";
import { PrintHeader } from "./PrintHeader";
import { trpc } from "utils/trpc";

export const MembersStatisticsReport = () => {
  const { data: orgs, isLoading } = trpc.organization_findMany.useQuery();

  useSetTitle("Statistika sportaša");

  return (
    <Stack p="xl" sx={{ width: "210mm", overflow: "auto" }}>
      <PrintHeader />
      <Title order={1} align="center">
        Statistika sportaša
      </Title>
      <Space sx={{ height: 12 }} />
      <TotalTable />
      <Space sx={{ height: 24 }} />
      <PerSport />
      <Space sx={{ height: 24 }} />
      <PerSportDetailed orgs={orgs} />
    </Stack>
  );
};

const textHeaderStyle: Sx = {
  textAlign: "right",
  marginRight: 16,
  fontWeight: "bold",
};
const textValueStyle: Sx = { textAlign: "right", marginRight: 16 };

const TotalTable = () => {
  const theme = useMantineTheme();

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const reportRows = [
    {
      id: 1,
      label: "Broj registriranih članova",
      male: getRandomInt(10),
      female: getRandomInt(10),
    },
    {
      id: 2,
      label: "Broj neregistriranih članova",
      male: getRandomInt(10),
      female: getRandomInt(10),
    },
    {
      id: 3,
      label: "Broj ostalih članova",
      male: getRandomInt(10),
      female: getRandomInt(10),
    },
  ];

  let totalWomen = 0;
  let totalMen = 0;

  reportRows.forEach((report) => {
    totalMen = totalMen + report.male;
    totalWomen = totalWomen + report.female;
  });

  return (
    <>
      <Grid columns={5} sx={{ backgroundColor: theme.colors.blue[4] }}>
        <React.Fragment>
          <Grid.Col span={2}>
            <Text sx={[textHeaderStyle, { textAlign: "left" }]}>
              Ukupni podaci
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Žena</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Muškaraca</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Ukupno</Text>
          </Grid.Col>
        </React.Fragment>
      </Grid>
      <Grid columns={5}>
        {reportRows?.map((report) => (
          <React.Fragment key={report.id}>
            <Grid.Col span={2}>
              <Text sx={[textValueStyle, { textAlign: "left" }]}>
                {report.label}
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text sx={textValueStyle}>{report.female}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text sx={textValueStyle}>{report.male}</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text sx={textValueStyle}>{report.male + report.female}</Text>
            </Grid.Col>
          </React.Fragment>
        ))}
      </Grid>
      <TotalRow totalWomen={totalWomen} totalMen={totalMen} />
    </>
  );
};

const PerSport = () => {
  const theme = useMantineTheme();

  const { data: sports } = trpc.sport_findMany.useQuery();

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  let totalWomen = 0;
  let totalMen = 0;

  return (
    <>
      <Grid columns={5} sx={{ backgroundColor: theme.colors.blue[4] }}>
        <React.Fragment>
          <Grid.Col span={2}>
            <Text sx={[textHeaderStyle, { textAlign: "left" }]}>Po sportu</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Žena</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Muškaraca</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Ukupno</Text>
          </Grid.Col>
        </React.Fragment>
      </Grid>
      <Grid columns={5}>
        {sports?.map((sport) => {
          const m = getRandomInt(10);
          const f = getRandomInt(10);
          totalMen = totalMen + m;
          totalWomen = totalWomen + f;
          return (
            <React.Fragment key={sport.id}>
              <Grid.Col span={2}>
                <Text>{sport.name}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text sx={textValueStyle}>{f > 0 ? f : "-"}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text sx={textValueStyle}>{m > 0 ? m : "-"}</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text sx={textValueStyle}>{m + f > 0 ? m + f : "-"}</Text>
              </Grid.Col>
            </React.Fragment>
          );
        })}
      </Grid>
      <TotalRow totalWomen={totalWomen} totalMen={totalMen} />
    </>
  );
};

const TotalRow = ({
  totalWomen,
  totalMen,
}: {
  totalWomen: number;
  totalMen: number;
}) => {
  const theme = useMantineTheme();

  return (
    <Grid
      columns={5}
      sx={{
        borderTop: `1px solid ${theme.colors.blue[5]}`,
        marginTop: 2,
      }}
    >
      <React.Fragment>
        <Grid.Col sx={{ fontWeight: "bold" }} span={2}>
          Ukupno
        </Grid.Col>
        <Grid.Col span={1}>
          <Text sx={textHeaderStyle}>{totalWomen}</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Text sx={textHeaderStyle}>{totalMen}</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Text sx={textHeaderStyle}>{totalWomen + totalMen}</Text>
        </Grid.Col>
      </React.Fragment>
    </Grid>
  );
};

const PerSportDetailed = ({ orgs }) => {
  const theme = useMantineTheme();

  const { data: sports } = trpc.sport_findMany.useQuery();

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <>
      <Grid columns={5} sx={{ backgroundColor: theme.colors.blue[4] }}>
        <React.Fragment>
          <Grid.Col span={2}>
            <Text sx={[textHeaderStyle, { textAlign: "left" }]}>
              Po sportu (detaljno)
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Žena</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Muškaraca</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text sx={textHeaderStyle}>Ukupno</Text>
          </Grid.Col>
        </React.Fragment>
      </Grid>

      {sports?.map((sport) => {
        return (
          <React.Fragment key={sport.id}>
            <Stack>
              <Grid columns={5} sx={{ backgroundColor: theme.colors.blue[3] }}>
                <Grid.Col span={2}>
                  <Text>{sport.name}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text sx={textValueStyle}></Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text sx={textValueStyle}></Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text sx={textValueStyle}>
                    {/* {totalMenSport + totalWomenSport > 0
                      ? totalMenSport + totalWomenSport
                      : "-"} */}
                  </Text>
                </Grid.Col>
              </Grid>
              {orgs
                ?.filter((x) => x.sportId === sport.id)
                .map((org) => {
                  const f = getRandomInt(10);
                  const m = getRandomInt(10);
                  return (
                    <Grid key={org.id} columns={5}>
                      <Grid.Col span={2}>
                        <Text>{org.name}</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text sx={textValueStyle}>{f > 0 ? f : "-"}</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text sx={textValueStyle}>{m > 0 ? m : "-"}</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text sx={textValueStyle}>
                          {m + f > 0 ? m + f : "-"}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  );
                })}
            </Stack>
          </React.Fragment>
        );
      })}
    </>
  );
};
