import { z } from "zod";
import { idSchema, isValidOIB } from "../helpers";
export const memberUpdateSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    firstName: z
        .string()
        .min(1, { message: "Ime je obvezan podatak" })
        .optional(),
    lastName: z
        .string()
        .min(1, { message: "Prezime je obvezan podatak" })
        .optional(),
    gender: z.string().min(1, { message: "Spol je obvezan podatak" }).nullish(),
    dateOfBirth: z.coerce
        .date({
        required_error: "Datum rođenja je obvezan podatak",
        invalid_type_error: "Datum rođenja je obvezan podatak",
    })
        .nullish(),
    parentNames: z.string().nullish(),
    email: z
        .string()
        .email("E-mail je neispravan")
        .nullish()
        .or(z.literal(""))
        .nullish(),
    phoneNumber: z.string().nullish(),
    description: z.string().nullish(),
    membershipTypeId: z
        .string({
        invalid_type_error: "Tip člana je obvezan podatak",
        required_error: "Tip člana je obvezan podatak",
    })
        .nullish(),
    oib: z
        .string({
        required_error: "OIB je obvezan podatak",
        invalid_type_error: "OIB je obvezan podatak",
    })
        .refine((value) => isValidOIB(value?.toString()), (value) => ({
        message: value.length ? "OIB je neispravan" : "OIB je obvezan podatak",
    }))
        .nullish(),
    membershipStartDate: z.coerce
        .date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    })
        .nullish(),
    membershipEndDate: z.coerce
        .date({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    })
        .nullish(),
    status: z.string().nullish(),
    address: z.string().nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    houseNo: z.string().nullish(),
    avatarRecordFileId: idSchema.nullish(),
    memberRole: z.string().nullish(),
    nationalSelectionName: z.string().nullish(),
});
