import { Loader } from "@mantine/core";

import { useParams } from "react-router-dom";
import { useSetTitle } from "sdk/common/hooks";
import { trpc } from "utils/trpc";
import { EditMeetingForm } from "./components/EditMeetingForm";
import { ContentContainer } from "sdk/components/ContentContainer";

export const UrediSjednicu = () => {
  const { id } = useParams();

  useSetTitle("Detalji sjednice", { showBackButton: true });

  const { data, isLoading, isError } = trpc.meeting_find.useQuery({
    id: id as string,
  });

  if (isLoading) return <Loader size="xl" mx="auto" />;

  if (isError || !data) return <div>Error</div>;

  return (
    <ContentContainer className="h-full overflow-hidden">
      <EditMeetingForm initialValues={data} />
    </ContentContainer>
  );
};
