import { useSetTitle } from "sdk/common/hooks";
import { UserForm } from "./components/UserForm";
import { ulid } from "ulid";
import { useNavigate } from "react-router-dom";
import { notifications } from "sdk/common/helpers";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";

export const NoviKorisnik = () => {
  useSetTitle("Novi korisnik", { showBackButton: true });

  const navigate = useNavigate();

  const utils = trpc.useContext();

  const userCreate = trpc.user_create.useMutation({
    onSuccess: () => {
      notifications.success("Korisnik uspješno dodan");
      utils.user_findMany.reset();
      navigate("/pregled/korisnici");
    },
    onError: (error) => notifications.error(error.message),
  });

  const initialValues = {
    id: ulid(),
    firstName: "",
    lastName: "",
    countryId: "HR",
    email: "",
    phoneNumber: "",
    dateOfBirth: new Date(),
    orgId: "",
    password: "",
  };

  return (
    <ContentContainer className="h-full overflow-auto">
      <UserForm
        initialValues={initialValues}
        onSubmit={(values) => userCreate.mutate(values)}
        isSubmitting={userCreate.isLoading}
      />
    </ContentContainer>
  );
};
