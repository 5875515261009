import {
  Group,
  Stack,
  Button,
  Text,
  useMantineTheme,
  Modal,
  ActionIcon,
  TypographyStylesProvider,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import RichTextEditor from "@mantine/rte";
import React from "react";
import { MdEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { formatDate } from "sdk/common/helpers";
import { RecordFilesView } from "sdk/components/RecordFilesView";
import { ulid } from "ulid";
import { CardContainer, TabPanelContainer } from ".";
import { trpc } from "utils/trpc";
import { useForm, zodResolver } from "@mantine/form";
import { medicalUpsertSchema } from "models";
import { assert } from "sdk/assert";
import { RecordFiles } from "sdk/components/RecordFiles";
import { openDeleteDialog } from "sdk/common/hooks";

export const TabPanelMedical = () => {
  const { id, orgId } = useParams();
  assert(id);
  const { data: member, refetch } = trpc.member_find.useQuery({ id });
  const theme = useMantineTheme();

  const medicalDelete = trpc.member_medicalDelete.useMutation({
    onSuccess: () => {
      refetch();
    },
  });

  const [selectedItem, setSelectedItem] = React.useState<any>();

  if (!member) return null;

  const items = member.MemberMedicalRecords;
  const isEdit = !!items?.find((item) => item.id === selectedItem?.id);

  return (
    <>
      <TabPanelContainer
        addButtonTitle={"Novi pregled"}
        addButtonOnClick={() =>
          setSelectedItem({
            id: ulid(),
            orgId,
            memberId: id,
            date: new Date(),
            description: "",
          })
        }
      >
        <CardContainer>
          <Text>Povijest pregleda</Text>
          <Stack spacing="xs" sx={{ position: "relative" }}>
            {items?.map((item, index) => (
              <div key={index} className="flex items-center gap-8">
                <div
                  key={item.id}
                  className="flex grow cursor-pointer justify-between gap-16 rounded-lg bg-blue-200 p-2 transition-all hover:rounded-2xl hover:bg-blue-300"
                  onClick={() => setSelectedItem({ ...item })}
                >
                  <Text>{formatDate.toDateString(item.date)}</Text>
                  <TypographyStylesProvider>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description || "",
                      }}
                    />
                  </TypographyStylesProvider>
                  {item?.id && <RecordFilesView recordId={item?.id} />}
                </div>
                <div className="flex gap-4 px-2">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    radius="xl"
                    size="xl"
                    onClick={() => setSelectedItem(item)}
                  >
                    <MdEdit />
                  </ActionIcon>
                  <ActionIcon
                    color="pink"
                    variant="light"
                    radius="xl"
                    size="xl"
                    onClick={() =>
                      openDeleteDialog({
                        onAccept() {
                          medicalDelete.mutate({ id: item.id });
                        },
                        titleText: "Izbriši pregled",
                        contentText: "Potvrdom čete izbrisati pregled",
                      })
                    }
                  >
                    <TbTrash />
                  </ActionIcon>
                </div>
              </div>
            ))}
          </Stack>
          {/* <pre style={{ marginTop: 400 }}>
            {JSON.stringify({ selectedItem }, null, 2)}
          </pre> */}
        </CardContainer>
      </TabPanelContainer>

      <Modal
        overlayOpacity={0.2}
        opened={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title={isEdit ? "Izmjena podataka" : "Novi pregled"}
        styles={{
          modal: { width: `min(100%, ${theme.breakpoints.sm}px)` },
          title: { fontWeight: "bold" },
        }}
      >
        <MedicalUpsert
          medical={selectedItem}
          onClose={() => setSelectedItem(undefined)}
        />
      </Modal>
    </>
  );
};

const MedicalUpsert = ({ medical, onClose }) => {
  const utils = trpc.useContext();
  const medicalUpsert = trpc.member_medicalUpsert.useMutation({
    onSuccess: () => {
      utils.member_find.invalidate({ id: medical.memberId });
      onClose();
    },
  });

  const form = useForm({
    validate: zodResolver(medicalUpsertSchema),
    initialValues: medical,
  });

  return (
    <form onSubmit={form.onSubmit((values) => medicalUpsert.mutate(values))}>
      <Stack>
        <Group align="flex-start" noWrap>
          <DatePicker
            withAsterisk
            label="Datum pregleda"
            {...form.getInputProps("date")}
            maxDate={new Date()}
          />
        </Group>
        <RichTextEditor {...form.getInputProps("description")} />
        {medical?.id && <RecordFiles recordId={medical.id} />}
        <Group position="right">
          <Button
            disabled={medicalUpsert.isLoading}
            variant="outline"
            onClick={onClose}
            type="button"
          >
            Odustani
          </Button>
          <Button
            loading={medicalUpsert.isLoading}
            disabled={medicalUpsert.isLoading}
            type="submit"
          >
            Spremi
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
