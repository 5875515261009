import { Box, Text } from "@mantine/core";
import { useSetTitle } from "sdk/common/hooks";
import { trpc } from "utils/trpc";

export const SportsPrintScreen = (props) => {
  const { data: sports } = trpc.sport_findMany.useQuery();

  useSetTitle("Aktivni klubovi");

  return (
    <Box>
      <Text>Sportovi</Text>
      <pre>{JSON.stringify(sports, null, 2)}</pre>
    </Box>
  );
};
