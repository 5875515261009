import { z } from "zod";
import { idSchema } from "../helpers";
export const physiqueUpsertSchema = z.object({
    id: idSchema,
    memberId: idSchema,
    orgId: z.string().min(1),
    date: z.date(),
    height: z.string().nullish(),
    weight: z.string().nullish(),
    description: z.string().nullish(),
});
