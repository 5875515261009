import React from "react";
import { Loader, Select, SelectProps, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import { trpc } from "utils/trpc";

export const SelectVenue = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        filterBySportId?: string;
      },
    ref: any
  ) => {
    const { hideLabel, filterBySportId, ...rest } = props;

    const { orgId } = useParams();

    const { data: venues, isLoading, isError } = trpc.venue_findMany.useQuery();

    if (isLoading) return <Loader />;
    if (!venues || isError) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Sportski objekt"}
        label={hideLabel ? undefined : "Sportski objekt"}
        {...rest}
        data={venues?.map((o) => ({ value: o.id, label: o.name }))}
      />
    );
  }
);

SelectVenue.displayName = "SelectVenue";
