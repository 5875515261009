import { Modal, Select, Button } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { SelectAgeGroup } from "sdk/components/Selects/SelectAgeGroup";
import { SelectCoach } from "sdk/components/Selects/SelectCoach";
import { SelectVenue } from "sdk/components/Selects/SelectVenue";
import { daysOfWeek } from "../RasporediTreninga";
import { trainingEventUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { notifications } from "sdk/common/helpers";

export const EventForm = ({ event, isEdit, hideModal }) => {
  const form = useForm({
    initialValues: event,
    validate: zodResolver(trainingEventUpsertSchema),
  });

  const utils = trpc.useContext();

  const eventDelete = trpc.event_delete.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.trainingSchedule_find.invalidate({ id: data.trainingScheduleId });
      hideModal();
    },
    onError: () => notifications.error(),
  });
  const eventUpsert = trpc.event_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.trainingSchedule_find.invalidate({ id: data.trainingScheduleId });
      hideModal();
    },
    onError: () => notifications.error(),
  });

  return (
    <Modal
      opened={!!event}
      onClose={hideModal}
      title={isEdit ? "Uredi termin" : "Novi termin"}
      styles={{
        title: { fontSize: 20 },
      }}
      size="lg"
    >
      <form onSubmit={form.onSubmit((values) => eventUpsert.mutate(values))}>
        <div className="flex flex-col gap-4">
          {/* <pre>{JSON.stringify({ form }, null, 2)}</pre> */}

          <Select
            label="Dan"
            data={[...daysOfWeek]}
            {...form.getInputProps("day")}
            sx={{ width: "auto" }}
          />
          <SelectVenue
            {...form.getInputProps("venueId")}
            sx={{ width: "auto" }}
          />
          <div className="flex gap-4 ">
            <TimeInput
              name="x-start-time"
              label="Vrijeme početka"
              {...form.getInputProps("startTime")}
            />
            <TimeInput
              name="x-end-time"
              label="Vrijeme kraja"
              {...form.getInputProps("endTime")}
            />
          </div>

          <SelectAgeGroup
            {...form.getInputProps("ageGroupId")}
            sx={{ width: "auto" }}
          />

          <SelectCoach
            {...form.getInputProps("coachId")}
            sx={{ width: "auto" }}
          />

          <div className="mt-8 flex gap-4">
            {isEdit && (
              <Button
                disabled={eventDelete.isLoading || eventUpsert.isLoading}
                variant="outline"
                onClick={() => eventDelete.mutate(event.id)}
                className="text-pink-500"
              >
                Obriši
              </Button>
            )}
            <Button
              disabled={eventDelete.isLoading || eventUpsert.isLoading}
              variant="outline"
              onClick={hideModal}
              className="ml-auto"
            >
              Odustani
            </Button>
            <Button
              disabled={eventDelete.isLoading || eventUpsert.isLoading}
              loading={eventUpsert.isLoading}
              type="submit"
            >
              Spremi
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
