import { Group, Stack, Text } from "@mantine/core";
import { MdOutlineAddCircle } from "react-icons/md";
import { twMerge } from "tailwind-merge";

export const TabPanelContainer = ({
  addButtonTitle,
  addButtonOnClick,
  ...props
}) => (
  <Stack sx={{ flexGrow: 1 }}>
    <Group
      position="right"
      sx={{ cursor: "pointer" }}
      spacing="xs"
      onClick={addButtonOnClick}
    >
      <MdOutlineAddCircle style={{ color: "rgb(120, 169, 247)" }} size={24} />
      <Text weight="bold" color={"rgb(120, 169, 247)"}>
        {addButtonTitle}
      </Text>
    </Group>
    {props.children}
  </Stack>
);

export const CardContainer = ({ children, className = "", ...props }) => (
  <div
    className={twMerge(
      "flex flex-col gap-4 rounded-2xl bg-[#fdfaff] p-6 transition-all focus-within:shadow-table-row hover:shadow-table-row",
      className
    )}
    {...props}
  >
    {children}
  </div>
);
