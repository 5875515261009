import { z } from "zod";
import { idSchema } from "../helpers";
export const userUpsertSchema = z.object({
    id: idSchema,
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    email: z.string().min(3, "E-mail je obvezan podatak"),
    phoneNumber: z.string().nullish(),
    password: z.string().min(1, "Upišite lozinku"),
    orgId: z.string().nullish(),
    dateOfBirth: z.date({ required_error: "Obvezan podatak" }).optional(),
    avatarRecordFileId: idSchema.nullish(),
});
