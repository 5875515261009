// utils/trpc.ts
import { createTRPCProxyClient, createTRPCReact } from "@trpc/react-query";
import { AppRouter } from "../../../backend/src/trpc/routes";
import superjson from "superjson";
import { httpLink } from "@trpc/client";
import { useAppState } from "state/useAppState";

export const trpc = createTRPCReact<AppRouter>();

export const trpcProxy = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpLink({
      url: import.meta.env.VITE_SERVER_URI + "/trpc",

      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});
