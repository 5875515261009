import { z } from "zod";
import { idSchema } from "../helpers";
export const competitionUpsertSchema = z.object({
    id: idSchema,
    orgId: z.string().min(1),
    typeId: idSchema,
    ageGroupId: idSchema,
    name: z
        .string({
        invalid_type_error: "Obvezan podatak",
        required_error: "Obvezan podatak",
    })
        .min(1, "Obvezan podatak"),
    startDate: z.date(),
    endDate: z.date().nullish(),
    description: z.string().nullish(),
});
