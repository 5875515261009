import { Loader, Select, SelectProps, Text } from "@mantine/core";
import React from "react";
import { trpc } from "utils/trpc";

export const SelectMembershipType = React.forwardRef(
  (
    props: Partial<SelectProps> &
      React.RefAttributes<HTMLInputElement> & {
        hideLabel?: boolean;
        withAllAthletesOption?: boolean;
      },
    ref: any
  ) => {
    const { hideLabel, withAllAthletesOption, ...rest } = props;

    const { data, isLoading, error } = trpc.membershipType_findMany.useQuery();

    const membershipTypes =
      data
        ?.filter(
          (item) =>
            item.id !== "T" && (withAllAthletesOption ? true : item.id !== "SS")
        )
        ?.map((item) => ({ value: item.id, label: item.name })) || [];

    if (isLoading) return <Loader />;
    if (!data || error) return <Text>Greška</Text>;

    return (
      <Select
        ref={ref}
        clearable
        placeholder={!hideLabel ? undefined : "Tip člana"}
        label={hideLabel ? undefined : "Tip člana"}
        data={membershipTypes}
        {...rest}
      />
    );
  }
);

SelectMembershipType.displayName = "SelectMembershipType";
