import { z } from "zod";
import { idSchema } from "../helpers";
export const competitionPeriodUpsertSchema = z.object({
    id: idSchema,
    competitionId: idSchema,
    name: z.string().min(1, "Obvezan podatak"),
    startDate: z.date({
        required_error: "Obvezan podatak",
        invalid_type_error: "Obvezan podatak",
    }),
    endDate: z
        .date({
        required_error: "Obvezan podatak",
        invalid_type_error: "Obvezan podatak",
    })
        .nullish(),
});
