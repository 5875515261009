import { Text } from "@mantine/core";
import { useParams, useLocation, NavLink } from "react-router-dom";
import { IoMdMail, IoIosCall, IoMdCreate } from "react-icons/io";
import { calculateAge, formatDate } from "sdk/common/helpers";
import { useSetTitle } from "sdk/common/hooks";
import { ProfilePicture } from "sdk/components/ProfilePicture";
import { ContentContainer } from "sdk/components/ContentContainer";
import { trpc } from "utils/trpc";

export const DetaljiTrenera = (props) => {
  const { id } = useParams();
  const {
    data: member,
    isLoading,
    isError,
  } = trpc.member_find.useQuery({
    id: id as string,
  });

  useSetTitle(
    member
      ? `${member.firstName} ${member.lastName} (${
          member.Organization?.name || ""
        })`
      : "",
    {
      showBackButton: true,
    }
  );
  const { pathname } = useLocation();

  if (isLoading) return <Text>Pričekajte</Text>;
  if (!member || isError) return <div>Podaci o treneru nisu pronađeni</div>;

  return (
    <ContentContainer className="h-full overflow-auto">
      <div className="mx-auto flex max-w-[1400px] gap-16 p-8">
        <ProfilePicture
          disableEdit={true}
          recordId={member.id}
          avatarRecordFileId={member.avatarRecordFileId as string}
        />

        <div className="relative flex flex-1 flex-col gap-8 rounded-xl bg-[#fdfaff] p-4 px-8">
          <NavLink
            to={pathname.replace("detalji", "uredi")}
            className={`absolute top-0 right-0 flex h-12 w-12 translate-x-1/3 -translate-y-1/3 items-center justify-center
                        rounded-full bg-blue-700 text-2xl text-[#fdfaff]
                        shadow-button
                        hover:shadow-button-hover
                        active:shadow-button-active`}
          >
            <IoMdCreate />
          </NavLink>

          <LabelAndText label="OIB" text={member.oib || "-"} />

          <LabelAndText
            label="DATUM ROĐENJA"
            text={formatDate.toDateString(member.dateOfBirth)}
          />
          <LabelAndText
            label="DOB"
            text={
              member.dateOfBirth
                ? calculateAge(member.dateOfBirth).toString()
                : ""
            }
          />
          <LabelAndText label="SPOL" text={member.gender || "-"} />

          <LabelAndText
            label="STATUS"
            text={member.CoachEmploymentType?.name || "-"}
          />
          <LabelAndText
            label="OSPOSOBLJENOST"
            text={member.EmployeeQualification?.name || "-"}
          />
          <LabelAndText label="LICENCA" text={member.license || "-"} />
          <LabelAndText label="MJESTO RADA" text={member.workLocation || "-"} />
          <LabelAndText
            label="NACIONALNA SELEKCIJA"
            text={member.nationalSelectionName || "-"}
          />

          <LabelAndText
            label="POČETAK RADA"
            text={formatDate.toDateString(member.workStartDate)}
          />
          {!!member.membershipEndDate && (
            <LabelAndText
              label="KRAJ RADA"
              text={formatDate.toDateString(member.membershipEndDate)}
            />
          )}
        </div>

        <div className="flex flex-col gap-16">
          <div className="flex items-center gap-4 text-xl">
            <IoMdMail className="text-3xl text-blue-600" />
            {member.email ? (
              <a
                className="text-blue-600 no-underline"
                href={`mailto:${member.email}`}
              >
                {member.email}
              </a>
            ) : (
              <Text sx={{ fontStyle: "italic" }}>
                nema upisanu email adresu
              </Text>
            )}
          </div>

          <div className="flex items-center gap-4 text-xl">
            <IoIosCall className="text-3xl text-blue-600" />
            <Text sx={{ fontStyle: member.phoneNumber ? "normal" : "italic" }}>
              {member?.phoneNumber || "nema upisan broj telefona"}
            </Text>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

const LabelAndText = ({ text, label }) => {
  return (
    <div className="flex items-center gap-8 border-0 border-b-[3px] border-solid border-blue-300 py-1">
      <div className="flex-1 font-bold text-blue-400">{label}</div>
      <div className="font-bold text-blue-500">{text}</div>
    </div>
  );
};
