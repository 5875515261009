import "dayjs/locale/hr";
import {
  // unstable_HistoryRouter as HistoryRouter,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { Button, Loader, MantineProvider, Text } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

import { useAppState } from "./state/useAppState";
import { AppRouter } from "./routes";
import { CustomFonts } from "./CustomFonts";
import { useEffect, useRef, useState } from "react";
import {
  NewVersionAvailableBanner,
  useServiceWorker,
} from "./useServiceWorker";
import { trpc } from "./utils/trpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink } from "@trpc/client";
import superjson from "superjson";
import { LoginScreen } from "./screens/commonScreens/LoginScreen";
import { theme } from "sdk/common/theme";
import { TbTrash } from "react-icons/tb";

// import { createBrowserHistory } from "history";

// export const history = createBrowserHistory();

export function App() {
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpLink({
          url: import.meta.env.VITE_SERVER_URI + "/trpc",
          // optional
          // headers: () => ({
          //   "x-org-id": orgId || "",
          // }),
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    })
  );

  useEffect(() => {
    if (!isUpdateAvailable) {
      return;
    }
    setTimeout(() => setShowUpdateBanner(true), 3000);
  }, [isUpdateAvailable]);

  const handleUpgradeApp = () => {
    updateAssets();
    setShowUpdateBanner(false);
  };
  return (
    <>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {/* Your app here */}
          <MantineProvider
            // emotionOptions={{ key: "lemon-mint", prepend: false }}
            theme={theme}
            withGlobalStyles
            withNormalizeCSS
          >
            <NotificationsProvider position="top-center" zIndex={2077}>
              <BrowserRouter>
                <CustomFonts />
                <AppContent />
                <DeleteDialogContainer />

                {showUpdateBanner ? (
                  <NewVersionAvailableBanner onClick={handleUpgradeApp} />
                ) : null}
              </BrowserRouter>
            </NotificationsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </>
  );
}
const AppContent = () => {
  const { pathname } = useLocation();

  const userMe = trpc.user_me.useQuery(undefined, { retry: false });

  if (userMe.isLoading) return <Loader />;
  //we are waiting for a READY signal from the store, set by init() call

  if (!userMe.data || pathname === "/prijava") return <LoginScreen />;

  return <AppRouter />;
};

const DeleteDialogContainer = () => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const [dialog, setDialog] = useAppState((state) => [
    state.dialog,
    state.setDialog,
  ]);

  useEffect(() => {
    {
      if (dialogRef.current) {
        if (dialog?.show) {
          dialogRef.current.showModal();
        } else {
          dialogRef.current.close();
        }
      }
    }
  }, [dialog]);

  if (!dialog) return null;

  return (
    <dialog
      ref={dialogRef}
      className="rounded-2xl border-0 p-0 shadow-xl transition-all lg:mt-[25dvh]"
    >
      <div>
        <div className="flex flex-col items-center gap-2 p-8">
          <TbTrash className="text-4xl text-pink-600" />
          <Text className="text-2xl font-bold text-pink-600">
            {dialog?.titleText || "Izbriši podatke"}
          </Text>
        </div>

        <div className=" flex flex-col items-center gap-8 bg-[#fdfaff] py-8 px-16 ">
          <Text className="text-lg">
            {dialog?.contentText || "Potvrdom ćete izbrisati podatke"}
          </Text>
          <div className="flex gap-8">
            <Button
              variant="outline"
              disabled={dialog?.isLoading}
              onClick={() => {
                dialog?.onReject?.();
                setDialog({});
              }}
            >
              Odustani
            </Button>
            <Button
              loading={dialog?.isLoading}
              disabled={dialog?.isLoading}
              className="bg-pink-500 hover:bg-pink-600 active:bg-pink-600"
              onClick={() => {
                console.log("deleting");
                dialog?.onAccept?.();
                setDialog({});
              }}
            >
              {dialog.buttonText || "Izbriši"}
            </Button>
          </div>
        </div>
      </div>
    </dialog>
  );
};
