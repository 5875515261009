import { Navigate } from "react-router-dom";
import { useMe } from "sdk/common/hooks";

export const BaseRouteRedirect = () => {
  const user = useMe();

  if (!user) return null;

  return (
    <Navigate
      to={
        user?.orgId?.toLowerCase() === "szgz"
          ? `/pregled`
          : `/pregled/klubovi/${user?.orgId}`
      }
    />
  );
};
