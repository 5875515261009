import { Button, Group, Modal, Text } from "@mantine/core";
import React from "react";
import { TiPlus } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import { assert } from "sdk/assert";
import { formatDate } from "sdk/common/helpers";
import { useBreakpoints, useSetTitle } from "sdk/common/hooks";
import { ContentContainer } from "sdk/components/ContentContainer";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { trpc } from "utils/trpc";
import { NovaSjednica } from "./components/NovaSjednica";

export const Sjednice = () => {
  const { orgId } = useParams();
  assert(orgId);
  useSetTitle("Sjednice");

  const [showNewModal, setShowNewModal] = React.useState(false);

  const navigate = useNavigate();
  const { isLg } = useBreakpoints();

  const { data: meetings } = trpc.meeting_findMany.useQuery({ orgId });

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "Datum",
        accessor: "date",
        width: 220,
        Cell: ({ value }) => <span>{formatDate.toDateString(value)}</span>,
      },
      {
        Header: "Lokacija",
        accessor: "location",
        width: 250,
      },
    ],
    []
  );

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        {isLg && (
          <Button
            leftIcon={<TiPlus size={20} />}
            onClick={() => setShowNewModal(true)}
            sx={{ fontWeight: "bold", alignSelf: "flex-end" }}
          >
            Dodaj sjednicu
          </Button>
        )}
        <DataTable
          isLoading={false}
          data={meetings}
          columns={columns}
          onRowClick={(row) => navigate(`uredi/${row.id}`)}
          renderItem={() => (
            <Group noWrap>
              <Text>BBB</Text>
              <Text>AAA</Text>
            </Group>
          )}
          renderItemHeight={100}
          // hasNextPage={hasNextPage}
          // fetchMore={() => setSkip((prev) => prev + 100)}
        />
      </ContentContainer>

      <Modal
        opened={showNewModal}
        onClose={() => setShowNewModal(false)}
        title="Nova sjednica"
      >
        <NovaSjednica onClose={() => setShowNewModal(false)} />
      </Modal>
    </>
  );
};
