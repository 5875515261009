import { InputHTMLAttributes, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
};
export const Input = (props: Props) => {
  const { label, error } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="flex flex-col">
      {label ? (
        <label className="mb-[1px] text-sm font-[500] text-blue-500">
          {label}
        </label>
      ) : null}
      <div className="relative">
        <input
          {...props}
          autoComplete="new-password"
          type={showPassword ? "text" : props.type}
          className={`w-full rounded-4xl border-1 border-solid  border-input-border bg-input-background pl-3 placeholder-input-placeholder outline-none focus:border-4
        ${props.type === "password" ? "pr-8" : ""}
        h-[40px]
        font-sans text-blue-700 hover:border-4 
        ${
          error
            ? "hover:border-red-500 focus:border-red-500 active:border-red-500"
            : "hover:border-input-border-focused focus:border-input-border-focused active:border-input-border-focused "
        }
        
           ${props.className || ""} ${
            error ? "border-red-500 text-red-500" : ""
          }`}
        />
        {props.type === "password" ? (
          <div className="absolute right-0 top-1/2 flex -translate-y-1/2 flex-row items-center text-blue-600">
            {showPassword ? (
              <AiOutlineEyeInvisible
                size={24}
                onClick={() => setShowPassword(false)}
                className={`mr-2 cursor-pointer `}
              />
            ) : (
              <AiOutlineEye
                size={24}
                onClick={() => setShowPassword(true)}
                className={`mr-2 cursor-pointer`}
              />
            )}
          </div>
        ) : null}
      </div>
      {error ? (
        <span className="pt-1 text-xs text-red-500">{error}</span>
      ) : null}
    </div>
  );
};
