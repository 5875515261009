import {
  Modal,
  Select,
  TextInput,
  Button,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdRefresh, MdOutlineCancel } from "react-icons/md";
import { useParams } from "react-router-dom";
import { notifications } from "sdk/common/helpers";
import { ProgressBar } from "sdk/components/ProgressBar";
import { TDocument } from "../Dokumenti";
import { trpc } from "utils/trpc";

export const NewDocumentModal = ({
  newDocument,
  setNewDocument,
  folders,
}: {
  newDocument: TDocument;
  setNewDocument: (e: any) => void;
  folders: any[];
}) => {
  const { orgId } = useParams();

  const [uploadStatus, setUploadStatus] = React.useState<
    "INITIAL" | "UPLOADING" | "UPLOADED" | "ERROR"
  >("INITIAL");

  const [progress, setProgress] = React.useState<number | undefined>(0);

  const utils = trpc.useContext();

  const fileUpdate = trpc.recordFile_update.useMutation({
    onSuccess: () => {
      notifications.success();
      utils.documents_findMany.invalidate();
      setProgress(0);
      setUploadStatus("INITIAL");
      setNewDocument(undefined);
    },
  });

  const theme = useMantineTheme();

  const handleFileUpload = async ([file]) => {
    if (file) {
      setNewDocument((prev) => ({ ...prev, file, fileName: file.name }));
      const data = new FormData();
      data.append("file", file);
      data.append("id", newDocument?.id);
      data.append("recordId", orgId as string);
      data.append("orgId", orgId as string);

      setUploadStatus("UPLOADING");
      axios
        .post(import.meta.env.VITE_SERVER_URI + "/api/recordFileUpload", data, {
          withCredentials: true,
          onUploadProgress: ({ progress }) => setProgress(progress),
        })
        .then((result) => {
          if (result.status === 200) {
            notifications.success("Datoteka uspješno dodana");
            setUploadStatus("UPLOADED");
          }
        })
        .catch((error) => {
          setNewDocument((prev) => ({
            ...prev,
            file: undefined,
            fileName: "",
          }));

          console.log({ error });
          setUploadStatus("ERROR");
          notifications.error(
            "Greška prilikom dodavanja datoteke, pokušajte ponovno!"
          );
        });
    }
  };

  const isNewDocumentValid =
    newDocument?.fileKindId &&
    newDocument?.folderId &&
    newDocument?.fileName &&
    newDocument?.file &&
    uploadStatus === "UPLOADED";

  return (
    <Modal
      opened={!!newDocument}
      onClose={() => {
        setNewDocument(undefined);
        setUploadStatus("INITIAL");
      }}
      title={<div className="font-bold text-blue-800">Novi dokument</div>}
      styles={(theme) => ({
        modal: {
          background: "rgb(253,250,255)",
          borderRadius: theme.radius.lg,
        },
        // inner: { padding: "5%" },
      })}
      size="lg"
    >
      <div className="flex flex-col gap-6 p-4">
        {/* <pre>{JSON.stringify({ uploadStatus, progress }, null, 2)}</pre> */}
        <Select
          withAsterisk
          sx={{ width: "auto" }}
          label="Odaberi mapu"
          value={newDocument?.folderId}
          onChange={(folderId: any) =>
            setNewDocument((prev) => ({
              ...prev,
              folderId,
              fileKindId: null,
            }))
          }
          data={folders?.map((f) => ({ value: f.id, label: f.name }))}
          error={!newDocument?.folderId && "Mapa je obvezan podatak"}
        />
        <Select
          withAsterisk
          disabled={!newDocument?.folderId}
          sx={{ width: "auto" }}
          label="Vrsta dokumenta"
          value={newDocument?.fileKindId}
          onChange={(fileKindId: any) =>
            setNewDocument((prev) => ({ ...prev, fileKindId }))
          }
          data={
            folders
              ?.find((f) => f.id === newDocument?.folderId)
              ?.FileKinds?.map((fk) => ({ value: fk.id, label: fk.name })) || []
          }
          error={
            !newDocument?.fileKindId && "Vrsta dokumenta je obvezan podatak"
          }
        />

        {newDocument?.file && (
          <TextInput
            withAsterisk
            rightSection={
              newDocument.fileName !== newDocument.file.name && (
                <MdRefresh
                  className="cursor-pointer text-lg text-blue-600"
                  onClick={() =>
                    setNewDocument((prev) => ({
                      ...prev,
                      fileName: prev?.file?.name,
                    }))
                  }
                />
              )
            }
            sx={{ width: "auto" }}
            label="Ime dokumenta"
            value={newDocument?.fileName}
            onChange={(e) =>
              setNewDocument((prev) => ({
                ...prev,
                fileName: e.target.value,
              }))
            }
            error={!newDocument?.fileName && "Ime datoteke je obvezan podatak"}
          />
        )}

        {uploadStatus === "UPLOADING" || uploadStatus === "UPLOADED" ? (
          <div className="my-8 w-full">
            <ProgressBar
              fileName={newDocument?.file?.name}
              fileSize={newDocument?.file?.size}
              progress={progress}
              uploadStatus={uploadStatus}
            />
          </div>
        ) : (
          <div className="w-full">
            <div className="text-sm font-medium text-blue-500">Dokumenti</div>

            <Dropzone
              disabled={!!newDocument?.file}
              multiple={false}
              title="Dokumenti"
              onDrop={handleFileUpload}
              // loading={isUploading}
              maxSize={20 * 1024 ** 2}
              accept={[
                ...IMAGE_MIME_TYPE,
                MIME_TYPES.doc,
                MIME_TYPES.docx,
                MIME_TYPES.pdf,
                MIME_TYPES.xls,
                MIME_TYPES.xlsx,
              ]}
              onReject={() =>
                showNotification({
                  title: "Ažuriranje neuspješno",
                  message: "Vrsta datoteke nije podržana",
                  color: "pink",
                  icon: <MdOutlineCancel size={32} />,
                })
              }
              styles={{
                root: {
                  background: newDocument?.file
                    ? theme.colors.green[0]
                    : theme.colors.blue[1],
                  borderColor: newDocument?.file
                    ? theme.colors.green[3]
                    : theme.colors.blue[3],
                },
              }}
            >
              {newDocument?.file ? (
                <div className="flex items-center justify-center gap-4">
                  <div>Dodana datoteka: {`${newDocument.file.name}`}</div>
                  <IoMdCheckmarkCircleOutline
                    className=" text-green-600"
                    size={40}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center gap-2 p-8">
                  <IoCloudUploadOutline
                    size={32}
                    color={theme.colors.blue[6]}
                  />
                  <div className="">
                    <span className="font-bold text-blue-500">
                      Klikni ovdje za upload{" "}
                    </span>
                    ili dovuci sliku/dokument
                  </div>
                  <div className="text-sm italic">
                    (max veličina dokumenta 20 MB)
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        )}

        <div className="flex gap-6 self-end">
          <Button
            variant="outline"
            disabled={uploadStatus === "UPLOADING"}
            onClick={() => {
              setNewDocument(undefined);
              setUploadStatus("INITIAL");
            }}
          >
            Odustani
          </Button>
          <Button
            disabled={!isNewDocumentValid}
            loading={uploadStatus === "UPLOADING"}
            onClick={() =>
              fileUpdate.mutate({
                id: newDocument?.id,
                fileKindId: newDocument?.fileKindId,
                fileName: newDocument?.fileName,
                folderId: newDocument?.folderId,
              })
            }
          >
            Dodaj
          </Button>
        </div>
      </div>
    </Modal>
  );
};
