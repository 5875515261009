import { useRoutes } from "react-router-dom";
import { ActiveOrganizationsPrintScreen } from "../screens/printScreens/ActiveOrganizationsPrintScreen";
import { MembersStatisticsReport } from "../screens/printScreens/MembersStatisticsReport";
import { SportsPrintScreen } from "../screens/printScreens/SportsPrintScreen";
import { ClanoviKlubaIzvjestaj } from "../screens/appScreens/pregled/klubovi/$orgId/izvjestaji/ClanoviKlubaIzvjestaj";

export const adminPrintRoutes = [
  {
    path: "izvjestaji/aktivniKlubovi",
    element: <ActiveOrganizationsPrintScreen />,
    label: "Aktivni klubovi",
  },
  {
    path: "izvjestaji/sportovi",
    element: <SportsPrintScreen />,
    label: "Sportovi",
  },
  {
    path: "izvjestaji/statistikaSportasa",
    element: <MembersStatisticsReport />,
    label: "Statistika sportaša",
  },
];

export const orgPrintRoutes = [
  {
    path: "izvjestaji/:orgId/statistikaClanovaKluba",
    element: <ClanoviKlubaIzvjestaj />,
    label: "Izvještaj o članovima",
  },
];

export const PrintRoutes = () =>
  useRoutes([...adminPrintRoutes, ...orgPrintRoutes]);
