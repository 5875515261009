import {
  Group,
  Stack,
  Button,
  Text,
  useMantineTheme,
  Modal,
  ActionIcon,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import RichTextEditor from "@mantine/rte";
import React from "react";
import { MdEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { formatDate, notifications } from "sdk/common/helpers";
import { ulid } from "ulid";
import { CardContainer, TabPanelContainer } from ".";
import { useForm, zodResolver } from "@mantine/form";
import { physiqueUpsertSchema } from "models";
import { trpc } from "utils/trpc";
import { assert } from "sdk/assert";
import { openDeleteDialog } from "sdk/common/hooks";

export const TabPanelPhysique = () => {
  const { id, orgId } = useParams();
  assert(id);
  const { data: member, refetch } = trpc.member_find.useQuery({ id });
  const theme = useMantineTheme();

  const [selectedItem, setSelectedItem] = React.useState<any>();

  const physiqueDelete = trpc.member_physiqueDelete.useMutation({
    onSuccess: () => {
      notifications.success();
      refetch();
    },
    onError: () => notifications.error(),
  });

  if (!member) return null;

  const items = member.MemberPhysiqueRecords;
  const isEdit = !!items?.find((item) => item.id === selectedItem?.id);

  return (
    <>
      <TabPanelContainer
        addButtonTitle={"Novo mjerenje"}
        addButtonOnClick={() =>
          setSelectedItem({
            id: ulid(),
            orgId,
            memberId: id,
            date: new Date(),
            weight: "",
            height: "",
            description: "",
          })
        }
      >
        <CardContainer>
          {/* <pre>{JSON.stringify({ selectedItem }, null, 2)}</pre> */}
          <Text>Povijest mjerenja</Text>
          <Stack spacing="xs" sx={{ position: "relative" }}>
            {items?.map((item, index) => (
              <div className="flex gap-8" key={index}>
                <div
                  key={item.id}
                  className="flex grow cursor-pointer justify-between rounded-lg bg-blue-200 p-2 transition-all hover:rounded-2xl hover:bg-blue-300"
                  onClick={() => setSelectedItem({ ...item })}
                >
                  <Text>{formatDate.toDateString(item.date)}</Text>
                  <Text className="ml-auto mr-16">
                    {item.height || "-/-"} cm
                  </Text>
                  <Text>{item.weight || "-/-"} kg</Text>
                </div>
                <div className="flex gap-4 px-2">
                  <ActionIcon
                    variant="light"
                    color="gray"
                    radius="xl"
                    size="xl"
                    onClick={() => setSelectedItem(item)}
                  >
                    <MdEdit />
                  </ActionIcon>
                  <ActionIcon
                    color="pink"
                    variant="light"
                    radius="xl"
                    size="xl"
                    onClick={() =>
                      openDeleteDialog({
                        onAccept() {
                          physiqueDelete.mutate({
                            id: item.id,
                            memberId: id,
                          });
                        },
                        titleText: "Izbriši mjerenje",
                        contentText: "Potvrdom čete izbrisati mjerenje",
                      })
                    }
                  >
                    <TbTrash />
                  </ActionIcon>
                </div>
              </div>
            ))}
          </Stack>
        </CardContainer>
      </TabPanelContainer>

      <Modal
        overlayOpacity={0.2}
        opened={!!selectedItem}
        onClose={() => setSelectedItem(undefined)}
        title={isEdit ? "Izmjena podataka" : "Novo mjerenje"}
        styles={{
          modal: { width: `min(100%, ${theme.breakpoints.sm}px)` },
          title: { fontWeight: "bold" },
        }}
      >
        <PhysiqueUpsert
          physique={selectedItem}
          onClose={() => setSelectedItem(undefined)}
        />
      </Modal>
    </>
  );
};

const PhysiqueUpsert = ({ physique, onClose }) => {
  const utils = trpc.useContext();
  const physiqueUpsert = trpc.member_physiqueUpsert.useMutation({
    onSuccess: () => {
      utils.member_find.invalidate({ id: physique.memberId });
      onClose();
    },
  });

  const form = useForm({
    validate: zodResolver(physiqueUpsertSchema),
    initialValues: physique,
  });

  return (
    <form onSubmit={form.onSubmit((values) => physiqueUpsert.mutate(values))}>
      <Stack>
        <Group align="flex-start" noWrap>
          <DatePicker
            label="Datum mjerenja"
            {...form.getInputProps("date")}
            maxDate={new Date()}
          />
          <TextInput label={"Visina"} {...form.getInputProps("height")} />
          <TextInput label={"Težina"} {...form.getInputProps("weight")} />
        </Group>
        <RichTextEditor {...form.getInputProps("description")} />
        <Group position="right">
          <Button
            disabled={physiqueUpsert.isLoading}
            variant="outline"
            onClick={onClose}
            type="button"
          >
            Odustani
          </Button>
          <Button
            loading={physiqueUpsert.isLoading}
            disabled={physiqueUpsert.isLoading}
            type="submit"
          >
            Spremi
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
