import { IoPeople, IoPeopleOutline } from "react-icons/io5";
import { ILink, SidebarMenuItem } from "./components/SideMenuItem";
import { useMe } from "sdk/common/hooks";

const links: Array<ILink> = [
  {
    to: "/postavke/korisnici",
    ActiveIcon: IoPeople,
    Icon: IoPeopleOutline,
    title: "Korisnici",
  },
];

export const AdminSettingsSidebar = (props) => {
  const user = useMe();
  const org = user?.Organization;

  return (
    <div className="flex flex-col gap-6 pl-4 pr-4 pt-3">
      <span className="font-bold text-blue-50 ">
        {org?.shortName || org?.name}
      </span>
      <div className="flex flex-col gap-3 pt-2">
        {links?.map((link) => (
          <SidebarMenuItem key={link.to} {...link} />
        ))}
      </div>
    </div>
  );
};
