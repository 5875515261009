import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Select } from "@mantine/core";
import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { useSetTitle } from "sdk/common/hooks";
import { adminPrintRoutes } from "../../routes/PrintRoutes";
import { ContentContainer } from "sdk/components/ContentContainer";

export const GenerateDocumentDefaultPage = () => {
  const [isDownloading, setIsDownloading] = React.useState(false);

  const reportTypes = adminPrintRoutes.map((pr) => ({
    value: pr.path,
    label: pr.label,
  }));

  const [selectedReport, setSelectedReport] = React.useState<any>(
    reportTypes[0].value
  );

  const [iframeUrl, setIframeUrl] = React.useState<any>();

  const { pathname } = useLocation();

  React.useEffect(() => {
    setIframeUrl(null);
  }, [pathname.toString()]);

  //   console.log({ pathname });

  useSetTitle("Izvještaji");

  const url = `${import.meta.env.VITE_SERVER_URI}/api/print/${selectedReport}`;

  // console.log({ url });

  return (
    <ContentContainer className="h-full p-4">
      <div className="mx-auto flex h-full max-w-7xl flex-col gap-4">
        <div className="flex justify-center gap-8">
          <Select
            value={selectedReport}
            onChange={setSelectedReport}
            data={reportTypes}
            label="Izvještaj"
          />
          <Button
            sx={{ alignSelf: "center", marginTop: 20 }}
            loading={isDownloading}
            leftIcon={<FontAwesomeIcon icon={faPrint} size="lg" />}
            disabled={!selectedReport}
            onClick={() => {
              if (selectedReport) {
                setIsDownloading(true);
                axios
                  .get(url, { responseType: "blob", withCredentials: true })
                  .then((res) => {
                    setIframeUrl(window.URL.createObjectURL(res.data));

                    // FileDownload(res.data, `${reportType + " " + Date.now()}.pdf`);
                  })
                  .finally(() => setIsDownloading(false));
              }
            }}
          >
            Generiraj izvještaj
          </Button>
        </div>

        {!!iframeUrl && (
          <iframe src={iframeUrl} style={{ width: "100%", height: "100%" }} />
        )}
      </div>
    </ContentContainer>
  );
};
