import { Button, Loader, useMantineTheme } from "@mantine/core";

import _ from "lodash";
import React from "react";
import { MdEdit } from "react-icons/md";
import { TbPlus, TbTrash } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { formatDate, notifications } from "sdk/common/helpers";
import { openDeleteDialog, useSetTitle } from "sdk/common/hooks";
import { ActionButton } from "sdk/components/ActionIcon";
import { SelectTrainingSchedule } from "sdk/components/Selects/SelectTrainingSchedule";
import { ulid } from "ulid";
import { GrScheduleNew } from "react-icons/gr";
import { ScheduleForm } from "./forms/ScheduleForm";
import { trpc } from "utils/trpc";
import {
  TrainingEventUpsertSchema,
  TrainingScheduleUpsertSchema,
} from "models";
import { EventForm } from "./forms/EventForm";
import { TiPlus } from "react-icons/ti";
import { ContentContainer } from "sdk/components/ContentContainer";

export const RasporediTreninga = () => {
  const { orgId } = useParams();
  useSetTitle("Raspored treninga");
  const theme = useMantineTheme();

  const [selectedScheduleId, setSelectedScheduleId] = React.useState<
    string | undefined
  >();

  const [modalSchedule, setModalSchedule] =
    React.useState<TrainingScheduleUpsertSchema>();

  const [event, setEvent] = React.useState<
    TrainingEventUpsertSchema | undefined
  >();

  const scheduleFindMany = trpc.trainingSchedule_findMany.useQuery(
    { orgId: orgId! },
    {
      onSuccess: (data) =>
        setSelectedScheduleId((prev) => (prev ? prev : data?.[0]?.id)),
    }
  );
  const schedules = scheduleFindMany.data;

  const scheduleFind = trpc.trainingSchedule_find.useQuery(
    { id: selectedScheduleId! },
    {
      enabled: !!selectedScheduleId,
    }
  );
  const schedule = scheduleFind.data;
  const events = schedule?.TrainingEvents;

  const scheduleDelete = trpc.trainingSchedule_delete.useMutation({
    onSuccess: () => {
      setSelectedScheduleId(undefined);
      notifications.success("Raspored je uspješno obrisan");
      scheduleFindMany.refetch();
    },
  });

  if (scheduleFindMany.isLoading) return <Loader />;

  if (scheduleFindMany.isError) return <div>Greška</div>;

  const eventsByTime = _.groupBy(events, "startTime");
  const getEventsForPeriod = (day: TDaysOfWeek, time) =>
    eventsByTime[time].filter((e) => e.day === day);

  const isScheduleEdit = !!schedules?.find(
    (item) => item.id === modalSchedule?.id
  );
  const isEventEdit = !!events?.find((item) => item.id === event?.id);

  return (
    <>
      <ContentContainer className="flex h-full flex-col items-start gap-8 overflow-y-auto p-4">
        <div className="flex items-end justify-between gap-4 self-stretch">
          <SelectTrainingSchedule
            value={selectedScheduleId}
            onChange={(value) => setSelectedScheduleId(value)}
            sx={{ minWidth: 450 }}
          />

          <ActionButton
            className="ml-auto mr-8"
            onClick={() =>
              openDeleteDialog({
                onAccept() {
                  scheduleDelete.mutate({ id: selectedScheduleId! });
                },
                titleText: "Izbriši raspored",
                contentText: "Potvrdom čete izbrisati raspored",
              })
            }
            disabled={!selectedScheduleId}
          >
            <TbTrash size={24} color={theme.colors.pink[5]} />
          </ActionButton>

          <ActionButton
            onClick={() => setModalSchedule(schedule)}
            disabled={!selectedScheduleId}
          >
            <MdEdit size={24} />
          </ActionButton>

          <Button
            className="self-end"
            leftIcon={<TiPlus />}
            onClick={() =>
              setModalSchedule({
                id: ulid(),
                orgId: orgId!,
                name: "Novi raspored",
                fromDate: new Date(),
                toDate: undefined as any,
                description: "",
              })
            }
          >
            Novi raspored
          </Button>
        </div>

        <div className="flex flex-col gap-2 self-stretch">
          <div className=" grid grid-cols-7 items-center justify-items-center gap-4 rounded-t-md bg-blue-450 px-4 py-2 font-semibold">
            <div className="p-2   text-center">VRIJEME POČETKA</div>
            {daysOfWeek.map((day) => (
              <div
                key={day.value}
                className="flex cursor-pointer items-center   gap-1 rounded-lg p-2 hover:bg-blue-200"
                onClick={() => {
                  setEvent({
                    trainingScheduleId: selectedScheduleId as string,
                    id: ulid(),
                    orgId: orgId!,
                    day: day.value,
                    startTime: undefined as any,
                  });
                }}
              >
                <div>{day.label}</div>
                <TbPlus className="text-md" />
              </div>
            ))}
          </div>
          {selectedScheduleId && scheduleFind.isLoading ? (
            <Loader size="lg" mx="auto" mt="xl" />
          ) : (
            Object.keys(eventsByTime)?.map((time, index) => (
              <div
                key={time}
                className={`grid grid-cols-7 items-center gap-4 ${
                  index === Object.keys(eventsByTime)?.length - 1 &&
                  "rounded-b-md"
                } bg-[rgba(253,250,255,0.8)]  p-2 hover:bg-white hover:shadow-documents-table-row`}
              >
                <div
                  className="flex cursor-pointer items-center justify-center gap-1 justify-self-center rounded-lg p-2 text-lg font-semibold hover:bg-blue-200"
                  onClick={() => {
                    setEvent({
                      trainingScheduleId: selectedScheduleId as string,
                      orgId: orgId!,
                      id: ulid(),
                      day: "",
                      startTime: new Date(time),
                    });
                  }}
                >
                  <div className=""> {formatDate.toTimeString(time)}</div>
                  <TbPlus className="" />
                </div>
                {daysOfWeek.map((d) => {
                  const events = getEventsForPeriod(d.value, time);
                  if (!events.length)
                    return (
                      <div
                        key={d.value}
                        className="cursor-pointer justify-self-center rounded-lg px-4 py-2 hover:bg-blue-200"
                        onClick={() => {
                          setEvent({
                            trainingScheduleId: selectedScheduleId as string,
                            id: ulid(),
                            day: d.value,
                            startTime: new Date(time),
                            orgId: orgId!,
                          });
                        }}
                      >
                        <GrScheduleNew className="text-2xl  [&>*]:stroke-blue-600 " />
                      </div>
                    );
                  return (
                    <div key={d.value} className="flex flex-col gap-2">
                      {events.map((event, index) => (
                        <React.Fragment key={event.id}>
                          {!!index && (
                            <div className="h-1  rounded bg-blue-500 opacity-50" />
                          )}
                          <div
                            onClick={() => setEvent(event as any)}
                            className={`flex cursor-pointer flex-col items-center rounded-lg p-2 hover:bg-blue-200`}
                          >
                            <div className="text-center text-lg font-semibold">
                              {event.AgeGroup?.name || "Svi uzrasti"}
                            </div>
                            <div className="text-center font-medium">
                              {event.Venue?.name}
                            </div>
                            <div className="text-center">{`Trener: ${
                              event.Coach
                                ? `${event.Coach.firstName} ${event.Coach.lastName}`
                                : "--"
                            }`}</div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  );
                })}
              </div>
            ))
          )}

          {/**
           * ADDITIONAL NOTES FOR SCHEDULE
           */}
        </div>
        {!!schedule?.description && schedule?.description !== "<p></p>" && (
          <div>
            <div>Napomena:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: schedule?.description,
              }}
            />
          </div>
        )}
      </ContentContainer>
      {/**
       * MODALS 🠗 🠗
       */}
      {modalSchedule && (
        <ScheduleForm
          schedule={modalSchedule}
          isEdit={isScheduleEdit}
          hideModal={() => setModalSchedule(undefined)}
        />
      )}
      {event && (
        <EventForm
          event={event}
          isEdit={isEventEdit}
          hideModal={() => setEvent(undefined)}
        />
      )}
    </>
  );
};

export const daysOfWeek = [
  { value: "MONDAY", label: "PON" },
  { value: "TUESDAY", label: "UTO" },
  { value: "WEDNESDAY", label: "SRI" },
  { value: "THURSDAY", label: "ČET" },
  { value: "FRIDAY", label: "PET" },
  { value: "SATURDAY", label: "SUB" },
] as const;

const days = daysOfWeek.map((item) => item.value);

type TDaysOfWeek = typeof days[number];
