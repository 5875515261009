import { twMerge } from "tailwind-merge";

export const ContentContainer = (props) => (
  <div
    className={twMerge(
      "max-h-full rounded-4xl border-4 border-solid border-white/80  bg-mainContent shadow-main-content backdrop-blur-md",
      props.className || ""
    )}
  >
    {props.children}
  </div>
);
