import "dayjs/locale/hr";

import { useParams } from "react-router-dom";
import { useSetTitle } from "sdk/common/hooks";
import { CoachDataEntryForm } from "./components/CoachDataEntryForm";
import { assert } from "sdk/assert";
import { Loader } from "@mantine/core";
import { trpc } from "utils/trpc";

export const UrediTrenera = () => {
  useSetTitle("Izmjena podataka trenera", {
    showBackButton: true,
  });
  const { id } = useParams<{ id: string }>();
  assert(id, "id is required");
  const { data, isLoading } = trpc.member_find.useQuery({ id });

  if (isLoading) return <Loader />;
  if (!data) return null;

  return (
    <>
      <CoachDataEntryForm initialValues={data} isUpdate={true} />
    </>
  );
};
