import { Modal, TextInput, Button } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import RichTextEditor from "@mantine/rte";
import { trainingScheduleUpsertSchema } from "models";
import { notifications } from "sdk/common/helpers";
import { trpc } from "utils/trpc";

export const ScheduleForm = ({ schedule, isEdit, hideModal }) => {
  const form = useForm({
    initialValues: schedule,
    validate: zodResolver(trainingScheduleUpsertSchema),
  });

  const utils = trpc.useContext();
  const scheduleUpsert = trpc.trainingSchedule_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.trainingSchedule_findMany.invalidate({ orgId: data.orgId });
      utils.trainingSchedule_find.invalidate({ id: data.id });
      hideModal();
    },
    onError: () => notifications.error(),
  });

  return (
    <Modal
      opened={!!schedule}
      onClose={hideModal}
      size={"xl"}
      title={
        <div className="text-lg">
          {isEdit ? "Uredi raspored" : "Novi raspored"}
        </div>
      }
    >
      <form onSubmit={form.onSubmit((values) => scheduleUpsert.mutate(values))}>
        <div className="flex flex-col gap-4">
          {/* <pre>{JSON.stringify({ form }, null, 2)}</pre> */}

          <TextInput
            label="Naziv/opis rasporeda"
            {...form.getInputProps("name")}
            sx={{ width: "auto" }}
          />
          <DatePicker
            initialLevel="date"
            label="Datum od"
            maxDate={form.values.toDate}
            {...form.getInputProps("fromDate")}
            sx={{ width: "auto" }}
          />
          <DatePicker
            initialLevel="date"
            label="Datum do"
            minDate={form.values.fromDate}
            {...form.getInputProps("toDate")}
            sx={{ width: "auto" }}
          />

          {isEdit && (
            <div>
              <label className="text-sm font-medium text-blue-500">
                Dodatne napomene
              </label>
              <RichTextEditor
                sx={{ minHeight: 200 }}
                {...form.getInputProps("description")}
              />
            </div>
          )}

          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={hideModal}
              disabled={scheduleUpsert.isLoading}
              className="ml-auto"
            >
              Odustani
            </Button>
            <Button loading={scheduleUpsert.isLoading} type="submit">
              Spremi
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
