import { ulid } from "ulid";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput, Textarea, Button } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { meetingUpsertSchema } from "models";
import { notifications } from "sdk/common/helpers";
import { trpc } from "utils/trpc";

export const NovaSjednica = ({ onClose }) => {
  const { orgId } = useParams();

  return (
    <NewMeetingForm
      initialValues={{
        id: ulid(),
        orgId,
        date: new Date(),
        location: "",
        notes: "",
      }}
      onClose={onClose}
    />
  );
};

const NewMeetingForm = ({ initialValues, onClose }) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const formHandler = useForm({
    initialValues,
    validate: zodResolver(meetingUpsertSchema),
  });

  const utils = trpc.useContext();

  const meetingUpsert = trpc.meeting_upsert.useMutation({
    onSuccess: (data) => {
      notifications.success();
      utils.meeting_findMany.invalidate({ orgId });
      navigate(`/pregled/klubovi/${orgId}/sjednice/uredi/${data.id}`);
    },
  });

  return (
    <>
      <form
        onSubmit={formHandler.onSubmit(
          (values) => meetingUpsert.mutate(values),
          (error) => console.log({ error })
        )}
        className="flex flex-col"
      >
        <div className="flex flex-col gap-4">
          <DatePicker
            label="Datum"
            withAsterisk
            locale="hr"
            {...formHandler.getInputProps("date")}
          />
          <TextInput
            label="Lokacija"
            withAsterisk
            {...formHandler.getInputProps("location")}
          />
          <Textarea
            label="Bilješke"
            withAsterisk
            sx={{ width: "auto" }}
            {...formHandler.getInputProps("notes")}
            minRows={5}
          />
        </div>
        <div className="mt-16 flex justify-between">
          <Button sx={{ minWidth: 170 }} variant="outline" onClick={onClose}>
            Odustani
          </Button>
          <Button
            sx={{ minWidth: 170 }}
            type="submit"
            loading={meetingUpsert.isLoading}
          >
            Spremi
          </Button>
        </div>
      </form>
    </>
  );
};
