import { twMerge } from "tailwind-merge";

export const Label = ({
  children,
  className,
  ...rest
}: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <label {...rest} className={twMerge("font-bold text-blue-500", className)}>
    {children}
  </label>
);
