/* eslint-disable react/prop-types */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Badge,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { MdClose } from "react-icons/md";
import { TbFilter } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  memberTypeTableColors,
  removeFalsies,
} from "sdk/common/helpers";
import { useBreakpoints, useSearchParams, useSetTitle } from "sdk/common/hooks";
import { DataTable, TableColumn } from "sdk/components/DataTable";
import { SelectMembershipType } from "sdk/components/Selects/SelectMembershipType";
import { SelectOrganization } from "sdk/components/Selects/SelectOrganization";
import { useSwipeable } from "react-swipeable";
import {
  MemberStatuses,
  SelectStatus,
} from "sdk/components/Selects/SelectStatus";
import { SelectSport } from "sdk/components/Selects/SelectSport";
import { HiChevronUp, HiChevronDown, HiPencil } from "react-icons/hi";
import { ActionButton } from "sdk/components/ActionIcon";
import { IoEnterOutline } from "react-icons/io5";
import { SelectGender } from "sdk/components/Selects/SelectGender";
import { BsDot } from "react-icons/bs";
import { trpc } from "utils/trpc";
import { ContentContainer } from "sdk/components/ContentContainer";

export const SviClanovi = (props) => {
  useSetTitle("Članovi");

  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [params, setParams] = useSearchParams();

  const { isLg } = useBreakpoints();

  const [drawerOpened, setDrawerOpened] = React.useState(false);

  const [sortInput, setSortInput] = React.useState<{
    orderBy: string;
    asc: boolean;
  }>({ orderBy: "lastName", asc: true });

  const [debouncedSearchText] = useDebouncedValue(params.searchText, 300);

  const memberFindMany = trpc.member_findMany.useInfiniteQuery(
    {
      ...params,
      searchText: debouncedSearchText || "",
      orderBy: { [sortInput.orderBy]: sortInput.asc ? "asc" : "desc" },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: true,
    }
  );
  const members = memberFindMany.data?.pages.flatMap((arr) => arr.items);

  const getSortHeader = React.useCallback(
    (columnId, columnLabel) => (
      <Group
        spacing="xs"
        sx={{ userSelect: "none", cursor: "pointer" }}
        onClick={() => {
          setSortInput((prev) => ({
            orderBy:
              prev.orderBy === columnId && !prev.asc ? "lastName" : columnId,
            asc: prev.orderBy === columnId && prev.asc ? false : true,
          }));
        }}
      >
        <Text>{columnLabel.toLowerCase()}</Text>
        {sortInput.orderBy === columnId &&
          (sortInput.asc ? <HiChevronUp /> : <HiChevronDown />)}
      </Group>
    ),
    //
    [sortInput]
  );

  const columns = React.useMemo<TableColumn[]>(
    () => [
      {
        Header: "",
        id: "eye",
        accessor: "",
        Cell: () => <IoEnterOutline size={20} />,
        width: 36,
        className: "pt-1 text-blue-400 hover:text-blue-700",
      },
      {
        Header: "",
        id: "edit",
        accessor: "",
        className: "pt-1 text-right text-blue-400 hover:text-blue-700",
        width: 36,
        Cell: (data) => {
          return (
            <HiPencil
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/pregled/klubovi/${data.row.original.orgId}/clanovi/uredi/${data.row.original.id}`
                );
              }}
            />
          );
        },
      },
      {
        id: "lastName",
        Header: getSortHeader("lastName", "prezime"),
        accessor: "lastName",
        width: 160,
        className: "font-bold text-blue-700",
      },
      {
        id: "firstName",
        Header: getSortHeader("firstName", "ime"),
        accessor: "firstName",
        width: 160,
        className: "font-bold text-blue-700",
      },

      {
        id: "age",
        Header: getSortHeader("dateOfBirth", "Dob"),
        accessor: "dateOfBirth",
        width: 60,
        headerClassName: "text-right",
        className: "text-center text-blue-500",
        Cell: (props) => (
          <div>
            {props.value ? dayjs().diff(dayjs(props.value), "y") : "-/-"}
          </div>
        ),
      },
      {
        Header: getSortHeader("gender", "Spol"),
        accessor: "gender",
        width: 70,
        className: "text-center text-blue-500",
        Cell: ({ value }) => value?.toString()?.charAt(0) || "-/-",
      },
      {
        Header: getSortHeader("sportId", "sport"),
        accessor: "Sport.name",
        width: 250,
        className: "text-blue-500",
      },
      {
        Header: getSortHeader("orgId", "klub"),
        accessor: "Organization.name",
        width: 250,
        className: "text-blue-500",
      },
      {
        Header: getSortHeader("membershipTypeId", "Tip člana"),
        accessor: "MembershipType.name",
        width: 260,
        className: "text-blue-500",
        Cell: ({ row, value }) => (
          <div
            className={`flex items-center rounded-full px-2 ${
              memberTypeTableColors[row?.original?.membershipTypeId]
            }`}
          >
            <BsDot size={32} />
            <div>
              {row?.original?.membershipTypeId === "O"
                ? row?.original?.memberRole
                : value}
            </div>{" "}
          </div>
        ),
      },
      {
        Header: getSortHeader("currentAgeGroupId", "Uzrast"),
        accessor: "CurrentAgeGroup.AgeGroup.name",
        width: 150,
        className: "text-blue-500",
      },
    ],
    [sortInput]
  );

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setDrawerOpened(false),
  });
  const { data: sports } = trpc.sport_findMany.useQuery();
  const { data: organizations } = trpc.organization_findMany.useQuery();

  const ageGroups =
    sports?.find((sport) => sport.id === params?.sportId)?.AgeGroups || [];

  const { data: membershipTypes } = trpc.membershipType_findMany.useQuery();
  const membershipFilterTypes = membershipTypes?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const filterLabels = {
    orgId: organizations?.find((org) => org.id === params?.orgId)?.name,
    sportId: sports?.find((sport) => sport.id === params?.sportId)?.name,
    membershipTypeId: membershipFilterTypes?.find(
      (m) => m.value === params?.membershipTypeId
    )?.label,
    currentAgeGroupId: ageGroups?.find(
      (ageGroup) => ageGroup.id === params?.currentAgeGroupId
    )?.name,
    searchText: params?.searchText,
    status: MemberStatuses.find(
      (item) => item.value === (params?.status as string)
    )?.label,
    gender: params.gender,
  };

  return (
    <>
      <ContentContainer className="flex h-full flex-col gap-4 p-4">
        <div className="flex flex-row items-center justify-between pb-4">
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({
                ...removeFalsies({
                  ...params,
                  searchText: e.currentTarget.value,
                }),
              })
            }
          />

          <div className="flex flex-row items-center gap-6">
            <ActionButton onClick={() => setDrawerOpened(true)} label="Filter">
              <TbFilter size={24} />
            </ActionButton>
          </div>
        </div>
        {Object.keys(removeFalsies(params)).length ? (
          <div className="flex gap-4 px-4">
            {Object.keys(removeFalsies(params)).map((key) => (
              <Badge
                size="lg"
                sx={{
                  background: "#FCE6E4",
                  color: "#617A9E",
                }}
                key={key}
                rightSection={
                  <ActionIcon
                    size="xs"
                    radius="xl"
                    variant="transparent"
                    onClick={() => {
                      setParams({ ...removeFalsies({ ...params, [key]: "" }) });
                    }}
                  >
                    <MdClose size={16} />
                  </ActionIcon>
                }
              >
                {filterLabels[key]}
              </Badge>
            ))}
            <button
              className="ml-auto cursor-pointer border-0 bg-inherit p-1 text-blue-600 hover:underline"
              onClick={() => setParams({})}
              type="button"
            >
              Ukloni filtere
            </button>
          </div>
        ) : null}

        <DataTable
          isLoading={memberFindMany.isLoading}
          data={members}
          columns={columns}
          restoreScrollKey="membersListScreen"
          onRowClick={(row) =>
            navigate(`/pregled/klubovi/${row.orgId}/clanovi/detalji/${row.id}`)
          }
          renderItem={(row) => (
            <Group noWrap>
              <Text>
                {row.firstName} {row.lastName}
              </Text>
              <Text>{formatDate.toDateString(row.dateOfBirth) || "-/-"}</Text>
            </Group>
          )}
          renderItemHeight={55}
          fetchMore={memberFindMany.fetchNextPage}
        />
      </ContentContainer>

      <Drawer
        withCloseButton={false}
        // overlayOpacity={0.3}
        overlayColor={theme.other.overlayColor}
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={
          <Text
            sx={{
              color: theme.colors.blue[4],
            }}
            weight={500}
            size={24}
          >
            Filter
          </Text>
        }
        position="right"
        closeOnEscape
        closeOnClickOutside
        styles={(theme) => ({
          root: {},
          title: {},
          drawer: {
            borderTopLeftRadius: theme.radius.lg,
            borderBottomLeftRadius: theme.radius.lg,
            backdropFilter: "blur(8px)",
            background: theme.other.sidebarFilterBackground,
          },
          header: {
            padding: theme.spacing.xl,
            height: isLg ? "80px" : "62px",
          },
        })}
      >
        <Stack {...swipeHandlers} spacing="xl" p="md" sx={{ height: "100%" }}>
          <TextInput
            icon={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                color={theme.colors.blue[5]}
              />
            }
            placeholder="Traži"
            value={params?.searchText || ""}
            onChange={(e) =>
              setParams({
                ...removeFalsies({
                  ...params,
                  searchText: e.currentTarget.value,
                }),
              })
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectMembershipType
            hideLabel
            withAllAthletesOption
            value={params.membershipTypeId || null}
            onChange={(membershipTypeId) =>
              setParams({
                ...removeFalsies({
                  ...params,
                  membershipTypeId,
                  currentAgeGroupId: "",
                }),
              })
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectSport
            hideLabel
            value={params.sportId || null}
            onChange={(sportId) =>
              setParams(
                removeFalsies({
                  ...params,
                  sportId,
                  currentAgeGroupId: "",
                  orgId: "",
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
          <SelectOrganization
            hideLabel
            value={params.orgId || null}
            onChange={(orgId) =>
              setParams(
                removeFalsies({
                  ...params,
                  orgId,
                  sportId: organizations?.find((o) => o.id === orgId)?.sportId,
                })
              )
            }
            filterBySportId={params.sportId}
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />
          <Select
            clearable
            disabled={!params.sportId || !ageGroups?.length}
            data={ageGroups?.map((ageGroup) => ({
              value: (ageGroup as any).id,
              label: (ageGroup as any).name,
            }))}
            placeholder="Uzrasna kategorija"
            value={params?.currentAgeGroupId || null}
            onChange={(currentAgeGroupId) =>
              setParams(removeFalsies({ ...params, currentAgeGroupId }))
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />

          <SelectGender
            hideLabel
            value={params.gender || null}
            onChange={(gender) =>
              setParams(
                removeFalsies({
                  ...params,
                  gender,
                })
              )
            }
            styles={{
              input: {
                border: "none",
                "::placeholder": {
                  color: theme.colors.blue[7],
                  fontWeight: 500,
                },
              },
            }}
          />

          <SelectStatus
            hideLabel
            value={params.status || null}
            onChange={(e) =>
              setParams(
                removeFalsies({
                  ...params,
                  status: e,
                })
              )
            }
            styles={{ input: { border: "none" } }}
          />
        </Stack>
      </Drawer>
    </>
  );
};
