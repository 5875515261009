import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { BsExclamation } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

export const notifications = {
  success(message = "Ažuriranje uspješno") {
    showNotification({
      message,
      color: "green",
      icon: <FaCheck size={16} />,
      styles: (theme) => ({
        root: {
          borderRadius: theme.radius.lg,
          padding: 16,
          border: "1px solid #8FCC66",
          background: "#F8FCF7",
          boxShadow: "0px 3px 6px 0px rgba(50, 88, 149, 0.4)",
        },
        closeButton: {
          color: "#F8FCF7",
        },
      }),
    });
  },
  error(message = "Ažuriranje neuspješno") {
    showNotification({
      message,
      color: "red",
      icon: <BsExclamation size={40} />,
      styles: (theme) => ({
        root: {
          borderRadius: theme.radius.lg,
          padding: 16,
          border: "1px solid #E24040",
          background: "#FEFAFF",
          boxShadow: "0px 3px 6px 0px rgba(50, 88, 149, 0.4)",
        },
        closeButton: {
          color: "#FEFAFF",
        },
      }),
    });
  },
};

export const showNotificationsForMutationResponse = (result) => {
  if (result.errors) {
    notifications.error(result.errors[0].message);
  } else {
    notifications.success();
  }
};

export const handleMutationResponse = <T,>(result: any) => {
  if (result.errors) {
    const err = result.errors[0];
    notifications.error(err.message.toString());
    return { sucess: false };
  }

  return { success: true };
};
export const formatDate = {
  toTimeString: (value: dayjs.ConfigType) =>
    value ? dayjs(value).format("HH:mm") : "-/-",
  toDateString: (value: dayjs.ConfigType) =>
    value ? dayjs(value).format("DD. MM. YYYY.") : "-/-",
  toDateTimeString: (value: dayjs.ConfigType) =>
    value ? dayjs(value).format("DD. MM. YYYY HH:mm") : "-/-",
  toSqlDateString: (value?: dayjs.ConfigType) =>
    value ? dayjs(value).format("YYYY-MM-DD") : undefined,
};
/**
 * Calculates the age of a person based on their date of birth and the current date.6
 * @param dateOfBirth
 * @returns
 * @todo
 *  not sure how precise this is
 */
export const calculateAge = (dateOfBirth: dayjs.ConfigType) => {
  return dayjs().diff(dateOfBirth, "year");
};
export const formatCurrency = (value) =>
  Number(value).toLocaleString("hr", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const fileTypes = {
  AVATAR: "Profilna slika",
  ID_FRONT: "Prednja strana osobne",
  ID_BACK: "Stražnja strana osobne",
  PASSPORT: "Putovnica",
  VISA: "Viza",
  OTHER: "Drugo",
};

export const getUrlFromRecordFileId = (id) =>
  `${import.meta.env.VITE_SERVER_URI}/api/recordFileDownload/${id}`;

export const removeFalsies = (obj) => {
  const temp = { ...obj };
  Object.keys(temp).forEach((k) => !temp[k] && delete temp[k]);
  return temp;
};

export const isValidOIB = (input: string): boolean => {
  const oib = input.toString();

  if (oib.match(/\d{11}/) === null) {
    return false;
  }

  let calculated = 10;

  for (const digit of oib.substring(0, 10)) {
    calculated += parseInt(digit);

    calculated %= 10;

    if (calculated === 0) {
      calculated = 10;
    }

    calculated *= 2;

    calculated %= 11;
  }

  let check = 11 - calculated;

  if (check === 10) {
    check = 0;
  }

  return check === parseInt(oib[10]);
};

/**
 *
 * @param input object that we received from gql server
 * @param isDate optional, used by recursion
 * @returns object without __typename keys and keys with "date" in them are parsed to Date
 */
export function parseDatesAndRemoveTypenames(input, isDate = false) {
  if (!input) return input;

  if (isDate) {
    return new Date(input);
  }

  if (Array.isArray(input)) {
    return input.map((item) =>
      typeof item === "object" ? parseDatesAndRemoveTypenames(item) : item
    );
  }

  if (typeof input === "object") {
    const temp = {};
    6;
    Object.keys(input).forEach(
      (key) =>
        key !== "__typename" &&
        (temp[key] = parseDatesAndRemoveTypenames(
          input[key],
          key.toLowerCase().includes("date")
        ))
    );
    return temp;
  }

  return input;
}

/**
 *
 * @param input any object or arrray that we need to iterate trough
 * @param isDate optional, used by recursion
 * @returns object or array without __typename keys and date objects stringified to YYYY-MM-DD / ISO
 */
export function stringifyDatesAndRemoveTypenames(input, isDate = false) {
  if (!input) return input;

  if (input instanceof Date)
    return isDate ? formatDate.toSqlDateString(input) : input.toISOString();

  if (Array.isArray(input)) {
    return input.map((item) =>
      typeof item === "object" ? stringifyDatesAndRemoveTypenames(item) : item
    );
  }

  if (typeof input === "object") {
    const temp = {};
    Object.keys(input).forEach(
      (key) =>
        key !== "__typename" &&
        (temp[key] = stringifyDatesAndRemoveTypenames(
          input[key],
          key.toLowerCase().includes("date")
        ))
    );
    return temp;
  }

  return input;
}

export const formSubmitErrorHandler = (
  message = "Molimo upišite sve obvezne podatke"
) => {
  return (errors) => {
    console.log({ errors });
    setTimeout(() => {
      const invalidElement = document.querySelector("[aria-invalid=true]");

      if (invalidElement) {
        invalidElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      showNotification({
        message: <div className="text-lx">{message}</div>,
        color: "pink",
        icon: <MdCancel size={32} />,
      });
    });
  };
};

export const memberTypeTableColors = {
  RS: "bg-[#ECF4EF] text-[#128755]",
  NRS: "bg-[#EAF1FD] text-[#4D78D7]",
  O: "bg-[#FFF2ED] text-[#A75139]",
};

export const isoStringToDate = (date) => (date ? new Date(date) : undefined);
