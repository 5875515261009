import { NavLink, useParams } from "react-router-dom";
import { useAppState } from "state/useAppState";
import { Group, Text } from "@mantine/core";

export interface ILink {
  to: string;
  title: string;
  Icon: any;
  ActiveIcon: any;
  // children?: Array<ILink>;
  end?: boolean;
}

export const SidebarMenuItem = (props: ILink) => {
  const { title, Icon, ActiveIcon, end = false } = props;

  const { orgId } = useParams();

  const [setSidebarOpen] = useAppState((state) => [state.setSidebarOpen]);

  const to = props.to.replace(":orgId", orgId || "");

  return (
    <NavLink
      to={to}
      style={{ textDecoration: "none" }}
      end={end}
      onClick={() => setSidebarOpen(false)}
    >
      {({ isActive }) => {
        return (
          <Group
            pl="xs"
            sx={(theme) => ({
              background: isActive ? "rgba(255, 255, 255, 0.31)" : "initial",
              borderRadius: "8px",
              height: "40px",
              color: isActive ? theme.colors.blue[7] : theme.colors.blue[5],
              "&:hover": !isActive
                ? {
                    background: "rgba(255, 255, 255, 0.31)",
                    ">*": {
                      color: isActive
                        ? theme.colors.blue[4]
                        : theme.colors.blue[7],
                    },
                  }
                : undefined,
            })}
            spacing={8}
          >
            {isActive ? <ActiveIcon size={24} /> : <Icon size={24} />}
            <Text
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: "14px",
                color: isActive ? theme.colors.blue[7] : theme.colors.blue[5],
              })}
            >
              {title}
            </Text>
          </Group>
        );
      }}
    </NavLink>
  );
};
